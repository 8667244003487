import { Serializable } from '../deserialize.service';

export class CustomerComment implements Serializable<CustomerComment> {
    id: string;
    commentAdded: Date;
    comment: string;
    comentatorName: string;

    deserialize(input) {
        // debugger;
        this.id = input.id;
        this.commentAdded = input.commentAdded;
        this.comment = input.comment;
        this.comentatorName = input.comentatorName;
        return this;
    }
}
