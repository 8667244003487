import { Serializable } from '../deserialize.service';

export class ServiceBUContractor implements Serializable<ServiceBUContractor> {
    bsId: number;
    bsServiceId: string;
    bsServiceName: string;
    contractorId: number;
    contractorName: string;
    contractorServiceId: number;
    contractorServiceName: string;
    contractorExternalServiceId: string;
    active: boolean;
    price: number;

    deserialize(input) {
        if (input) {
            this.bsId = input.bsId;
            this.bsServiceId = input.bsServiceId;
            this.bsServiceName = input.bsServiceName;
            this.contractorId = input.contractorId;
            this.contractorName = input.contractorName;
            if (input.contractorServiceId) {
                this.contractorServiceId = input.contractorServiceId;
            }
            this.contractorServiceName = input.contractorServiceName;
            if (input.contractorExternalServiceId) {
                this.contractorExternalServiceId = input.contractorExternalServiceId;
            }
            this.active = input.active;
            this.price = input.price;
        }
        return this;
    }
}
