import { Serializable } from '../deserialize.service';
import { OrgUnit } from '../orgUnit.model';

export class Service implements Serializable<Service> {
    id: number;
    bsInternalId: string;
    name: string;
    description: string;
    length: number;
    price: number;
    internalId: string;
    externalId: string;
    counter: number;
    priceRange: string;
    orgUnitExternalId: string;
    orgUnit: OrgUnit;
    directBooking: boolean;
    coveragePercentage: number;
    coverageAmount: number;
    selfCoveragePercentage: number;
    selfCoverageAmount: number;
    originalService: Service;
    telemed: boolean;
    phoneCall: boolean;
    addDescriptionToComment: boolean;
    bsGeneraliId: string;
    surveyAfterAuthTemplateId: number;
    surveyAfterServiceTemplateId: number;
    code: number; //AUDAX

    deserialize(input: any) {
        this.id = input.id;
        this.bsInternalId = input.bsInternalId;
        this.name = input.name;
        this.description = input.description;
        this.length = input.length;
        this.price = input.price;
        this.internalId = input.internalId;
        this.externalId = input.externalId;
        this.counter = input.counter;
        this.priceRange = input.priceRange;
        this.orgUnitExternalId = input.orgUnitExternalId;
        this.directBooking = input.directBooking;
        this.coveragePercentage = input.coveragePercentage;
        this.coverageAmount = input.coverageAmount;
        this.selfCoveragePercentage = input.selfCoveragePercentage;
        this.selfCoverageAmount = input.selfCoverageAmount;
        this.telemed = input.telemed;
        if (input.orgUnit) {
            this.orgUnit = new OrgUnit().deserialize(input.orgUnit);
        }
        this.originalService = input.originalService;
        this.addDescriptionToComment = input.addDescriptionToComment;
        this.bsGeneraliId = input.bsGeneraliId;
        this.phoneCall = input.phoneCall;
        this.surveyAfterAuthTemplateId = input.surveyAfterAuthTemplateId;
        this.surveyAfterServiceTemplateId = input.surveyAfterServiceTemplateId;
        this.code = input.code;

        return this;
    }

    constructor(obj?: any) {
        this.id = (obj && obj.id) || null;
        this.name = (obj && obj.name) || null;
        this.description = (obj && obj.description) || [];
        this.length = (obj && obj.length) || [];
        this.price = (obj && obj.price) || [];
        this.internalId = (obj && obj.internalId) || [];
        this.externalId = (obj && obj.externalId) || [];
        this.counter = (obj && obj.counter) || [];
        this.priceRange = (obj && obj.priceRange) || [];
        this.orgUnitExternalId = (obj && obj.orgUnitExternalId) || null;
    }
}
