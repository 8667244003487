import { AuthorityCaseStatus } from './../../enums/authorityCaseStatus.enum';
import { AuthorityType } from './../../enums/authorityType.enum';
import { Serializable } from '../deserialize.service';

export class AuthorityTypeStatus implements Serializable<AuthorityTypeStatus> {
    public type: AuthorityType;
    public status: AuthorityCaseStatus;

    deserialize(input: any) {
        this.type = input.type;
        this.status = input.status;
        return this;
    }
}
