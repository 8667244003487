import { DocumentSign } from './documentSign.model';
import { Serializable } from '../deserialize.service';

export class DocumentWithSignature implements Serializable<DocumentWithSignature> {
    public document: Blob;
    public signature: DocumentSign;

    deserialize(input: any) {
        this.document = input.document;
        this.signature = input.signature;
        return this;
    }
}
