import { Serializable } from '../deserialize.service';

export class AuthorityCase implements Serializable<AuthorityCase> {
    public created: Date;
    public authorityId: string;
    public id: number;

    deserialize(input: any) {
        this.authorityId = input.authorityId;
        this.created = input.created;
        this.id = input.id;
        return this;
    }
}
