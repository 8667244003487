<div class="modal-header">
    <h4 class="modal-title">
        Dodajanje nove nepovezane storitve
    </h4>
</div>
<div class="modal-body">
    <form [formGroup]="externalServiceForm" novalidate *ngIf="externalServiceForm">
        <div class="m-list-search">
            <div class="m-list-search__results">
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="name"
                            type="text"
                            placeholder="Naziv storitve"
                            class="form-control m-input"
                            [ngClass]="{ 'form-control-sm': isSmallInput }"
                        />
                    </span>
                </div>
                <div *ngIf="externalServiceForm.controls['name']?.errors && isSubmitted" class="m--font-danger pl-5">
                    <small
                        [hidden]="!externalServiceForm.controls['name']?.errors?.required"
                        i18n="@@8fa4cba7-0e75-4fc1-aae0-8809d55d1c75"
                        >Naziv storitve je obvezno polje.
                    </small>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="ml-auto mr-auto btn m-btn--pill btn-danger m-btn m-btn--custom"
        (click)="dismiss('Close click')"
        tabindex="8"
        i18n="@@d2552f4e-a8b7-5daf-9c89-f1f0ac30a2df"
    >
        Prekliči
    </button>
    <button
        (click)="createService()"
        type="buttons"
        class="mr-auto btn m-btn--pill btn-accent m-btn m-btn--custom"
        tabindex="6"
        i18n="@@615c16be-d366-5ec4-a79a-7db71946bb97"
    >
        Potrdi
    </button>
</div>
