import { BsGuiSettings } from './../model/guiSettings/bsGuiSettings.model';
import { GuiSettings } from './../model/guiSettings/guiSettings.model';
import { AuthorizationManage } from '../model/authorization/authorizationManage.model';
import { AuthorizationOrderNew } from './../model/authorization/authorizationOrderNew.model';
import { BusinessSubjects } from './../model/businessSubjects.model';
import { AuthorizationRequest } from './../model/authorization/authorizationRequest.model';
import { Customer } from './../model/customer.model';
import { User } from './../model/user.model';
import { Injectable } from '@angular/core';
import { AuthorizationOrder, Contractor } from '../model';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class StorageService {
    private appDataSubject = new Subject<any>();

    constructor() {}

    public saveToken(token: any): void {
        localStorage.setItem('mfa-credentialsData', JSON.stringify(token));
    }

    public getToken(): any {
        return JSON.parse(localStorage.getItem('mfa-credentialsData'));
    }

    public clearToken(): any {
        return localStorage.removeItem('mfa-credentialsData');
    }

    //getBusinessData
    public setBusinessSubject(c: BusinessSubjects): void {
        this.set('b481b8fa-82ff-52a7-81e3-bd1a1aab99d6', c);
    }

    public getBusinessSubject(): BusinessSubjects {
        return this.get('b481b8fa-82ff-52a7-81e3-bd1a1aab99d6');
    }

    public deleteBusinessData(): any {
        localStorage.removeItem('b481b8fa-82ff-52a7-81e3-bd1a1aab99d6');
    }

    //AppData
    public appData(): Observable<any> {
        return this.appDataSubject.asObservable();
    }

    public setAppData(c: any): void {
        this.appDataSubject.next(c);
        this.set('mfa-appData', c);
    }

    public getAppData(): any {
        const appData = this.get('mfa-appData');
        appData.type = 0;
        return appData;
    }

    //setUserData
    public setGuiSettings(con: GuiSettings): void {
        this.set('87e7acec-5cdd-5953-8c2c-c638377510bf', con);
    }

    public getGuiSettings(): GuiSettings {
        return this.get('87e7acec-5cdd-5953-8c2c-c638377510bf');
    }

    //setUserData
    public setUserData(con: User): void {
        // debugger;
        this.set('mfa-userData', con);
    }

    public getUserData(): User {
        // debugger;
        return this.get('mfa-userData');
    }

    public setBsGuiSettings(con: BsGuiSettings): void {
        this.set('84631437-3652-5ee7-8633-933b2b1bc506', con);
    }

    public getBsGuiSettings(): BsGuiSettings {
        return new BsGuiSettings().deserialize(this.get('84631437-3652-5ee7-8633-933b2b1bc506'));
    }

    public deleteBsGuiSettings() {
        localStorage.removeItem('84631437-3652-5ee7-8633-933b2b1bc506');
    }

    public getUserRoles(): any {
        const accessToken = this.getToken();
        if (!accessToken || !accessToken.access_token) {
            return null;
        }
        const tokenData = JSON.parse(atob(accessToken.access_token.split('.')[1]));
        if (tokenData.sdata.bsRoles && tokenData.sdata.bsRoles[this.getBusinessSubject().id]) {
            return this.filterUserRoles(tokenData.sdata.bsRoles[this.getBusinessSubject().id]);
        } else if (
            tokenData.sdata.contractorsRoles &&
            tokenData.sdata.contractorsRoles[this.getSelectedContractor().id]
        ) {
            return this.filterUserRoles(tokenData.sdata.contractorsRoles[this.getSelectedContractor().id]);
        }
        return [];
    }

    public filterUserRoles(roles): any {
        /*if (roles.indexOf('ROLE_BOOKING_METABASE_ANALYTICS') !== -1) {
            roles = roles.filter((role) => {
                return role !== 'ROLE_BOOKING';
            });
        }*/
        return roles;
    }

    public deleteUserRoles() {
        localStorage.removeItem('mfa-userRoles');
    }

    public deleteUserData() {
        localStorage.removeItem('mfa-userData');
    }

    public setSelectedCustomer(con: Customer): void {
        this.set('mfa-rabvoc', con);
    }

    public getSelectedCustomer(): any {
        return this.get('mfa-rabvoc');
    }

    public deleteSelectedCustomer() {
        localStorage.removeItem('mfa-rabvoc');
    }

    //external mode
    public isExternalMode(): boolean {
        return this.get('5ebb9811-0daf-5d2c-959e-a1fda7f20b93');
    }

    public setExternalMode(con: boolean): void {
        this.set('5ebb9811-0daf-5d2c-959e-a1fda7f20b93', con);
    }

    public deleteExternalMode(): void {
        localStorage.removeItem('5ebb9811-0daf-5d2c-959e-a1fda7f20b93');
    }

    //external order added
    public isExternalOrderAdded(): boolean {
        return this.get('ce96d103-0523-58d1-91cf-a7fa0e001e2a');
    }

    public setExternalOrderAdded(con: boolean): void {
        this.set('ce96d103-0523-58d1-91cf-a7fa0e001e2a', con);
    }

    public deleteExternalOrderAdded(): void {
        localStorage.removeItem('ce96d103-0523-58d1-91cf-a7fa0e001e2a');
    }

    public setExternalOrder(con: AuthorizationOrder): void {
        this.set('b4de5503-d3af-501d-b16a-adbab4c02836', con);
    }

    public getExternalOrder(): AuthorizationOrder {
        return this.get('b4de5503-d3af-501d-b16a-adbab4c02836');
    }

    public deleteExternalOrder() {
        localStorage.removeItem('b4de5503-d3af-501d-b16a-adbab4c02836');
    }

    public setAuthorizationOrderNew(con: AuthorizationOrderNew): void {
        this.set('c1e1ab07-db33-53a5-864d-ba2b79129f9a', con);
    }

    public getAuthorizationOrderNew(): AuthorizationOrderNew {
        return this.get('c1e1ab07-db33-53a5-864d-ba2b79129f9a');
    }

    public deleteAuthorizationOrderNew() {
        localStorage.removeItem('c1e1ab07-db33-53a5-864d-ba2b79129f9a');
    }

    public setAuthorizationManage(con: AuthorizationManage): void {
        this.set('2e006281-8582-5e12-a2ad-50b64111ac09', con);
    }

    public getAuthorizationManage(): AuthorizationManage {
        return this.get('2e006281-8582-5e12-a2ad-50b64111ac09');
    }

    public deleteAuthorizationManage() {
        localStorage.removeItem('2e006281-8582-5e12-a2ad-50b64111ac09');
    }

    //selected authorization

    public setAuthorizationOrder(con: AuthorizationOrder): void {
        this.set('4a3d6fb7-4213-50ba-8df0-ee3dec488d5a', con);
    }

    public getAuthorizationOrder(): AuthorizationOrder {
        return this.get('4a3d6fb7-4213-50ba-8df0-ee3dec488d5a');
    }

    public deleteAuthorizationOrder() {
        localStorage.removeItem('4a3d6fb7-4213-50ba-8df0-ee3dec488d5a');
    }

    //selected authorization

    public setAuthorizationExternalRequest(con: AuthorizationRequest): void {
        this.set('41836484-245c-5a8e-ba89-2ab7368076df', con);
    }

    public getAuthorizationExternalRequest(): AuthorizationRequest {
        return this.get('41836484-245c-5a8e-ba89-2ab7368076df');
    }

    public deleteAuthorizationExternalRequest() {
        localStorage.removeItem('41836484-245c-5a8e-ba89-2ab7368076df');
    }

    public setSelectedContractor(con: Contractor): void {
        this.set('mf-selectedContractor', con);
    }

    public getSelectedContractor(): Contractor {
        return this.get('mf-selectedContractor');
    }

    public setBpi(bpi): void {
        this.set('mf-bpi', bpi);
    }

    public getBpi() {
        return this.get('mf-bpi');
    }

    public setCase(caseId): void {
        this.set('mf-case', caseId);
    }

    public getCase() {
        return this.get('mf-case');
    }

    //prevent redirects
    public isPreventRedirect(): boolean {
        return JSON.parse(localStorage.getItem('9d76etz6-88zt-j3hz-lpp0-76az5h344u'));
    }

    public setPreventRedirect(con: boolean): void {
        localStorage.setItem('9d76etz6-88zt-j3hz-lpp0-76az5h344u', JSON.stringify(con));
    }

    public deletePreventRedirect(): void {
        localStorage.removeItem('9d76etz6-88zt-j3hz-lpp0-76az5h344u');
    }

    // delete
    public deleteAll(): void {
        // localStorage.removeItem('selectedPreReservation');
        // localStorage.removeItem('selectedTimeSlot');
        // localStorage.removeItem('selectedService');
        // localStorage.removeItem('selectedContractor');
        // localStorage.removeItem('mf-selectedContractor');
        // localStorage.removeItem('customerForTerm');
        // localStorage.removeItem('selectedCustomerId');
        // localStorage.removeItem('selectedTerm');
        // localStorage.removeItem('selectedCustomer');
        // this.deleteExternalOrder();
        // this.deleteAuthorizationOrder();
        // this.deleteExternalMode();
        // this.deleteBusinessData();
        localStorage.clear();

        // localStorage.removeItem('mfa-userData');
        // localStorage.clear();
    }

    //helpers
    public set(name: string, data) {
        localStorage.setItem(name, JSON.stringify(data));
    }

    public get(name: string): any {
        return JSON.parse(localStorage.getItem(name));
    }
}
