import { StorageService } from './../../../shared/services/storage.service';
import { Country } from './../../../shared/model/country.model';
import { MfToastService } from './../../../shared/services/mfToast.service';
import { CustomerFormHelperService } from './customer-form.helper.service';
import { Customer } from './../../../shared/model/customer.model';
import { FormGroup, FormBuilder, Validators, FormArray, ValidatorFn } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AtLeastOneFieldValidator } from './../../../shared/validators/atLeastOne.validator';

@Component({
    selector: 'common-customer-form',
    templateUrl: './customer-form.component.html',
    styleUrls: ['./customer-form.component.scss'],
})
export class CustomerFormComponent implements OnInit {
    // public properties: any;
    //8 = medifit EMSO ni obvezen

    // public customerIdValidators: any[] = [
    //     Validators.maxLength(13),
    //     Validators.minLength(13),
    //     Validators.pattern('^[0-9]*$'),
    // ];

    @Input()
    createCustomer() {
        this.isSubmited = true;
        const customer: Customer = new Customer().deserialize(this.customerDetailsForm.value);
        customer.id = _.random(0, 99999) + '';
        customer.birthDate = moment(customer.birthDate, 'DD.MM.YYYY').toDate();
        customer.birthDateString = moment(customer.birthDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        customer.emso = _.get(this.customerDetailsForm, 'value.customerId.emso');
        if (customer.emso == '') customer.emso = undefined;
        customer.kzz = _.get(this.customerDetailsForm, 'value.customerId.kzz');
        if (customer.kzz == '') customer.kzz = undefined;
        customer.taxNo = _.get(this.customerDetailsForm, 'value.customerId.taxNo');
        if (customer.taxNo == '') customer.taxNo = undefined;
        customer.mobilePhone = _.get(this.customerDetailsForm, 'value.phone');
        customer.country = _.get(this.customerDetailsForm, 'value.country');
        let name = (<string>_.get(this.customerDetailsForm, 'value.name', '')).trim();
        let surname = (<string>_.get(this.customerDetailsForm, 'value.surname', '')).trim();
        customer.name = name ? name.charAt(0).toUpperCase() + name.slice(1) : undefined;
        customer.surname = surname ? surname.charAt(0).toUpperCase() + surname.slice(1) : undefined;

        // this.isSubmited = true;

        if (!this.customerDetailsForm.valid) {
            /*
            if (this.customerDetailsForm.controls.customerId['controls'].emso.errors.incorrect) {
                this.toast.error('Napaka pri dodajanju stranke! EMŠO že obstaja.');
            }*/
            return;
        }
        this.cusHelper.manageCustomer(customer).subscribe(
            (medifitId: number) => {
                customer.medifitId = medifitId;
                this.toast.success('Stranka uspešno dodana.');
                //this.onCustomerManaged.next(customer);
                this.activeModal.close(customer);
            },
            (error) => {
                let errorMessage = 'Napaka pri dodajanju stranke!';
                switch (error.error.errors[0].code) {
                    case 'validatePhone':
                        errorMessage = 'Napaka pri dodajanju stranke! Telefonska številka ni ustrezna.';
                        break;
                    case 'validateEMSO':
                    case 'validateEMSO and validateKZZ':
                    // if (this.customerDetailsForm.get('customerId.taxNo')) {
                    //     this.customerDetailsForm.controls['customerId']['controls']['taxNo'].setErrors({
                    //         incorrect: true,
                    //         message: 'Davčna številka ni pravilna',
                    //     });
                    //     errorMessage = 'Napaka pri shranjevanju sprememb! Davčna številka ni pravilna';
                    // }
                    // if (this.customerDetailsForm.get('customerId.emso')) {
                    //     this.customerDetailsForm.controls['customerId']['controls']['emso'].setErrors({
                    //         incorrect: true,
                    //         message: 'EMŠO ni pravilen',
                    //     });
                    //     errorMessage = 'Napaka pri shranjevanju sprememb! EMŠO ni pravilen.';
                    // }
                    // if (this.customerDetailsForm.get('customerId.kzz')) {
                    //     this.customerDetailsForm.controls['customerId']['controls']['kzz'].setErrors({
                    //         incorrect: true,
                    //         message: 'Številka KZZ ni pravilna',
                    //     });
                    //     errorMessage = 'Napaka pri shranjevanju sprememb! Številka KZZ ni pravilna.';
                    // }

                    // if (
                    //     this.customerDetailsForm.get('customerId.kzz') &&
                    //     this.customerDetailsForm.get('customerId.emso')
                    // ) {
                    //     errorMessage = 'Napaka pri shranjevanju sprememb! Številka KZZ ali EMŠO ni pravilen.';
                    // }
                    // break;
                    case 'DuplicateEmso':
                    case 'existingBsCustomers':
                    case 'DuplicateEmso or DuplicateKZZ':
                        // if (this.customerDetailsForm.get('customerId.emso')) {
                        //     errorMessage = 'Napaka pri shranjevanju sprememb! EMŠO že obstaja.';
                        //     this.customerDetailsForm.controls['customerId']['controls']['emso'].setErrors({
                        //         incorrect: true,
                        //         message: 'EMŠO že obstaja',
                        //     });
                        // }
                        // if (this.customerDetailsForm.get('customerId.kzz')) {
                        //     errorMessage = 'Napaka pri shranjevanju sprememb! Številka KZZ že obstaja.';
                        //     this.customerDetailsForm.controls['customerId']['controls']['kzz'].setErrors({
                        //         incorrect: true,
                        //         message: 'Številka KZZ že obstaja',
                        //     });
                        // }

                        // if (
                        //     this.customerDetailsForm.get('customerId.kzz') &&
                        //     this.customerDetailsForm.get('customerId.emso')
                        // ) {
                        //     errorMessage =
                        //         'Napaka pri shranjevanju! Številka KZZ ali EMŠO že obstaja.';
                        // }

                        errorMessage = 'Napaka pri shranjevanju! Številka KZZ, EMŠO ali davčna številka že obstaja.';
                        break;

                    default:
                        errorMessage = 'Napaka pri dodajanju stranke!';
                        break;
                }

                this.toast.error(errorMessage);
            },
        );
    }

    @Input()
    isReadOnly: boolean = false;

    @Input()
    isSmallInput: boolean = false;

    @Input()
    customer: Customer;

    @Output()
    onCustomerManaged: EventEmitter<Customer> = new EventEmitter();

    get formData() {
        return <FormArray>this.customerDetailsForm.get('customerId');
    }

    public isSubmited: boolean = false;
    public dateOfBirthMask: any = [/[0-3]/, /\d/, '.', /[0-1]/, /\d/, '.', /[1-2]/, /\d/, /\d/, /\d/];
    public customerDetailsForm: FormGroup;
    public countryList: Country[] = [];

    constructor(
        public fb: FormBuilder,
        public cusHelper: CustomerFormHelperService,
        public toast: MfToastService,
        public activeModal: NgbActiveModal,
        public storage: StorageService,
    ) {}

    ngOnInit() {
        // this.manageFormInputs();
        this.createForm();
        this.getCountriesForInput();
        this.formValidation();
    }

    // private manageFormInputs(): void {
    //     debugger;
    //     switch (this.storage.getBusinessSubject().id) {
    //         case 2:
    //             this.properties = {
    //                 emso: false,
    //                 kzz: true,
    //                 taxNo: false,
    //                 isRequired: true,
    //             };
    //             break;
    //         case 7:
    //         case 9:
    //             this.properties = {
    //                 emso: false,
    //                 kzz: false,
    //                 taxNo: true,
    //                 isRequired: true,
    //             };
    //             break;

    //         case 10:
    //             this.properties = {
    //                 emso: true,
    //                 kzz: true,
    //                 taxNo: true,
    //                 isRequired: false,
    //             };
    //             break;
    //         case 8:
    //             this.properties = {
    //                 emso: true,
    //                 kzz: false,
    //                 taxNo: false,
    //                 isRequired: false,
    //             };
    //             break;

    //         default:
    //             this.properties = {
    //                 emso: true,
    //                 kzz: false,
    //                 taxNo: false,
    //                 isRequired: true,
    //             };
    //             break;
    //     }
    // }

    public getCountriesForInput(): void {
        this.cusHelper.getCountries().subscribe((res) => {
            this.countryList = res.sort((a, b) => {
                return a.country.localeCompare(b.country);
            });
            const emptyCountry: Country = new Country().deserialize({ id: -1, country: 'Ostalo', code: 'OST' });

            const slovenia = this.countryList.find((country) => {
                if (country.code === 'SI') {
                    return true;
                }
                return false;
            });

            this.countryList.unshift(emptyCountry);
            this.fillcountry(slovenia);
        });
    }

    public createForm(): void {
        // if (this.isNewCustomer) {
        //     this.customer = new Customer().deserialize({});
        //     this.customer.sendMail = true;
        //     this.customer.sendSms = true;
        // }

        // this.permissionsService.hasPermission('customer_details_canEdit').thenthis.isReadOnly => {
        // ce ni editable pomeni da nima pravice, ce pa je editebla pa lahko spreminjamo

        // this.isPermissionsLoaded = true;
        let formFields = {
            name: this.fb.control({ value: undefined, disabled: this.isReadOnly }, Validators.required),
            surname: this.fb.control({ value: undefined, disabled: this.isReadOnly }, Validators.required),
            gender: this.fb.control({ value: undefined, disabled: this.isReadOnly }, Validators.required),
            birthDate: this.fb.control(
                {
                    value: undefined,
                    disabled: this.isReadOnly,
                },
                Validators.required,
            ),
            address: this.fb.control({ value: undefined, disabled: this.isReadOnly }),
            post: this.fb.control({ value: undefined, disabled: this.isReadOnly }),
            city: this.fb.control({ value: undefined, disabled: this.isReadOnly }),
            email: this.fb.control({ value: undefined, disabled: this.isReadOnly }),
            phone: this.fb.control({ value: undefined, disabled: this.isReadOnly }, [
                Validators.pattern(/^[\s\d]+$/),
                Validators.minLength(9),
                Validators.required,
            ]),
            country: this.fb.control({ value: undefined, disabled: this.isReadOnly }),
            customerId: this.fb.group(
                {
                    kzz: this.fb.control({ value: undefined, disabled: this.isReadOnly }, [
                        Validators.maxLength(9),
                        Validators.minLength(9),
                        Validators.pattern('^[0-9]*$'),
                    ]),
                    emso: this.fb.control({ value: undefined, disabled: this.isReadOnly }, [
                        Validators.maxLength(13),
                        Validators.minLength(13),
                        Validators.pattern('^[0-9]*$'),
                    ]),
                    taxNo: this.fb.control({ value: undefined, disabled: this.isReadOnly }, [
                        Validators.maxLength(8),
                        Validators.minLength(8),
                        Validators.pattern('^[0-9]*$'),
                    ]),
                },
                {
                    validator: AtLeastOneFieldValidator(this.storage.getBusinessSubject().id),
                },
            ),
        };

        this.customerDetailsForm = this.fb.group(formFields);
        // debugger;
        if (this.customer) {
            this.customerDetailsForm.patchValue(this.customer);
            this.customerDetailsForm.controls.birthDate.setValue(moment(this.customer.birthDate).format('DD.MM.YYYY'));
        }
        // });
    }

    public fillcountry(e): void {
        if (e.country !== 'SLOVENIJA') {
            if (this.customerDetailsForm.get('customerId.emso')) {
                this.customerDetailsForm.get('customerId.emso').clearValidators();
                this.customerDetailsForm.get('customerId.emso').setValidators([Validators.required]);
                this.customerDetailsForm.get('customerId.emso').updateValueAndValidity();
            }
            if (this.customerDetailsForm.get('customerId.kzz')) {
                this.customerDetailsForm.get('customerId.kzz').clearValidators();
                this.customerDetailsForm.get('customerId.kzz').setValidators([Validators.required]);
                this.customerDetailsForm.get('customerId.kzz').updateValueAndValidity();
            }
        } else {
            if (this.customerDetailsForm.get('customerId.emso')) {
                // this.customerDetailsForm.get('customerId.emso').setValidators(this.customerIdValidators);
                this.customerDetailsForm.get('customerId.emso').updateValueAndValidity();
            }
            if (this.customerDetailsForm.get('customerId.kzz')) {
                // this.customerDetailsForm.get('customerId.kzz').setValidators(this.customerIdValidators);
                this.customerDetailsForm.get('customerId.kzz').updateValueAndValidity();
            }
        }
        this.customerDetailsForm.controls.country.setValue(e.country);
    }

    public dismiss(customer: any): void {
        this.activeModal.dismiss(customer);
    }

    public close(customer: any): void {
        this.activeModal.close(customer);
    }

    private formValidation(): void {
        switch (this.storage.getBusinessSubject().id) {
            case 10:
            case 8:
                this.customerDetailsForm.get('customerId').clearValidators();

                break;
            case 7:
                this.customerDetailsForm
                    .get('customerId')
                    ['controls'].taxNo.setValidators([
                        Validators.required,
                        this.customerDetailsForm.get('customerId')['controls'].taxNo.validator,
                    ]);
        }
    }
}
