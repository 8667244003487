import { Country } from './../../../shared/model/country.model';
import { Observable } from 'rxjs';
import { StorageService } from './../../../shared/services/storage.service';
import { CustomerService } from './../../../shared/services/customer.service';
import { Customer } from './../../../shared/model/customer.model';
import { MfToastService } from '../../../shared/services/mfToast.service';
import { HelperService } from '../../../shared/services/helper.service';

import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class CustomerFormHelperService {
    constructor(
        private toastr: MfToastService,
        private helper: HelperService,
        private rest: CustomerService,
        private storage: StorageService,
    ) {}

    public manageCustomer(customer: Customer): Observable<any> {
        return this.rest.createBusinessSubjectCustomer(this.storage.getBusinessSubject().id, customer);
    }

    public getCountries(): Observable<Country[]> {
        return this.rest.getCountries();
    }
}
