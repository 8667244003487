import { UrlCase } from './../shared/model/auth/url-case.model';
import { HelperService } from './../shared/services/helper.service';
import { Store } from '@ngxs/store';
import { Customer } from './../shared/model/customer.model';
import { IntegrationService } from './../shared/services/integration.service';
import { CustomerService } from './../shared/services/customer.service';
import { StorageService } from './../shared/services/storage.service';
import { UrlDataBS1 } from './../shared/model/auth/BS1/url-data-bs1.model';
import { UrlData } from './../shared/model/auth/url-data.model';
import { Injectable } from '@angular/core';
import { Base64 } from 'js-base64';
import { of } from 'rxjs/internal/observable/of';
import { Observable, Subject, throwError, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BaseCaseState } from '../theme/pages/_base/base-case/_state/base-case.state';
import { GenderType } from '../shared/enums/genderType.enum';
@Injectable({
    providedIn: 'root',
})
export class AuthHelperService {
    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private storage: StorageService,
        private customerRest: CustomerService,
        private integrationRest: IntegrationService,
        public store: Store,
        public helper: HelperService,
    ) {}

    ngOnDestroy() {
        this.destroy$.unsubscribe();
    }
    /**
     * manageUrlData
     */
    // return observable

    public manageUrlData(data: any, caseId?: string | number): Observable<UrlData | UrlDataBS1> {
        let parsedData: any;
        // let urlData = new UrlData().deserialize(parsedData);
        let urlData: UrlData = new UrlData();
        if (data) {
            try {
                parsedData = JSON.parse(Base64.decode(data));
            } catch (e) {
                console.error('Error parsing JSON url data!');
            }
        }
        if (parsedData && parsedData.customer) {
            //odstranimo parametre z praznimi stringi
            for (let key in parsedData.customer) {
                if (parsedData.customer[key] == '') {
                    delete parsedData.customer[key];
                }
            }
        }
        switch (this.storage.getBusinessSubject().id) {
            case 1:
                if (_.has(parsedData, 'customer.taxnumber')) {
                    _.set(parsedData, 'customer.taxNo', parsedData.customer.taxnumber);
                }
                return of(new UrlDataBS1().deserialize(parsedData));
            case 7:
                const token = this.store.selectSnapshot(BaseCaseState.getToken);
                const tokenData = this.helper.parseJWT(token);
                let userName: string = tokenData.user_name;
                if (userName.indexOf(':') > 0) {
                    userName = userName.split(':')[1];
                }

                return this.integrationRest.getExtraInfo(<string>caseId, userName).pipe(
                    map((extraInfo) => {
                        let urlData = new UrlData();
                        urlData.additionalData = {
                            bsDiagnose: extraInfo.diagnose,
                            bsInsuranceNumber: extraInfo.insuranceNumber,
                            bsInsurancePolicy: extraInfo.policyNumber,
                            bsTreatmentReason: extraInfo.examReason,
                        };
                        const insured = extraInfo.insured;
                        if (!_.has(insured, 'taxnumber')) {
                            // throw throwError({ id: 'CUSTOMER_MISSING_TAXNUMBER' });
                            throw { id: 'CUSTOMER_MISSING_TAXNUMBER' };
                        }

                        urlData.customer = new Customer().deserialize({
                            id: insured.id,
                            name: insured.firstname,
                            surname: insured.lastname,
                            address:
                                _.get(insured, 'address.street', '') + ' ' + _.get(insured, 'address.houseNumber', ''),
                            city: _.get(insured, 'address.post', ''),
                            post: _.get(insured, 'address.postCode', ''),
                            country: _.get(insured, 'address.country', ''),
                            phone: _.get(insured, 'address.mobilephone', ''),
                            mobilePhone: _.get(insured, 'mobilephone', ''),
                            email: _.get(insured, 'email', ''),
                            taxNo: _.get(insured, 'taxnumber', ''),
                            // birthDate: moment(_.get(insured, 'birthdate', ''), 'YYYY-MM-DD').toDate(),
                            // gender: _.get(insured, 'gender', null),
                            // birthDateString: _.get(insured, 'birthdate', null),
                        });

                        if (_.has(insured, 'gender')) {
                            urlData.customer.gender =
                                _.get(insured, 'gender') == 'F' ? GenderType.FEMALE : GenderType.MALE;
                        }

                        if (_.has(insured, 'birthdate')) {
                            urlData.customer.birthDateString = _.get(insured, 'birthdate');
                        }
                        urlData.case = new UrlCase().deserialize({
                            id: caseId,
                            additionalData: {
                                bsDiagnose: extraInfo.diagnose,
                                bsInsuranceNumber: extraInfo.insuranceNumber,
                                bsInsurancePolicy: extraInfo.policyNumber,
                                bsTreatmentReason: extraInfo.examReason,
                            },
                        });

                        return urlData;
                    }),
                );
            // .subscribe((extraInfo) => {
            //     // //TODO
            //     // debugger;
            //     // console.log(extraInfo);
            //     this.store
            //         .dispatch(
            //             new SetAdditionalData({
            //                 bsDiagnose: extraInfo.diagnose,
            //                 bsInsuranceNumber: extraInfo.insuranceNumber,
            //                 bsInsurancePolicy: extraInfo.policyNumber,
            //                 bsTreatmentReason: extraInfo.examReason,
            //             }),
            //         )
            //         .pipe(takeUntil(this.destroy$));
            // });
            case 5:
                // let urlData = new UrlData().deserialize(parsedData);
                return this.customerRest.getBsCustomer(this.storage.getBusinessSubject().id, urlData.customer.id).pipe(
                    map((customerData) => {
                        urlData.customer = customerData;
                        urlData.customer.mobilePhone = customerData.mobilePhone
                            ? customerData.mobilePhone
                            : customerData.mobilephone;
                        return urlData;
                    }),
                );
            case 24:
                if (isNaN(<number>_.get(parsedData, 'case.id'))) {
                    return throwError({ id: 'CASE_NOT_NUMBER' });
                }
                if (_.get(parsedData, 'case.serviceId') && _.get(parsedData, 'case.contractorId')) {
                    // return forkJoin({
                    //     serviceMapping: this.buRest.getServicesForBus(
                    //         this.storage.getUserData().businessSubjects[0].id,
                    //     ),
                    //     contractorMapping: this.contractorRest.mapExternalContractor(
                    //         this.storage.getUserData().businessSubjects[0].id,
                    //         parsedData.case.contractorId,
                    //     ),
                    // }).pipe(
                    //     map((mappingData: { serviceMapping; contractorMapping }) => {
                    //         //map services
                    //         const connectedServices = mappingData.serviceMapping.filter(
                    //             (services) => services.name && services.id == parsedData.case.serviceId,
                    //         );
                    //         if (connectedServices) parsedData.service = connectedServices[0];
                    //         //map contractor
                    //         parsedData.case.contractorId = mappingData.contractorMapping;
                    //         parsedData.contractorId = mappingData.contractorMapping;
                    //         return new UrlData().deserialize(parsedData);
                    //     }),
                    // );
                } else {
                    return of(new UrlData().deserialize(parsedData));
                }

            case 2:
            default:
                return of(new UrlData().deserialize(parsedData));
        }
    }
}
