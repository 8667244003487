import { HelperService } from './../../../../shared/services/helper.service';
import { HttpClientService } from './../../../../shared/services/http-client.service';
import { TphCallEnded } from './model/tph-call-ended.model';
import { map } from 'rxjs/operators';

import { BehaviorSubject, Observable, EMPTY, of } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import AircallPhone from 'aircall-everywhere';

@Injectable({
    providedIn: 'root',
})
export class TelephonyService {
    public showWindow: EventEmitter<string> = new EventEmitter();
    public hiddeWindow: EventEmitter<any> = new EventEmitter();
    public initTelephony: EventEmitter<boolean> = new EventEmitter();
    public onIncomingCall: EventEmitter<any> = new EventEmitter();
    public onOutgoingCall: EventEmitter<TphCallEnded> = new EventEmitter();
    public onOutgoingAnswered: EventEmitter<TphCallEnded> = new EventEmitter();
    public onCallEnded: EventEmitter<TphCallEnded> = new EventEmitter();
    public oncCallEndRingtone: EventEmitter<TphCallEnded> = new EventEmitter();
    // public destructorTelephony: EventEmitter<boolean> = new EventEmitter();

    // BehaviorSubject

    private phone: AircallPhone;
    constructor(private httpClient: HttpClientService, private helper: HelperService) {}

    public show(phoneNumber?: string): void {
        this.showWindow.next(phoneNumber);
    }

    public hidde(): void {
        this.hiddeWindow.next(true);
    }

    public init(test: boolean = true): void {
        this.initTelephony.next(test);
    }

    // public setCustomer(customer:Customer): void {
    //     this.showWindow.next(true);
    // }

    public initPhone(): void {
        this.phone = new AircallPhone({
            domToLoadPhone: '#mf-phone',
            onLogin: (settings) => {
                // ...
                // debugger;
            },
            onLogout: () => {
                // ...
                // debugger;
            },
        });
        this.phone.on('call_ended', (callInfos) => {
            // debugger;
            this.onCallEnded.next(new TphCallEnded().deserialize(callInfos));
        });

        this.phone.on('call_end_ringtone', (callInfos) => {
            // debugger;
            this.oncCallEndRingtone.next(callInfos);
        });

        this.phone.on('outgoing_call', (callInfos) => {
            // debugger;
            this.onOutgoingCall.next(callInfos);
        });

        this.phone.on('incoming_call', (callInfos) => {
            // debugger;
            this.onIncomingCall.next(callInfos);
            this.show();
        });

        this.phone.on('outgoing_answered', (callInfos) => {
            // debugger;
            this.onOutgoingAnswered.next(callInfos);
        });

        // debugger;
    }

    public isLoggedIn(): boolean {
        // debugger;

        return this.phone.isLoggedIn((response) => {
            // ...
            // debugger;
            return true;
        });
    }

    public callNumber(number: string): void {
        const payload = {
            phone_number: number,
        };

        this.phone.send('dial_number', payload, (success, data) => {
            // ...
        });
    }

    public isUserRegistered(cid: number, email: string): Observable<boolean> {
        return this.httpClient.get(this.helper.getAirCallUrl() + `/contractors/${cid}/aircall/users/`).pipe(
            map((data: any) => {
                return (<[]>data.users).some((el: any) => el.email == email);
            }),
        );
    }

    public registerNewUser(cid: number, acUser: any): Observable<any> {
        return this.httpClient.post(this.helper.getAirCallUrl() + `/contractors/${cid}/aircall/users/`, acUser);
    }

    public saveCall(cid: number, callid: number): Observable<any> {
        return this.httpClient.post(this.helper.getAirCallUrl() + `/contractors/${cid}/calls/${callid}`);
    }
    // public descructor(): void {
    //     this.destructorTelephony.next(true);
    // }
}
