<a href="#" class="m-nav__link m-dropdown__toggle" id="m_topbar_notification_icon" (click)="click()">
    <span class="m-topbar__notifications_number" *ngIf="showAlert && visibleNumberOfNotifications > 0">
        {{ visibleNumberOfNotifications }}
    </span>
    <span class="m-nav__link-icon">
        <span class="m-nav__link-icon-wrapper" [ngClass]="{ 'm-nav__link-icon-wrapper-active': showAlert }">
            <i class="la la-bell" id="m_quick_sidebar_toggle" [ngClass]="{ 'm-animate-shake': showAlert }"></i>
        </span>
    </span>
</a>
