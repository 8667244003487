import {Component, Input, OnInit} from '@angular/core';
import { Contractor } from '../../../shared/model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MfToastService, StorageService } from '../../../shared/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContractorsService } from '../../../shared/services/contractors.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-external-service-form',
    templateUrl: './external-service-form.component.html',
    styles: [],
})
export class ExternalServiceFormComponent implements OnInit {
    public isSmallInput: boolean = false;
    public isSubmitted: boolean = false;
    public externalServiceForm: FormGroup;
    private destroy$: Subject<boolean> = new Subject<boolean>();

    @Input()
    contractor: Contractor;
    
    constructor(
        public fb: FormBuilder,
        public toast: MfToastService,
        public activeModal: NgbActiveModal,
        public contractorsService: ContractorsService,
        private storage: StorageService,
    ) {}

    ngOnInit() {
        this.createForm();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public createForm(): void {
        const formFields = {
            name: this.fb.control({ value: undefined, disabled: false }, Validators.required),
        };

        this.externalServiceForm = this.fb.group(formFields);
    }

    public dismiss(contractor: any): void {
        this.activeModal.dismiss(contractor);
    }

    public close(contractor: any): void {
        this.activeModal.close(contractor);
    }

    public createService() {
        this.isSubmitted = true;

        if (!this.externalServiceForm.valid || !this.contractor.externalId) {
            return;
        }
        const formValue: any = this.externalServiceForm.value;

        this.contractorsService.addSeparatedContractorService(this.storage.getBusinessSubject().id, this.contractor.externalId, formValue).subscribe(
            (res) => {
                this.activeModal.close();
                this.toast.success('Uspešno dodajanje storitve');
            },
            () => {
                this.toast.error('Napaka pri dodajanju storitve');
            },
        );
    }
}
