import { TokenInterceptorService } from './../token.interceptor.service';
import { AuthService } from './../shared/services/auth.service';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StorageService } from './../shared/services/storage.service';
import { SharedModule } from './../shared/shared.module';
import { HelperService } from './../shared/services/helper.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuthRoutingModule } from './auth-routing.routing';
import { AuthComponent } from './auth.component';
import { AlertComponent } from './_directives/alert.component';
import { LogoutComponent } from './logout/logout.component';
import { AuthGuard } from './_guards/auth.guard';
import { AlertService } from './_services/alert.service';
import { AuthenticationService } from './_services/authentication.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    declarations: [AuthComponent, AlertComponent, LogoutComponent],
    imports: [CommonModule, FormsModule, AuthRoutingModule, SharedModule, HttpClientModule],
    providers: [
        AuthGuard,
        AlertService,
        AuthenticationService,
        // api backend simulation
        //BaseRequestOptions,
        HelperService,
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
    ],
    entryComponents: [AlertComponent],
})
export class AuthModule {}
