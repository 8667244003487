import { Subject } from 'rxjs';
import { Service } from './../../../../shared/model/service/service.model';
import { Slot } from './../../../../shared/model/slot.model';
import { HelperService } from './../../../../shared/services/helper.service';
import { Component, OnInit, Input, ViewChild, wtfLeave } from '@angular/core';
import { NgbActiveModal, NgbModal } from '../../../../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { ContractorsService } from '../../../../shared/services/contractors.service';
import * as moment from 'moment';
import { DaterangePickerComponent } from '../../../../../../node_modules/ng2-daterangepicker';
import { StorageService } from '../../../../shared/services/storage.service';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-all-slots-popup',
    templateUrl: './all-slots-popup.component.html',
    styleUrls: ['./all-slots-popup.component.scss'],
})
export class AllSlotsPopupComponent implements OnInit {
    @Input()
    data: any;

    public selectedWeek: moment.Moment = moment().startOf('isoWeek');

    public showLoading: boolean = false;
    public noFutureTimeSlots: boolean = false;

    public dateRangePickeroptions: any = this.helper.getDateSelectorSettngsMinDate();
    public allSlots: any;
    public disableBackButton: boolean = false;

    private onDestroy$ = new Subject<void>();

    @ViewChild(DaterangePickerComponent, { static: true })
    private picker: DaterangePickerComponent;

    constructor(
        public activeModal: NgbActiveModal,
        private contractorsRest: ContractorsService,
        private helper: HelperService,
        private storage: StorageService,
        private modalService: NgbModal,
    ) {}

    ngOnInit() {
        this.loadData();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    public changeDate(event): any {
        // debugger;
        //  this.dateFrom = event.start.format('YYYY-MM-DD');
        this.selectedWeek = event.start.clone().startOf('isoWeek');
        this.loadData();

        // this.dateTo = event.end.format('YYYY-MM-DD');
        // this.loadChartData();
        // if (this.selectedPerformace === 'service') {
        //     this.getServicesPerformance();
        // } else {
        //     this.getSubcontractorsPerformance();
        // }
    }

    public findNextDate(): void {
        this.showLoading = true;
        this.noFutureTimeSlots = false;
        let selectedDate = this.selectedWeek ? this.selectedWeek : moment();
        if (selectedDate.isBefore()) {
            selectedDate = moment();
        }
        this.contractorsRest
            .getFirstNFlatFreeSlots(
                500,
                this.storage.getBusinessSubject().id,
                [
                    {
                        contractor: {
                            id: this.data.contractor.id,
                            externalId: this.data.contractor.externalId,
                            subcontractors: [
                                { id: this.data.subcontractor.id, externalId: this.data.subcontractor.externalId },
                            ],
                        },
                        service: this.data.service,
                        orgUnit: {
                            externalId: _.get(this.data, 'service.orgUnit.externalId'),
                        },
                    },
                ],
                this.data.contractor.bpiType,
                selectedDate.format('YYYY-MM-DD'),
            )
            .subscribe(
                (response) => {
                    const timeSlots = _.get(response, 'contractors[0].subcontractors[0].timeSlots', null);
                    if (timeSlots) {
                        this.selectedWeek = moment(timeSlots[0].start).startOf('isoWeek');
                        this.picker.datePicker.setStartDate(this.selectedWeek);
                        this.picker.datePicker.setEndDate(this.selectedWeek);
                        this.loadData();
                    } else {
                        this.noFutureTimeSlots = true;
                        this.showLoading = false;
                    }
                },
                () => {
                    this.showLoading = false;
                },
            );
    }

    public currentDate(): void {
        this.selectedWeek = moment().startOf('isoWeek');
        this.picker.datePicker.setStartDate(this.selectedWeek);
        this.picker.datePicker.setEndDate(this.selectedWeek);
        this.loadData();
    }

    public nextDate(): void {
        this.selectedWeek.add(1, 'week');
        this.picker.datePicker.setStartDate(this.selectedWeek);
        this.picker.datePicker.setEndDate(this.selectedWeek);
        this.loadData();
    }

    public previousDate(): void {
        this.selectedWeek.subtract(1, 'week');
        this.picker.datePicker.setStartDate(this.selectedWeek);
        this.picker.datePicker.setEndDate(this.selectedWeek);
        this.loadData();
    }

    private loadData(): void {
        this.noFutureTimeSlots = false;
        const showLoading = setTimeout(() => {
            this.showLoading = true;
        }, 1000);
        const from: moment.Moment = this.selectedWeek;
        const to: moment.Moment = this.selectedWeek.clone().add(6, 'days');

        if (moment().isBetween(from, to)) {
            this.disableBackButton = true;
        } else {
            this.disableBackButton = false;
        }

        if (this.data) {
            const postData: any = {
                contractor: {
                    id: this.data.contractor.id,
                    externalId: this.data.contractor.externalId,
                    subcontractors: [
                        { id: this.data.subcontractor.id, externalId: this.data.subcontractor.externalId },
                    ],
                },
                serviceId: _.get(this.data, 'service.id'),
                externalServiceId: _.get(this.data, 'service.externalId', this.getComServ().externalId),
                from: from.format('YYYY-MM-DD'),
                to: to.format('YYYY-MM-DD'),
                orgUnit: {
                    externalId: _.get(this.data, 'service.orgUnit.externalId'),
                },
            };

            if (this.data.service.orgUnit) {
                postData.orgUnitExternalId = this.data.service.orgUnitExternalId;
            }
            // this.data.contractor.subcontractors = [this.data.subcontractor];
            this.contractorsRest
                .getFreeSlots(postData, this.storage.getBusinessSubject().id, this.data.contractor.bpiType)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                    (res) => {
                        let allSlots = this.filterSlots(res);
                        if (allSlots.timeSlots.length > 0) {
                            allSlots = this.fillEmptyDaySlots(allSlots);
                        }
                        this.allSlots = allSlots;
                        clearTimeout(showLoading);
                        this.showLoading = false;
                    },
                    () => {
                        clearTimeout(showLoading);
                        this.showLoading = false;
                    },
                );
        }
    }

    private filterSlots(slots: any): any {
        slots.timeSlots.map((el: any) => {
            if (moment().isSame(moment(el.date, 'YYYY-MM-DD'), 'day')) {
                el.slots = el.slots.filter((slot: any) => {
                    if (moment().isAfter(moment(slot.start))) {
                        return false;
                    }
                    return true;
                });
            }
            if (moment().isAfter(moment(el.date, 'YYYY-MM-DD'), 'day')) {
                el.slots = [];
            }
        });
        return slots;
    }

    private fillEmptyDaySlots(slots: any): any {
        console.log(slots);
        let firstSlot = moment();
        if (slots.timeSlots.length > 0) {
            firstSlot = moment(slots.timeSlots[0].date);
        }
        const weekStart = firstSlot.clone().startOf('isoWeek');
        const weekEnd = firstSlot.clone().endOf('isoWeek');
        const weekFriday = weekStart.clone().add(4, 'day');
        const currentDay = weekStart.clone();
        const newSlots: any[] = [];

        while (currentDay.isBefore(weekEnd)) {
            const sameDay = slots.timeSlots.find((existingDay) => {
                if (moment(existingDay.date).isSame(currentDay, 'day')) {
                    return true;
                }
                return false;
            });

            if (sameDay) {
                newSlots.push(sameDay);
            } else if (currentDay.isSameOrBefore(weekFriday)) {
                newSlots.push({
                    date: currentDay.format('YYYY-MM-DD'),
                    slots: [],
                });
            }
            currentDay.add(1, 'day');
        }

        slots.timeSlots = newSlots;

        return slots;
    }

    public makePreReservationNew(slot: Slot): void {
        this.data.slot = slot;
        this.activeModal.dismiss(this.data);
    }
    private getComServ(): Service {
        return _.get(this.data, 'contractor.subcontractors[0].services[0]');
    }
}
