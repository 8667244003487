export enum NotificationEnum {
    GDPR_CONSENT_COMPLETED = 'GDPR_CONSENT_COMPLETED',
    RESERVATION_COMPLETED = 'RESERVATION_COMPLETED',
    RESERVATION_UPDATED = 'RESERVATION_UPDATED',
    RESERVATION_CANCELED = 'RESERVATION_CANCELED',
    RESERVATION_CREATED = 'RESERVATION_CREATED',
    RESERVATION_MARKED_AS_NO_SHOW = 'RESERVATION_MARKED_AS_NO_SHOW',
    ASISTANT_CASE_DOCUMENT_ADDED = 'ASISTANT_CASE_DOCUMENT_ADDED',
    ASISTANT_CASE_COMMENT_ADDED = 'ASISTANT_CASE_COMMENT_ADDED',
    CASE_CANCELED = 'CASE_CANCELED',
    // triage
    ASISTANT_TRIAGE_DOCUMENT_ADDED = 'ASISTANT_TRIAGE_DOCUMENT_ADDED',
    ASISTANT_TRIAGE_COMMENT_ADDED = 'ASISTANT_TRIAGE_COMMENT_ADDED',
    TRIAGE_CONCLUDED = 'TRIAGE_CONCLUDED',
    TRIAGE_CREATED = 'TRIAGE_CREATED',
    TRIAGE_CANCELED = 'TRIAGE_CANCELED',
    TRIAGE_ANSWERED = 'TRIAGE_ANSWERED',
    TRIAGE_UNANSWERED = 'TRIAGE_UNANSWERED',
    // pricelist
    CONTRACTOR_PRICE_LIST_CONFIRMED = 'CONTRACTOR_PRICE_LIST_CONFIRMED',
    CONTRACTOR_PRICE_LIST_UPDATED = 'CONTRACTOR_PRICE_LIST_UPDATED',

    // prikaz na asistentu
    VISIT_CONCLUDED = 'VISIT_CONCLUDED',
    ASISTANT_CASE_CONTRACTOR_DOCUMENT_ADDED = 'ASISTANT_CASE_CONTRACTOR_DOCUMENT_ADDED',
    ASISTANT_TRIAGE_CONTRACTOR_DOCUMENT_ADDED = 'ASISTANT_TRIAGE_CONTRACTOR_DOCUMENT_ADDED',
    ASISTANT_CASE_CONTRACTOR_COMMENT_ADDED = 'ASISTANT_CASE_CONTRACTOR_COMMENT_ADDED',
    ASISTANT_TRIAGE_CONTRACTOR_COMMENT_ADDED = 'ASISTANT_TRIAGE_CONTRACTOR_COMMENT_ADDED',
}
