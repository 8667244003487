import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-number-of-terms-popup',
    templateUrl: './number-of-terms-popup.component.html',
    styles: [],
})
export class NumberOfTermsPopupComponent implements OnInit {
    @Input() termData: any;
    constructor(public activeModal: NgbActiveModal) {}
    public numberOfTerms: number = 1;
    ngOnInit() {}
}
