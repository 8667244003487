import { ComaToDotDirective } from './directives/coma-to-dot.directive';
import { TphCallNumberDirective } from './directives/tph-call-number.directive';
import { PartnerPipe } from './../contractor-app/pipes/partner.pipe';
import { MenuService } from './services/menu.service';
import { HttpClientService } from './services/http-client.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponent } from './shared.component';
import { HttpClientModule } from '@angular/common/http';
import { CaptchaComponent } from './components/captcha/captcha.component';
import { MetabaseReportComponent } from './components/metabase-report/metabase-report.component';
import { FormsModule } from '@angular/forms';
import { Daterangepicker } from 'ng2-daterangepicker';

@NgModule({
    imports: [CommonModule, HttpClientModule, FormsModule, Daterangepicker],
    declarations: [
        SharedComponent,
        PartnerPipe,
        CaptchaComponent,
        MetabaseReportComponent,
        TphCallNumberDirective,
        ComaToDotDirective,
    ],
    providers: [HttpClientService, MenuService],
    exports: [PartnerPipe, CaptchaComponent, MetabaseReportComponent, TphCallNumberDirective, ComaToDotDirective],
})
export class SharedModule {}
