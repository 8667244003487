import { Service } from './../service/service.model';
import { Contractor } from './../contractor.model';
import { Serializable } from '../deserialize.service';
import { ServiceSlots } from './serviceSlots.model';

export class SubcontracorFreeSlots implements Serializable<SubcontracorFreeSlots> {
    subcontractor: Contractor;
    service: Service;
    serviceSlots: ServiceSlots[];

    deserialize(input: any) {
        if (input.subcontractor) {
            this.subcontractor = new Contractor().deserialize(input.subcontractor);
        }

        if (input.serviceSlots) {
            this.serviceSlots = input.serviceSlots.map((el: any) => new ServiceSlots().deserialize(el));
        }

        if (input.service) {
            this.service = new Service().deserialize(input.service);
        }

        return this;
    }
}
