import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { HelperService } from './helper.service';
import { Observable } from 'rxjs';
import { Contractor } from '../model/contractor.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(private httpClient: HttpClientService, private helper: HelperService) {}

    public uploadFile(file: File, comment: string, cid: number): Observable<any> {
        return this.httpClient.uploadFile(this.helper.getDocumentUrl() + '/upload/contractors/' + cid, file, comment);
    }

    public getFileList(buId: number): Observable<Contractor[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl() + '/bu/' + buId + '/contractors/').pipe(
            map((contr) => {
                // debugger;
                return contr.map((subc) => new Contractor().deserialize(subc));
                // new Contractor().deserialize(el);
                // return [];
            }),
        );
    }

    public getDocumentsVR(reservationId: string, visitId?: number): Observable<Document[]> {
        if (visitId) {
            // return this.getDocumentsForVisit(visitId);
        } else {
            return this.getDocumentsForReservation(reservationId);
        }
    }

    public getDocumentsForReservation(reservationId: string): Observable<Document[]> {
        return this.httpClient
            .get(this.helper.getErpUrl() + '/reservations/' + reservationId + '/documents')
            .pipe(map((el) => el.documents));
    }
    public getAuthTemplatePdf(bsid, caseId): Observable<any> {
        return this.httpClient.getAttachment(
            this.helper.getAssistanceUrl(3) + `/bs/${bsid}` + '/cases/' + this.helper.replaceSlahsForDolar(caseId) + '/template/pdf',
        );
    }

    // addCustomerDocument()
    // public addFileToCustomer(contractorId: number, customerId: number, documentId: string): Observable<any> {
    //     return this.httpClient.post(
    //         this.helper.getErpUrl() +
    //             '/contractors/' +
    //             contractorId +
    //             '/customers/' +
    //             customerId +
    //             '/documents/' +
    //             documentId
    //     );
    // }

    // public deleteCustomerDocument(contractorId: number, customerId: number, documentId: number): Observable<any> {
    //     return this.httpClient.delete(
    //         this.helper.getErpUrl() +
    //             '/contractors/' +
    //             contractorId +
    //             '/customers/' +
    //             customerId +
    //             '/documents/' +
    //             documentId
    //     );
    // }

    public addFileToVR(reservationId: string, documentId: string, visitId?: number): Observable<any> {
        if (visitId) {
            // return this.addFileToVisit(visitId, documentId);
        } else {
            return this.addFileToReservation(reservationId, documentId);
        }
    }

    // public addFileToVisit(visitId: number, documentId: string): Observable<any> {
    //     return this.httpClient.post(this.helper.getVisitUrl() + '/visits/' + visitId + '/documents/' + documentId);
    // }

    public addFileToReservation(reservationId: string, documentId: string): Observable<any> {
        return this.httpClient.post(
            this.helper.getErpUrl() + '/reservations/' + reservationId + '/documents/' + documentId,
        );
    }

    public openDocument(contractorId: number, documentId: number): Observable<any> {
        return this.httpClient.getAttachment(
            this.helper.getDocumentUrl() + '/contractors/' + contractorId + '/documents/' + documentId,
        );
    }

    public deleteDocumentVR(prereservationid: string, documentId: number, visitId?: number): Observable<any> {
        if (visitId) {
            // return this.deleteDocumentFromVisit(visitId, documentId);
        } else {
            return this.deleteDocumentFromReseration(prereservationid, documentId);
        }
    }

    public deleteDocumentFromReseration(prereservationid: string, documentId: number): Observable<any> {
        return this.httpClient.delete(
            this.helper.getErpUrl() + '/reservations/' + prereservationid + '/documents/' + documentId,
        );
    }

    // public deleteDocumentFromVisit(visitId: number, documentId: number): Observable<any> {
    //     return this.httpClient.delete(this.helper.getVisitUrl() + '/visits/' + visitId + '/documents/' + documentId);
    // }
}
