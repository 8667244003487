import { Serializable } from './deserialize.service';
import { NotificationData } from './notificationData.model';

export class OneSignalNotification implements Serializable<OneSignalNotification> {
    //
    content: string;
    heading: string;
    icon: string;
    id: string;
    url: string;

    data: NotificationData;

    deserialize(input) {
        // debugger;
        this.content = input.content;
        this.heading = input.heading;
        this.icon = input.icon;
        this.id = input.id;
        this.url = input.url;

        if (input.data) {
            this.data = new NotificationData().deserialize(input.data);
        }

        return this;
    }
}

// channel:"EMAIL"
// customerId:43
// customerName:"Nina Test"
// description:"Obvestilo o rezervaciji"
// queueId:"01ab4f1b-cf1d-42d4-b680-de6949bb6faf"
// sentTime:"2018-03-21T12:14:04.265"
// status:"SENT"
