import { Serializable } from '../deserialize.service';
import { Comment } from '../comment.model';
import { Document } from '../document.model';

export class AuthorityCaseAttachment implements Serializable<AuthorityCaseAttachment> {
    public type: string;
    public local: boolean;
    public value: any;
    public dateAdded: any;

    deserialize(input) {
        // debugger;

        this.type = input.type;
        this.local = input.local;

        if (input.type === 'comment') {
            this.value = new Comment().deserialize({
                commentAdded: input.value.commentAdded,
                comment: input.value.comment,
                comentatorName: input.value.comentatorName,
                id: input.value.id,
            });
            this.dateAdded = input.value.commentAdded;
        }

        if (input.type === 'document') {
            this.value = new Document().deserialize({
                documentAdded: input.value.documentAdded,
                description: input.value.description,
                url: input.value.url,
                id: input.value.id,
                documentId: input.value.documentId,
                appUserId: input.value.appUserId,
                appUserName: input.value.appUserName,
                documentSource: input.value.documentSource,
            });
            this.dateAdded = input.value.documentAdded;
        }

        return this;
    }
}
