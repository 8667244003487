import { Serializable } from '../deserialize.service';

export class UpdateCasePricesRequestDTO implements Serializable<UpdateCasePricesRequestDTO> {
    bsId: number;
    caseId: string;
    totalPrice: number;
    customerPrice: number;
    coveragePrice: number;
    coveragePercentage: number;
    //nepovezani izvajalci lahko naknadno spreminjajo ceno. logPriceChange določi, ali se updateCaseCoverage klic pokaže v history-logu
    logPriceChange: boolean;
    numberOfServices: number;

    deserialize(input: any) {
        this.bsId = input.bsId;
        this.caseId = input.caseId;
        this.totalPrice = input.totalPrice;
        this.customerPrice = input.customerPrice;
        this.coveragePrice = input.coveragePrice;
        this.coveragePercentage = input.coveragePercentage;
        this.numberOfServices = input.numberOfServices;
        if (input.logPriceChange) this.logPriceChange = input.logPriceChange;
        return this;
    }
}
