import { Serializable } from './deserialize.service';

export class Country implements Serializable<Country> {
    id: number;
    code: string;
    country: string;

    deserialize(input) {
        this.id = input.id;
        this.code = input.code;
        this.country = input.country;
        return this;
    }
}
