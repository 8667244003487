import { BsGuiMetabaseData } from './bsGuiMetabaseData.model';
import { Serializable } from '../deserialize.service';

export class BsGuiAnalytics implements Serializable<BsGuiAnalytics> {
    public active: boolean;
    public metabase: BsGuiMetabaseData;
    public halodoctor: BsGuiMetabaseData;

    deserialize(input) {
        if (!input || input === null) {
            return null;
        }

        Object.keys(input).forEach((key) => (this[key] = input[key]));
        // this.termsAndConditions = input.termsAndConditions;

        return this;
    }
}
