import { Serializable } from '../deserialize.service';
import { AuthorityType } from '../../enums/authorityType.enum';
import { AuthorityCaseStatus } from '../../enums/authorityCaseStatus.enum';

export class AuthorizationTriage implements Serializable<AuthorizationTriage> {
    appUser: string;
    caseId: string;
    contractorName: string;
    created: string;
    customerName: string;
    serviceName: string;
    triageId: number;
    caseCanceled: string;
    triageStatus: string;
    caseStatus: string;

    authorityType: AuthorityType;
    status: AuthorityCaseStatus;

    deserialize(input: any) {
        this.appUser = input.appUser;
        this.caseId = input.caseId;
        this.contractorName = input.contractorName;
        this.created = input.created;
        this.customerName = input.customerName;
        this.serviceName = input.serviceName;
        this.triageId = input.triageId;
        this.caseStatus = input.caseStatus;
        return this;
    }
}
