import { AuthorizationType } from './../enums/authorizationType.enum';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'caseLogTypes',
})
export class CaseLogTypesPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (args) {
            switch (value) {
                case AuthorizationType.CASE_CREATED:
                case AuthorizationType.TRIAGE_CREATED:
                    return `m-${args}-metal`;
                case AuthorizationType.RESERVATION_UPDATED:
                    return `m-${args}-brand`;
                case AuthorizationType.RESERVATION_CANCELED:
                case AuthorizationType.CASE_CANCELED:
                case AuthorizationType.TRIAGE_CANCELED:
                case AuthorizationType.TRIAGE_REJECTED:
                    return `m-${args}-danger`;
                case AuthorizationType.VISIT_CONCLUDED:
                case AuthorizationType.TREATMENT_CONCLUDED:
                case AuthorizationType.TRIAGE_CONCLUDED:
                case AuthorizationType.TRIAGE_ANSWERED:
                    return `m-${args}-success`;
                default:
                    return `m-${args}-accent`;
            }
        } else {
            switch (value) {
                //exist
                case AuthorizationType.CASE_CREATED:
                    return 'Avtorizacija ustvarjena.';
                //exist
                case AuthorizationType.RESERVATION_CREATED:
                    return 'Termin rezerviran';
                //exist
                case AuthorizationType.WQ_ITEM_CREATED:
                    return 'Rezervacija v čakalni vrsti ustvarjena.';
                //exist
                case AuthorizationType.RESERVATION_UPDATED:
                    return 'Rezervacija spremenjena';
                //exist
                case AuthorizationType.VISIT_CONCLUDED:
                    return 'Obravnava zaključena';
                case AuthorizationType.TREATMENT_CONCLUDED:
                    return 'Obravnava zaključena';
                //exist
                case AuthorizationType.RESERVATION_CANCELED:
                    return 'Rezervacija preklicana in postavljena v čakalno vrsto';
                //exist
                case AuthorizationType.CASE_CANCELED:
                    return 'Avtorizacija preklicana.';
                //exist
                case AuthorizationType.VISIT_CREATED:
                    return 'Obravnava ustvarjena';
                case AuthorizationType.TRIAGE_CREATED:
                    return 'eTriaža ustvarjena.';
                case AuthorizationType.TRIAGE_CONCLUDED:
                    return 'eTriaža odgovorjena.';
                case AuthorizationType.TRIAGE_CANCELED:
                    return 'eTriaža preklicana.';
                case AuthorizationType.TRIAGE_REJECTED:
                    return 'eTriaža zavrnjena';
                case AuthorizationType.TRIAGE_ANSWERED:
                    return 'eTriaža odgovorjena.';
                case AuthorizationType.TRIAGE_REQUEST_SENT:
                    return 'eTriaža poslana.';
                case AuthorizationType.COVERAGE_PRICE_UPDATED:
                    return 'Urejanje cene/kritja storitve.';
                default:
                    return value;
            }
        }
    }
}
// visit - obravanva
// case - avtorizacija
// rezervacija v ČV -> WQ
