import { Serializable } from './deserialize.service';

export class NotificationMessage implements Serializable<NotificationMessage> {
    message: string;
    messageStyle: string;
    datePipe: boolean;
    icon: string;

    deserialize(input) {
        this.message = input.message;
        this.messageStyle = input.messageStyle;
        this.datePipe = input.datePipe;
        this.icon = input.icon;
        return this;
    }
}
