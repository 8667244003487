import { UrlCase } from './../auth/url-case.model';
import { User } from './../user.model';
import { Customer } from './../customer.model';
import { Serializable } from '../deserialize.service';

export class AuthorityCaseRequest implements Serializable<AuthorityCaseRequest> {
    public customer: Customer;
    public user: User;
    public case: UrlCase;
    public authorityId?: string;

    deserialize(input: any) {
        this.customer = new Customer().deserialize(input.customer);
        this.user = new User().deserialize(input.user);
        this.case = input.case;
        this.authorityId = input.authorityId;
        return this;
    }
}
