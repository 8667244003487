import { NotificationsService } from './../../../../shared/services/notifications.service';
import { ToastrService } from 'ngx-toastr';
import { QuickSidebarService } from './../../quick-sidebar/quick-sidebar.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// services
import { TriageService } from './../../../../shared/services/triage.service';
import { AuthorizationService } from './../../../../shared/services/authorization.service';
import { HelperService } from './../../../../shared/services/helper.service';
import { StorageService } from './../../../../shared/services/storage.service';
// models
import { NotificationMessage } from './../../../../shared/model/notificationMessage.model';
import { Router } from '@angular/router';
// ngxs
import { OpenAuthorityCase } from './../../../pages/_base/base-case/_actions/base-case.actions';
import { Store } from '@ngxs/store';
import { HelperNotificationService } from '../../../../shared/services/helper-notification.service';

@Component({
    selector: 'app-header-notification-element',
    templateUrl: './header-notification-element.component.html',
    styles: [],
})
// pardon tistemu, ki bo to popravljal
export class HeaderNotificationElementComponent implements OnInit {
    @Output()
    public messageClick: EventEmitter<any> = new EventEmitter();
    @Output()
    public resetNotif: EventEmitter<any> = new EventEmitter();
    @Input() public prereservationId: string;
    @Input() public notificationDate: Date;
    @Input() public title: string;
    @Input() public name: string;
    @Input() public messages: NotificationMessage[];
    @Input() public backgroundColor: string;
    @Input() public borderColor: string;
    @Input() public seen: boolean;
    @Input() public index: number;
    @Input() public dataInFooter: string;
    @Input() public link: string;
    @Input() public redirect: string;
    @Input() public data: any;
    @Input() public notificationId: number;

    constructor(
        private route: Router,
        private storage: StorageService,
        private helper: HelperService,
        private store: Store,
        private authService: AuthorizationService,
        private triageService: TriageService,
        private serviceSide: QuickSidebarService,
        private toastr: ToastrService,
        private notificationHelper: HelperNotificationService,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit() {}

    public messageRead(): void {
        this.messageClick.emit(this.index);
    }

    public redirectSwitch(): void {
        this.notificationsService
            .markNotificationAsSeen(this.storage.getBusinessSubject().id, this.notificationId)
            .subscribe(() => {
                this.seen = true;
            });
        this.serviceSide.toggle();
        this.notificationHelper.redirectSwitch(this.redirect, this.link, this.data);
        this.resetNotif.emit();
    }

    private redirectReservation(prereservationId: string): any {
        /*return this.reservRest
            .getReservation(this.storage.getSelectedContractor().id, prereservationId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res) => {
                if (!_.isNil(res.visitId)) {
                    this.visitRest
                        .isVisitConcluded(this.storage.getSelectedContractor().id, res.visitId)
                        .subscribe((result) => {
                            this.helper.navigateToVisitOrReservation(res);
                        });
                } else {
                    this.helper.navigateToVisitOrReservation(res);
                }
            });*/
    }

    public copyPreId(event: any) {
        let nav: any;
        nav = window.navigator;
        let text = event.target.textContent.trim();
        if (!text) {
            //klikneš na ikono, namesto besede
            return;
        }
        if (text.includes('ID:')) {
            text = text.split('ID:')[1].trim();
        }
        nav.clipboard.writeText(text).then(function() {});
        this.toastr.info('Šifra dodana v odložišče.');
    }
}
