import { PivotDef } from './../model/analytics/pivotDef.model';
import { Observable, of, throwError } from 'rxjs';
import { HelperService } from './helper.service';
import { HttpClientService } from './http-client.service';
import { Injectable } from '@angular/core';
import { AnalyticsConfig } from '../model/analyticsConfig.model';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    constructor(
        private helper: HelperService,
        private httpClient: HttpClientService,
        private storage: StorageService,
    ) {}

    public getPivotDefs(bs: number): Observable<PivotDef[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bs}/pivot-defs`);
    }

    public addPivotDefs(bs: number, data: PivotDef): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(2) + `/bs/${bs}/pivot-defs`, data);
    }

    public removePivotDefs(bs: number, pivotDefId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getAssistanceUrl(2) + `/bs/${bs}/pivot-defs/${pivotDefId}`);
    }

    public getPivotTableData(bs: number, from: string, to: string): Observable<any> {
        return this.httpClient.getAttachment(
            this.helper.getAssistanceUrl(2) + `/bs/${bs}/pivot-data?from=${from}&to=${to}`,
        );
    }

    public getTermDataFile(bs: number, from: string, to: string): Observable<any> {
        return this.httpClient.getAttachment(
            this.helper.getAssistanceUrl(3) + `/spec/bs/${bs}/used-specialities?from=${from}&to=${to}`,
        );
    }

    public getAnalyticsConfig(bsId?: number, userGuid?: string): Observable<AnalyticsConfig> {
        if (!bsId) {
            bsId = this.storage.getBusinessSubject().id;
        }

        if (!userGuid) {
            const tokenSdata = this.storage.getToken().sdata;
            console.log(tokenSdata);
            if (tokenSdata && tokenSdata.atoken) {
                userGuid = tokenSdata.atoken;
            } else {
                return throwError('userGuid missing');
            }
        }

        return this.httpClient.get(`https://services.medifit.si/public/asistent-dashboard-analytics/`, {
            params: {
                bsid: bsId,
                user: userGuid,
            },
        });
    }
}
