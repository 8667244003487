import {Slot} from './../slot.model';
import {Serializable} from '../deserialize.service';
import {Service} from '../service/service.model';

export class ServiceSlots implements Serializable<ServiceSlots> {
    service: Service;
    slots: Slot[];
    slotsError: boolean;
    lastMissingNotificationSent: number;

    deserialize(input: any) {
        this.slotsError = input.slotsError;

        if (input.service) {
            this.service = new Service().deserialize(input.service);
        }
        if (input.slots) {
            this.slots = input.slots.map((el: any) => new Slot().deserialize(el));
        }

        if (input.lastMissingNotificationSent) {
            this.lastMissingNotificationSent = input.lastMissingNotificationSent;
        }

        return this;
    }
}
