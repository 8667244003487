// import { UserSettings } from './userSettings.model';
import { Serializable } from './deserialize.service';
import { Contractor } from './contractor.model';

export class User implements Serializable<User> {
    id: number;
    // contractorId: number;
    name: string;
    surname: string;
    email: string;
    phone: string;
    roles: string[];
    webId: string;
    subcontractorId: number;
    businessSubjects: any[];
    contractorId: number;
    contractors: Contractor[];
    // settings: UserSettings;
    // contractors: Contractor[];
    // subcontractorColor: string;

    // constructor(obj?: any) {
    //     this.name = obj && obj.name || null;
    //     this.doctorBpi = obj && obj.doctorBpi || null;
    //     this.institutions = obj && obj.institutions || null;
    // }

    deserialize(input) {
        // debugger;
        this.id = input.id;
        // this.contractorId = input.contractorId;
        this.name = input.name;
        this.surname = input.surname;
        this.email = input.email;
        this.phone = input.phone;
        this.roles = input.roles;
        this.webId = input.webid;
        this.subcontractorId = input.subcontractorId;
        this.businessSubjects = input.businessSubjects;
        this.contractorId = input.contractorId;
        if (input.contractors) {
            this.contractors = input.contractors.map((cont) => new Contractor().deserialize(cont));
        }
        return this;
    }
}
