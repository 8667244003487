import { ServiceBUContractor } from './serviceBUContractor.model';
import { Service } from './service.model';
import { Serializable } from '../deserialize.service';

export class ServiceReference implements Serializable<ServiceReference> {
    data: ServiceBUContractor[];
    // serviceCont: Service;
    // serviceBU: Service;
    id: string;
    code: number; //AUDAX
    name: string;
    active: boolean;
    externalId: string;
    defaultPrice: number;
    minPrice: number;
    maxPrice: number;

    // panelId: number;
    deserialize(input) {
        if (input) {
            // super.deserialize(input);
            this.active = input.active;
            this.data = input.data.map((el) => {
                return new ServiceBUContractor().deserialize(el);
            });
            this.id = input.id;
            // this.serviceBU = new Service().deserialize(input.serviceBU);
            // this.serviceCont = new Service().deserialize(input.serviceCont);
            this.code = input.code;
            this.name = input.name;
            this.externalId = input.externalId;
            this.defaultPrice = input.defaultPrice;
            this.minPrice = input.minPrice;
            this.maxPrice = input.maxPrice;
            // this.panelId = input.panelId;
        }
        return this;
    }
}
