import { throwError as observableThrowError, Observable } from 'rxjs';
import { StorageService } from './../../shared/services/storage.service';
import { environment } from './../../../environments/environment';
import { HelperService } from './../../shared/services/helper.service';
//import { RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient, private helper: HelperService, private storage: StorageService) {}

    login(username: string, password: string): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Basic ' + environment.AUTHPASS,
            }),
        };

        return this.http
            .post(
                this.helper.getAuthUrl(),
                'username=' + encodeURIComponent(username.trim()) + '&password=' + encodeURIComponent(password)+ '&grant_type=password',
                httpOptions,
            )
            .pipe(
                map(
                    (response) => {
                        // login successful if there's a jwt token in the response

                        this.storage.saveToken(response);
                        return true;
                    },
                    (error) => {
                        console.error('Napaka pri: AuthenticationService');
                        return observableThrowError(error);
                    },
                ),
            );
    }

    logout() {
        this.storage.clearToken();
        this.storage.deleteAll();
        this.storage.deleteUserData();
        // this.storage.deleteAppData();
    }
}
