<!-- historyLog (case) + (triage)-->
<ng-container
    *ngIf="item.origin === NotificationOrigin.HISTORYLOGS || item.origin === NotificationOrigin.TRIAGEHISTORYLOG"
>
    <div
        class="m-timeline-1__item-circle p-1"
        *ngIf="
            item.eventType !== 'CASE_CANCELED' ||
            item.eventType !== 'TRIAGE_REJECTED' ||
            item.eventType !== 'TRIAGE_CANCELED'
        "
    >
        <div ngbTooltip="Status primera" class="m--bg-warning"></div>
    </div>
    <!-- triage/authorization canceled or rejected icon -->
    <div
        class="m-timeline-1__item-circle p-1 text-danger"
        *ngIf="
            item.eventType === 'CASE_CANCELED' ||
            item.eventType === 'TRIAGE_REJECTED' ||
            item.eventType === 'TRIAGE_CANCELED'
        "
    >
        <i class="la la-times" ngbTooltip="Status primera"></i>
    </div>
    <span class="m-timeline-1__item-time m--font-brand" *ngIf="item.eventDate">
        {{ item.eventDate | date: 'medium' }}
    </span>
    <div class="m-timeline-1__item-content fromAs">
        <b> Status: </b> {{ item.eventType | caseLogTypes }}
        <span *ngIf="item?.description?.caseInternalId">
            <b> {{ item.description.caseInternalId }} </b>
        </span>
        <!-- Coverage Price updated after confirming case (SEPARATED ONLY) -->
        <span *ngIf="item?.eventType === 'COVERAGE_PRICE_UPDATED'">
            Cena storitve: {{ item?.description?.totalPrice }}€, Kritje zavarovalnice:
            {{ item?.description?.coveragePrice }}€
        </span>
    </div>
</ng-container>
<!-- comment from assistance -->
<ng-container
    *ngIf="item.origin === NotificationOrigin.CASECOMMENTS || item.origin === NotificationOrigin.TRIAGECOMMENTS"
>
    <div class="m-timeline-1__item-circle p-1" i18n-ngbTooltip="@@history.popup.tooltip.comment.from.assistance">
        <i class="la la-comment" ngbTooltip="Komentar iz asistenta"></i>
    </div>
    <span class="m-timeline-1__item-time m--font-brand" *ngIf="item.commentAdded">
        {{ item.commentAdded | date: 'medium' }}
    </span>
    <div class="m-timeline-1__item-content fromAs">
        <span i18n="@@history.popup.comment"> <b> Komentar: </b> </span>{{ item.comment }}
    </div>
</ng-container>
<!-- comment from eA-->
<ng-container
    *ngIf="
        item.origin === NotificationOrigin.CASECONTRACTORCOMMENTS ||
        item.origin === NotificationOrigin.TRIAGECONTRACTORCOMMENTS
    "
>
    <div class="m-timeline-1__item-circle p-1" i18n-ngbTooltip="@@history.popup.tooltip.comment.from.eambulanta">
        <i class="la la-comment" ngbTooltip="Komentar iz eAmbulante"></i>
    </div>
    <span class="m-timeline-1__item-time m--font-brand" *ngIf="item.commentAdded">
        {{ item.commentAdded | date: 'medium' }}
    </span>
    <div class="m-timeline-1__item-content fromEa">
        <span i18n="@@history.popup.comment"> <b> Komentar: </b> {{ item.comment }} </span>
    </div>
</ng-container>
<!-- document on customer (assistance)-->
<ng-container *ngIf="item.origin === NotificationOrigin.CASECUSTOMERDOCUMENTS">
    <div class="m-timeline-1__item-circle p-1" i18n-ngbTooltip="@@history.popup.tooltip.document.customer.comm">
        <i class="la la-user-plus" ngbTooltip="Komunikacija s stranko"></i>
    </div>
    <span class="m-timeline-1__item-time m--font-brand" *ngIf="item.documentAdded">
        {{ item.documentAdded | date: 'medium' }}
    </span>
    <div class="m-timeline-1__item-content" style="display:inline-block">
        <span i18n="@@history.popup.document"> <b> Dokument: </b> </span>
        <i class="la la-file-photo-o pr-1 pl-1"></i>
        <span class="mfa-document-name pr-1">{{ item.description }}</span>
        <i
            class="la la-eye icn-hover"
            ngbTooltip="Ogled/Prenos"
            container="body"
            style="cursor:pointer"
            (click)="downloadDocument(item)"
        ></i>
    </div>
</ng-container>
<!-- document from assistance communication-->
<ng-container
    *ngIf="item.origin === NotificationOrigin.CASEDOCUMENTS || item.origin === NotificationOrigin.TRIAGEDOCUMENTS"
>
    <div
        class="m-timeline-1__item-circle p-1"
        i18n-ngbTooltip="@@history.popup.tooltip.document.from.assistance"
        *ngIf="item.documentAdded"
    >
        <i class="la la-file-photo-o" ngbTooltip="Dokument iz asistenta"></i>
    </div>
    <span class="m-timeline-1__item-time m--font-brand" *ngIf="item.documentAdded">
        {{ item.documentAdded | date: 'medium' }}
    </span>
    <div class="m-timeline-1__item-content fromAs">
        <span i18n="@@history.popup.document"> <b> Dokument: </b> </span>
        <i class="la la-file-photo-o pr-1 pl-1"></i>
        <span class="mfa-document-name pr-1">{{ item.description }}</span>
        <i
            class="la la-eye icn-hover"
            ngbTooltip="Ogled/Prenos"
            container="body"
            style="cursor:pointer"
            (click)="downloadDocument(item)"
        ></i>
    </div>
</ng-container>
<!-- document from eA communication-->
<ng-container
    *ngIf="
        item.origin === NotificationOrigin.CASECONTRACTORDOCUMENTS ||
        item.origin === NotificationOrigin.TRIAGECONTRACTORDOCUMENTS
    "
>
    <div class="m-timeline-1__item-circle p-1" i18n-ngbTooltip="@@history.popup.tooltip.document.from.eambulanta">
        <i class="la la-file-photo-o" ngbTooltip="Dokument iz eAmbulante"></i>
    </div>
    <span class="m-timeline-1__item-time m--font-brand" *ngIf="item.documentAdded">
        {{ item.documentAdded | date: 'medium' }}
    </span>
    <div class="m-timeline-1__item-content fromEa">
        <span i18n="@@history.popup.document"> <b> Dokument: </b> </span>
        <i class="la la-file-photo-o pr-1 pl-1"></i>
        <span class="mfa-document-name pr-1">{{ item.description }}</span>
        <i
            class="la la-eye icn-hover"
            ngbTooltip="Ogled/Prenos"
            container="body"
            style="cursor:pointer"
            (click)="downloadDocument(item)"
        ></i>
    </div>
</ng-container>
<!-- Communication (email) on authorization-->
<ng-container *ngIf="item.origin === NotificationOrigin.AUTHORIZATIONCOMMUNICATION">
    <div class="m-timeline-1__item-circle p-1">
        <i class="la la-envelope" ngbTooltip="Komunikacija s stranko"></i>
    </div>
    <span class="m-timeline-1__item-time m--font-brand" *ngIf="item.sentTime">
        {{ item.sentTime | date: 'medium' }}
    </span>
    <div class="m-timeline-1__item-content fromAs pt-1">
        <div class="row ">
            <div *ngIf="item.isTwoFA" class="pl-3 pt-2 font-weight-bold">
                DVOFAKTORSKA AVTENTIKACIJA <i class="la la-lock"></i>
            </div>

            <div class="pt-2 ml-auto">
                <span>
                    <a (click)="collapsed = !collapsed" class="pointer">
                        <i
                            class="la pr-1 pl-1"
                            [ngClass]="{
                                'la-expand': !collapsed && item.channel === 'EMAIL',
                                'la-compress': collapsed && item.channel === 'EMAIL'
                            }"
                        ></i>
                    </a>
                </span>
            </div>
        </div>
        <div
            *ngIf="item.channel === 'EMAIL'"
            [innerHTML]="item.msgBody"
            [ngClass]="{ 'limit-height': !collapsed }"
        ></div>
        <div *ngIf="item.channel === 'SMS'"><b>SMS:</b> {{ item.msgBody }}</div>
    </div>
</ng-container>
<!-- Communication (email) on triage-->
<ng-container *ngIf="item.origin === NotificationOrigin.TRIAGECOMMUNICATION">
    <div class="m-timeline-1__item-circle p-1">
        <i class="la la-envelope" ngbTooltip="Komunikacija s stranko"></i>
    </div>
    <span class="m-timeline-1__item-time m--font-brand" *ngIf="item.sentTime">
        {{ item.sentTime | date: 'medium' }}
    </span>
    <div class="m-timeline-1__item-content fromAs pt-1">
        <div class="row justify-content-end">
            <div>
                <span>
                    <a (click)="collapsed = !collapsed" class="pointer">
                        <i class="la pr-1 pl-1" [ngClass]="{ 'la-expand': !collapsed, 'la-compress': collapsed }"></i>
                    </a>
                </span>
            </div>
        </div>
        <div
            [innerHTML]="item.msgBody"
            class="three-line-fadeout-overflow"
            [ngClass]="{ 'limit-height': !collapsed }"
        ></div>
    </div>
</ng-container>
<!--styling (colors) for arrows-->
<div
    class="m-timeline-1__item-arrow fromEa__arrow"
    *ngIf="
        item?.origin === NotificationOrigin.CASECONTRACTORDOCUMENTS ||
        item?.origin === NotificationOrigin.CASECONTRACTORCOMMENTS ||
        item?.origin === NotificationOrigin.TRIAGECONTRACTORDOCUMENTS ||
        item?.origin === NotificationOrigin.TRIAGECONTRACTORCOMMENTS
    "
></div>
<div
    class="m-timeline-1__item-arrow fromAs__arrow"
    *ngIf="
        item?.origin === NotificationOrigin.CASEDOCUMENTS ||
        item?.origin === NotificationOrigin.CASECOMMENTS ||
        item?.origin === NotificationOrigin.HISTORYLOGS ||
        item?.origin === NotificationOrigin.TRIAGEDOCUMENTS ||
        item?.origin === NotificationOrigin.TRIAGECOMMENTS ||
        item?.origin === NotificationOrigin.TRIAGEHISTORYLOG ||
        item?.origin === NotificationOrigin.TRIAGECOMMUNICATION ||
        item?.origin === NotificationOrigin.AUTHORIZATIONCOMMUNICATION
    "
></div>
<div class="m-timeline-1__item-arrow" *ngIf="item?.origin === NotificationOrigin.CASECUSTOMERDOCUMENTS"></div>
