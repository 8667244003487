import {TelephonyService} from './../../pages/default/telephony/telephony.service';
import {ActiveModulesService} from './../../../shared/services/active-modules.service';
import {MenuItem} from './../../../shared/model/utils/menuItem.model';
import {QuickSidebarService} from './../quick-sidebar/quick-sidebar.service';
import {environment} from './../../../../environments/environment';
import {Customer} from './../../../shared/model/customer.model';
import {MenuService} from './../../../shared/services/menu.service';
import {Component, OnInit, ViewEncapsulation, AfterViewInit} from '@angular/core';
import {StorageService} from '../../../shared/services/storage.service';
import {User} from '../../../shared/model/user.model';
import * as _ from 'lodash';
import {AppModulesType} from '../../../shared/enums/appModulesType.enum';
import {NgxPermissionsService} from 'ngx-permissions';

declare let mLayout: any;

@Component({
    selector: 'app-header-nav',
    templateUrl: './header-nav.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./header-nav.component.scss'],
})
export class HeaderNavComponent implements OnInit, AfterViewInit {
    public loggedingUser: User;
    public shortName = 'UP';
    public translateRole = {
        ROLE_OWNER: 'vodja',
        ROLE_SUBCONTRACTOR: 'podizvajalec',
        ROLE_SEKRETAR: 'Sekretar',
    };
    public selectedCustomer: Customer;
    public roles: string[];
    public isProduction: boolean = environment.production;
    public mainMenu: MenuItem[] = [];
    public isTelephonyModule: boolean;
    public version: string = environment.VERSION;
    public allPermissions = {};

    constructor(
        public storage: StorageService,
        public menu: MenuService,
        public quickBarService: QuickSidebarService,
        public am: ActiveModulesService,
        public telephony: TelephonyService,
        private permissionsService: NgxPermissionsService,
    ) {
    }

    ngOnInit() {
        this.loggedingUser = this.storage.getUserData();
        this.shortName = this.getInitials(this.loggedingUser.name);
        this.isTelephonyModule = this.am.isAM(AppModulesType.TELEPHONY);
        this.roles = this.storage.getUserRoles();
        this.menu.validateMenu(this.menu.default).subscribe((mainMenu) => {
            this.mainMenu = mainMenu;
        });
        this.allPermissions = this.permissionsService.getPermissions();
        // this.selectedCustomer = _.get(this.storage.getAuthorizationOrder(), 'customer');
    }

    ngAfterViewInit() {
        mLayout.initHeader();
    }

    public openPhone(): void {
        this.telephony.show();
    }

    public logout() {
        this.quickBarService.toggle('logout');
    }

    private getInitials(value) {
        if (value.indexOf(' ') === -1) {
            return value.substr(0, 2);
        }

        let ret = '';
        ret = ret + value.substr(0, 1);
        ret = ret + value.substr(value.lastIndexOf(' ') + 1, 1);

        if (ret !== '') {
            return ret;
        }

        return value;
    }

    public checkPermission(permission: string) {
        if (this.allPermissions[permission]) {
            return true;
        }
        return false;
    }
}
