import { OverriddenContractorAddress } from '../../../shared/model/contractor/overridden-contractor-address.model';
import { HelperService } from './../../../shared/services/helper.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contractor } from '../../../shared/model';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MfToastService, StorageService } from '../../../shared/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContractorsService } from '../../../shared/services/contractors.service';
import { Intercom } from 'ng-intercom';
import _ from 'lodash';
import { Subject, Observable, of, forkJoin, EMPTY } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-contractor-form',
    templateUrl: './contractor-form.component.html',
    styles: [],
})
export class ContractorFormComponent implements OnInit {
    public properties = {
        emso: true,
        kzz: false,
    };

    @Input()
    contractor: Contractor;

    @Output()
    onContractorManaged: EventEmitter<Contractor> = new EventEmitter();

    get formData() {
        return <FormArray>this.contractorDetailsForm.get('externalId');
    }

    public isSmallInput: boolean = false;
    public isReadOnly: boolean = false;
    public newContractor: boolean = true;
    public isSubmitted: boolean = false;
    public contractorDetailsForm: FormGroup;
    public agreedTermsChecked: any = false;
    private destroy$: Subject<boolean> = new Subject<boolean>();
    public selectionFromCRM: boolean = false;
    public contractorsFromCRM = [];
    private contactAddress: OverriddenContractorAddress;
    public bsId = this.storage.getBusinessSubject().id;

    constructor(
        public fb: FormBuilder,
        //public cusHelper: CustomerFormHelperService,
        public toast: MfToastService,
        public activeModal: NgbActiveModal,
        public contractorsService: ContractorsService,
        private storage: StorageService,
        private intercom: Intercom,
        private helper: HelperService,
    ) {}

    ngOnInit() {
        // this.contractorsService
        //     .getContractorAddress(this.storage.getBusinessSubject().id, this.contractor.id)
        //     .pipe(tap((data: OverriddenContractorAddress) => (this.contactAddress = data)))
        //     .subscribe(() => {
        //         this.createForm();
        //     });

        this.createForm();
        // this.contractorsService
        //     .getContractorTest(this.storage.getBusinessSubject().id, this.contractor.id, this.contractor.bpiType)
        //     .subscribe((x) => {
        //         debugger;
        //     });

        this.contractorsService
            .getAllContractorsFromCRM()
            //filter tiste, ki nimajo phone + email?
            .pipe(takeUntil(this.destroy$))
            .subscribe((contr) => {
                this.contractorsFromCRM = contr;
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public createForm(): void {
        const formFields = {
            contractor: this.fb.control({
                value: undefined,
                disabled: false,
            }),
            name: this.fb.control({ value: undefined, disabled: false }, Validators.required),
            address: this.fb.control({
                value: undefined,
                disabled: false,
            }),
            post: this.fb.control({
                value: undefined,
                disabled: false,
            }),
            postNumber: this.fb.control({
                value: undefined,
                disabled: false,
            }),
            email: this.fb.control(
                {
                    value: undefined,
                    disabled: false,
                },
                [Validators.required, Validators.email],
            ),
            contactEmail: this.fb.control(
                {
                    value: undefined,
                    disabled: false,
                },
                [Validators.email],
            ),
            contactPhone: this.fb.control({ value: undefined, disabled: false }, [Validators.minLength(9)]),
            phone: this.fb.control({ value: undefined, disabled: false }, [
                Validators.minLength(9),
                Validators.required,
            ]),
            agreedTerms: this.fb.control({ value: undefined, disabled: false }, [Validators.required]),
            // contactPostName: this.fb.control(
            //     {
            //         value: undefined,
            //         disabled: false,
            //     },
            //     [],
            // ),
            // contactPostNumber: this.fb.control(
            //     {
            //         value: undefined,
            //         disabled: false,
            //     },
            //     [],
            // ),
            // contactStreetName: this.fb.control(
            //     {
            //         value: undefined,
            //         disabled: false,
            //     },
            //     [],
            // ),
            // contactStreetNumber: this.fb.control(
            //     {
            //         value: undefined,
            //         disabled: false,
            //     },
            //     [],
            // ),
            // contactTitle: this.fb.control(
            //     {
            //         value: undefined,
            //         disabled: false,
            //     },
            //     [],
            // ),
        };
        if (this.bsId == 1 || this.bsId == 11) {
            formFields['generaliExternalId'] = this.fb.control({
                value: undefined,
                disabled: false,
            });
            formFields['generaliOsaId'] = this.fb.control({
                value: undefined,
                disabled: false,
            });
        } else if (this.bsId == 2) {
            formFields['tzzExternalId'] = this.fb.control({
                value: undefined,
                disabled: false,
            });
            formFields['tzzOsebaId'] = this.fb.control({
                value: undefined,
                disabled: false,
            });
        } else {
            formFields['inisExternalId'] = this.fb.control({
                value: undefined,
                disabled: false,
            });
        }
        this.contractorDetailsForm = this.fb.group(formFields);
        if (this.contractor) {
            this.contractorDetailsForm.patchValue(this.contractor);
            this.contractorDetailsForm.controls.agreedTerms.setValidators([]);
            this.contractorDetailsForm.controls.agreedTerms.updateValueAndValidity();
        }
        if (this.contactAddress) {
            // this.postName = input.postName;
            // this.postNumber = input.postNumber;
            // this.streetName = input.streetName;
            // this.streetNumber = input.streetNumber;
            // this.title = input.title;
            // debugger;
            // this.contractorDetailsForm.controls.contactPostName.setValue(this.contactAddress.postName);
            // this.contractorDetailsForm.controls.contactPostNumber.setValue(this.contactAddress.postNumber);
            // this.contractorDetailsForm.controls.contactStreetName.setValue(this.contactAddress.streetName);
            // this.contractorDetailsForm.controls.contactStreetNumber.setValue(this.contactAddress.streetNumber);
            // if (this.contactAddress.title) {
            //     this.contractorDetailsForm.controls.contactTitle.setValue(this.contactAddress.title);
            // } else {
            //     this.contractorDetailsForm.controls.contactTitle.setValue(this.contractor.name);
            // }
            // debugger;
        }
    }

    public termsChanged(data) {
        this.agreedTermsChecked = _.get(data, 'target.checked', false);
        this.contractorDetailsForm.controls['agreedTerms'].setValue(this.agreedTermsChecked ? true : undefined);
    }

    public fillContractorData(data) {
        if (!data) {
            return;
        }
        if (data.id) {
            // this.disableContractorDataForm();
            //obstoječ izvajalec iz seznama
            this.contractorDetailsForm.controls['contractor'].setValue(data);
            this.contractorDetailsForm.controls['name'].setValue(data.name ? data.name.trim() : '');
            this.contractorDetailsForm.controls['address'].setValue(data.street ? data.street.trim() : '');
            this.contractorDetailsForm.controls['post'].setValue(data.city ? data.city.trim() : '');
            this.contractorDetailsForm.controls['postNumber'].setValue(data.zip ? data.zip.trim() : '');
            this.contractorDetailsForm.controls['email'].setValue(data.email ? data.email.trim() : '');
            // if (data.phone) {
            //     data.phone = data.phone.trim().replace('+386', '0');
            //     data.phone = data.phone.replace(/\s/g, '');
            //     this.contractorDetailsForm.controls['phone'].setValue(data.phone);
            // } else {
            //     this.contractorDetailsForm.controls['phone'].setValue('');
            // }
            this.contractorDetailsForm.controls['phone'].setValue(
                data.phone ? data.phone.trim().replace('+386', '0') : '',
            );
        } else {
            //ročni vnos izvajalca
            // this.contractorDetailsForm.reset();
            // this.contractorDetailsForm.enable();
            this.contractorDetailsForm.controls['name'].setValue(data.name ? data.name.trim() : '');
        }
        this.agreedTermsChecked = false;
        this.contractorDetailsForm.controls['agreedTerms'].setValue(undefined);
    }

    // public disableContractorDataForm() {
    //     if (this.contractorDetailsForm) {
    //         this.contractorDetailsForm.controls['address'].disable();
    //         this.contractorDetailsForm.controls['post'].disable();
    //         this.contractorDetailsForm.controls['postNumber'].disable();
    //         this.contractorDetailsForm.controls['email'].disable();
    //         this.contractorDetailsForm.controls['phone'].disable();
    //     }
    // }

    public dismiss(contractor: any): void {
        this.activeModal.dismiss(contractor);
    }

    public close(contractor: any): void {
        this.activeModal.close(contractor);
    }

    public createContractor() {
        this.isSubmitted = true;

        if (!this.contractorDetailsForm.valid) {
            return;
        }
        const formValue: any = this.contractorDetailsForm.value;
        const contractor: Contractor = new Contractor().deserialize(this.contractorDetailsForm.getRawValue());
        if (this.contractor) {
            // UPDATE CONTRACTOR
            if (_.get(this.contractor, 'bpiType') === 'SEPARATED') {
                //UPDATE (SEPARATED)
                forkJoin([
                    this.contractorsService.updateSeparatedContractor(
                        this.storage.getBusinessSubject().id,
                        this.contractor.externalId,
                        contractor,
                    ),
                    this.contractorsService.updateContractorContact(
                        this.storage.getBusinessSubject().id,
                        this.contractor.id ? String(this.contractor.id) : this.contractor.externalId,
                        {
                            email: _.get(contractor, 'contactEmail.length') > 0 ? contractor.contactEmail : null,
                            phone:
                                _.get(contractor, 'contactPhone.length') > 0
                                    ? contractor.contactPhone.replace(/\s/g, '')
                                    : null,
                        },
                    ),
                ])
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(
                        () => {
                            this.toast.success('Izvajalec uspešno posodobljen.');
                            this.activeModal.close(contractor);
                        },
                        (error) => {
                            let errorMessage = 'Napaka pri spreminjanju izvajalca!';
                            switch (error.error.errors[0].code) {
                                case 'validatePhone':
                                    errorMessage =
                                        'Napaka pri spreminjanju izvajalca! Telefonska številka ni ustrezna.';
                                    break;
                                default:
                                    errorMessage = 'Napaka pri spreminjanju izvajalca!';
                                    break;
                            }

                            this.toast.error(errorMessage);
                        },
                    );
            } else {
                //UPDATE (X21 + MEDIFIT)

                // this.postName = input.postName;
                // this.postNumber = input.postNumber;
                // this.streetName = input.streetName;
                // this.streetNumber = input.streetNumber;
                // this.title = input.title;
                // forkJoin([
                //     this.contractorsService.updateContractorContact(
                //         this.storage.getBusinessSubject().id,
                //         this.contractor.id ? String(this.contractor.id) : this.contractor.externalId,
                //         {
                //             email: _.get(contractor, 'contactEmail.length') > 0 ? contractor.contactEmail : null,
                //             phone: _.get(contractor, 'contactPhone.length') > 0 ? contractor.contactPhone : null,
                //         },
                //     ),
                //     this.contractorsService.putContractorAddress(
                //         this.storage.getBusinessSubject().id,
                //         this.contractor.id,
                //         new OverriddenContractorAddress().deserialize({
                //             postName: formValue.contactPostName,
                //             postNumber: formValue.contactPostNumber,
                //             streetName: formValue.contactStreetName,
                //             streetNumber: formValue.contactStreetNumber,
                //             title: formValue.contactTitle,
                //         }),
                //     ),
                // ]).subscribe(
                const contractorId =
                    this.contractor.bpiType == 'MEDIFIT'
                        ? String(this.contractor.id)
                        : this.contractor.externalId || String(this.contractor.id);
                const contractorData = {
                    postName: formValue.post,
                    postNumber: formValue.postNumber,
                    title: formValue.name,
                    email: formValue.email,
                    phone: formValue.phone ? formValue.phone.replace(/\s/g, '') : undefined,
                    address: formValue.address,
                };
                if (this.bsId == 1 || this.bsId == 11) {
                    contractorData['generaliExternalId'] = formValue.generaliExternalId;
                    contractorData['generaliOsaId'] = formValue.generaliOsaId;
                } else if (this.bsId == 2) {
                    contractorData['tzzOsebaId'] = formValue.tzzOsebaId || undefined;
                    contractorData['tzzExternalId'] = formValue.tzzExternalId || undefined;
                } else {
                    contractorData['inisExternalId'] = formValue.inisExternalId || undefined;
                }

                this.contractorsService
                    .putContractorAddress(
                        this.storage.getBusinessSubject().id,
                        contractorId,
                        new OverriddenContractorAddress().deserialize(contractorData),
                    )
                    .subscribe(
                        () => {
                            this.activeModal.close({
                                ...contractor,
                                ...this.contractor,
                            });
                            this.toast.success('Uspešno urejanje izvajalca.');
                        },
                        () => {
                            this.toast.error('Napaka pri urejanju izvajalca');
                        },
                    );
            }
        } else {
            // NOV IZVAJALEC
            if (!_.get(this.contractorDetailsForm, "controls['contractor'].value.id")) {
                //NOV IZVAJALEC - ROČNI VNOS
                forkJoin([
                    this.contractorsService.createSeparatedContractor(this.storage.getBusinessSubject().id, contractor),
                ]).subscribe(
                    (res) => {
                        this.activeModal.close(contractor);
                        this.toast.success('Uspešno dodajanje izvajalca');
                    },
                    () => {
                        this.toast.error('Napaka pri dodajanju izvajalca');
                    },
                );
            } else {
                //NOV IZVAJALEC IZ SEZNAMA (CRM)
                let observableArray$: Observable<any>[] = [];
                let mailSend: boolean = false;
                // MEDIFIT --> poveži
                // karkoli drugega --> pošlji mail na asistent@medifit.si
                _.get(this.contractorDetailsForm, "controls['contractor'].value.his", []).forEach((his) => {
                    if (_.get(his, 'x_medifit_institution_his_name') === 'eAmbulanta') {
                        //MEDIFIT (poveži)
                        observableArray$.push(
                            this.contractorsService.createMedifitContractor(
                                this.storage.getBusinessSubject().id,
                                _.get(his, 'contractor_id'),
                            ),
                        );
                    } else {
                        //X21 + SEPARATED (pošlji MAIL)
                        if (!mailSend) {
                            observableArray$.push(
                                this.contractorsService.sendMailToMedifit(this.storage.getBusinessSubject().id, {
                                    sendTo: 'asistent@medifit.si',
                                    msg: this.helper.generateMailForContractor(
                                        _.get(this.contractorDetailsForm, "controls['contractor'].value"),
                                        this.storage.getBusinessSubject(),
                                        this.storage.getUserData(),
                                    ),
                                }),
                            );
                            mailSend = true;
                        }
                    }
                });
                forkJoin(observableArray$).subscribe(
                    () => {
                        this.toast.success('Uspešno dodajanje.');
                        this.activeModal.close(contractor);
                    },
                    () => {
                        this.toast.error('Napaka pri dodajanju.');
                    },
                );
            }
        }
    }

    public showIntercom() {
        this.intercom.showNewMessage('Želimo dodati novega izvajalca: ');
    }
}
