import { OverriddenContractorAddress } from '../model/contractor/overridden-contractor-address.model';
import { PriceListElement } from './../model/settings/priceListElement.model';
import { ServiceContractor } from './../model/service/serviceContractor.model';
import { PreReservation } from './../model/preReservation.model';
import { HttpClientService } from './http-client.service';
import { HelperService } from './helper.service';
import { Contractor } from './../model/contractor.model';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
//import { pipe } from '@angular/core/src/render3';
import * as _ from 'lodash';
import { PriceList } from '../model/settings/priceList.model';
import { StorageService } from './storage.service';

@Injectable()
export class ContractorsService {
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
        private storage: StorageService,
    ) {}

    // public getContractors(buId: number): Observable<Contractor[]> {
    //     return this.httpClient.get(this.helper.getAssistanceUrl() + '/bu/' + buId + '/contractors/').pipe(
    //         map(contr => {
    //             // debugger;
    //             return contr.map(subc => new Contractor().deserialize(subc));
    //             // new Contractor().deserialize(el);
    //             // return [];
    //         })
    //     );
    // }

    public getAllContractorsFromCRM(): Observable<any> {
        // http://10.230.33.13:5550/booking/asv2/odo/api/hsps
        return this.httpClient.get(this.helper.getOdooUrl() + `/hsps`);
    }

    //getAllContractorsForBusinessUnitForService()
    public getContractorsForService(
        bsid: number | string,
        serviceid: number,
        contractorId?: number,
        contractorServiceId?: number,
    ): Observable<Contractor[]> {
        let url = this.helper.getAssistanceUrl(2) + `/bs/${bsid}/services/${serviceid}/contractors`;
        if (contractorId) {
            url += `/${contractorId}`;
        }

        if (contractorServiceId) {
            url += `/services/${contractorServiceId}`;
        }

        return this.httpClient.get(url).pipe(
            map((contr) => {
                // debugger;
                return contr.map((subc) => new Contractor().deserialize(subc));
                // new Contractor().deserialize(el);
                // return [];
            }),
        );
    }

    //getAllContractorsForBusinessUnit()
    public getAllContractors(bsid: number | string): Observable<Contractor[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl(3) + `/bs/${bsid}/contractors`).pipe(
            map((contr) => {
                return contr.map((subc) => new Contractor().deserialize(subc));
                // new Contractor().deserialize(el);
                // return [];
            }),
        );
    }

    //getAllContractorsForBusinessUnit()
    public getAllContractorsForBusinessUnit(bsid: number | string): Observable<Contractor[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/contractors`).pipe(
            map((contr) => {
                return contr.map((subc) => new Contractor().deserialize(subc));
                // new Contractor().deserialize(el);
                // return [];
            }),
        );
    }

    //getAllContractorsForBusinessUnit()
    public getContractorForBusinessUnit(bsid: number, contractorId: any): Observable<Contractor> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/contractors/${contractorId}`).pipe(
            map((contr: any[]) => {
                // debugger;
                if (contr.length > 0) {
                    return new Contractor().deserialize(contr[0]);
                }
                return undefined;
                // return contr.map(subc => new Contractor().deserialize(subc));
                // new Contractor().deserialize(el);
                // return [];
            }),
        );
    }

    public getContractorStats(bsid: number): Observable<Contractor> {
        return this.httpClient.get(this.helper.getAssistanceUrl(3) + `/surveys/bs/${bsid}/contractors`);
    }

    //getFirstNFlatFreeSlots()
    public getFirstNFlatFreeSlots(
        numberOfFreeSlots: number,
        bsid: number | string,
        data: any,
        bpi: string,
        from?: string,
    ): Observable<any[]> {
        return this.httpClient.post(
            this.helper.getAssistanceUrl() +
                `/bs/${bsid}/flatfreeslots/${numberOfFreeSlots}/bpi/${bpi}` +
                (from ? `?from=${from}` : ``),
            data,
        );
        // .pipe(
        //     map(el => {
        //         return _.get(el, 'contractors[0].subcontractors', []);
        //     })
        // );
    }

    //getFreeSlots()
    public getFreeSlots(data: any, bsid: number | string, bpi: string): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl() + `/bs/${bsid}/freeslots/bpi/${bpi}`, data);
    }

    //getFreeSlots()
    public getFlatFreeSlots(data: any): Observable<any> {
        return this.httpClient.post(this.helper.getErpUrl() + '/flatfreeslots/', data);
    }

    public getAddress(address: string): Observable<Contractor[]> {
        // https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=AIzaSyDAq4rmQzieDtilQYesXbW-UOIWx0wOxyI
        return this.httpClient.get(
            'https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=AIzaSyDAq4rmQzieDtilQYesXbW-UOIWx0wOxyI',
        );
    }

    // star klic
    // public getContractorsServicesSetup(contractorid: string, bpitype: string): Observable<ServiceContractor[]> {
    //     return this.httpClient.get(this.helper.getErpUrl() + `/services/manage/${contractorid}/type/${bpitype}`);
    // }
    public getContractorsServicesSetup(
        contractorid: string,
        bpitype: string,
        bsid: any,
    ): Observable<ServiceContractor[]> {
        return this.httpClient.get(
            this.helper.getAssistanceUrl(2) + `/services/bs/${bsid}/manage/${contractorid}/type/${bpitype}`,
        );
    }

    public contractorEnabled(bsid: number, bpiType: string, contractorId: string): Observable<any> {
        return this.httpClient.put(
            this.helper.getAssistanceUrl(2) + `/bs/${bsid}/bpi/${bpiType}/contractors/${contractorId}/enable`,
        );
    }

    public contractorDirectBookingChange(bsid: number, contractorid: string): Observable<any> {
        return this.httpClient.put(
            this.helper.getAssistanceUrl() + `/bs/${bsid}/contractors/${contractorid}/directbooking`,
        );
    }

    public contractorDirectTriageChange(bsid: number, contractorid: string): Observable<any> {
        return this.httpClient.put(
            this.helper.getAssistanceUrl(2) + `/bs/${bsid}/contractors/${contractorid}/directtriage`,
        );
    }

    public contractorPriorityChange(bsid: number, contractorid: string): Observable<any> {
        return this.httpClient.put(
            this.helper.getAssistanceUrl(2) + `/bs/${bsid}/contractors/${contractorid}/prioritybooking`,
        );
    }

    // pricelists
    //getContractorPriceLists()
    public getContractorPriceLists(contractorid: string, bsid: number): Observable<PriceList[]> {
        return this.httpClient.get(
            this.helper.getAssistanceUrl(2) + `/bs/${bsid}/contractors/${contractorid}/price-lists`,
        );
    }

    public getExternalContractorPriceLists(
        contractorid: string,
        bsid: number,
        bpiType: string,
    ): Observable<PriceList[]> {
        return this.httpClient.get(
            this.helper.getAssistanceUrl(2) + `/bs/${bsid}/contractors/${contractorid}/${bpiType}/price-lists`,
        );
    }

    // get pricelists for external and internal contractors
    public getPriceLists(contractorId: string, bsId: number, bpiType: string): Observable<PriceList[]> {
        // if (bpiType === 'SEPARATED') {
        //     return of([]);
        // }
        return this.httpClient.get(
            this.helper.getAssistanceUrl(2) +
                (bpiType === 'MEDIFIT'
                    ? `/bs/${bsId}/contractors/${contractorId}/price-lists`
                    : `/bs/${bsId}/contractors/${contractorId}/${bpiType}/price-lists`),
        );
    }

    //getContractorPriceListForId()
    public getContractorPriceListForId(
        contractorid: number,
        bsid: number,
        plistid: number,
    ): Observable<PriceListElement[]> {
        return this.httpClient.get(
            this.helper.getErpUrl(2) + `/contractors/${contractorid}/bs/${bsid}/price-lists/${plistid}`,
        );
    }

    public getPriceListWithId(bsid: number, plistid: number): Observable<PriceListElement[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/price-lists/${plistid}`);
    }

    public confirmContractorPriceList(
        contractorid: number,
        bsid: number,
        listid: number,
        data: PriceList,
    ): Observable<any> {
        return this.httpClient.put(
            this.helper.getAssistanceUrl(2) + `/bs/${bsid}/contractors/${contractorid}/price-lists/${listid}`,
            data,
        );
    }

    public getActivePricelistsForContractor(bsid: number, contractorId: number): Observable<any> {
        return this.httpClient.get(
            this.helper.getAssistanceUrl() + `/bs/${bsid}/contractors/${contractorId}/price-lists/active`,
        );
    }

    // http://localhost:8092/booking/v2/assistance/api/bs/1/contractors/54/price-lists/active

    public getActivePricelistsForExternalContractor(bsid: number, contractorId: string, bpi: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getAssistanceUrl() + `/bs/${bsid}/contractors/${contractorId}/${bpi}/price-lists/active`,
        );
    }

    // valid for all types of contractors
    public getActivePricelists(bsid: number, contractorId: string, bpi: string): Observable<any> {
        // if (bpi === 'SEPARATED') {
        //     return of(null);
        // }
        return this.httpClient.get(
            this.helper.getAssistanceUrl(2) +
                (bpi === 'MEDIFIT'
                    ? `/bs/${bsid}/contractors/${contractorId}/price-lists/active`
                    : `/bs/${bsid}/contractors/${contractorId}/${bpi}/price-lists/active`),
        );
    }

    public createExternalContractorPriceList(
        data: any,
        contractorid: string,
        bsid: number,
        bpiType: string,
    ): Observable<any> {
        return this.httpClient.put(
            this.helper.getAssistanceUrl(2) + `/bs/${bsid}/contractors/${contractorid}/${bpiType}/price-lists`,
            data,
        );
    }

    // public getDocumentsForVisit(visitId: number): Observable<Document[]> {
    //     return this.httpClient.get(this.helper.getVisitUrl() + '/visits/' + visitId + '/documents').map(val => {
    //         return val.json().documents;
    //     });
    // }

    /*
    Separated contractors
     */
    public createSeparatedContractor(bsid: number, data: Contractor): Observable<any> {
        return this.httpClient.put(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/separated/contractors`, data);
    }

    public createMedifitContractor(bsid: number, contractorId): Observable<any> {
        // http://localhost:5550/booking/asv2/assistance/api/bs/1/contractors/435234av123a4
        return this.httpClient.post(this.helper.getAssistanceUrl(3) + `/bs/${bsid}/contractors/${contractorId}`);
    }

    public sendMailToMedifit(bsid: number, data: Object): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(3) + `/bs/${bsid}/sendMail`, data);
    }

    public updateSeparatedContractor(bsid: number, contractorId: string, data: Contractor): Observable<any> {
        data.externalId = contractorId;
        return this.httpClient.put(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/separated/contractors`, data);
    }

    public deleteSeparatedContractor(bsid: number, contractorId: string): Observable<any> {
        return this.httpClient.delete(
            this.helper.getAssistanceUrl(2) + `/bs/${bsid}/separated/contractors/${contractorId}`,
        );
    }

    public updateContractorContact(bsid: number, contractorId: string, data: any): Observable<any> {
        // data:  {email, phone}
        return this.httpClient.put(
            this.helper.getAssistanceUrl(3) + `/cont/bs/${bsid}/contractors/${contractorId}/contactInfo`,
            data,
        );
    }

    // //star klic za urejanje subspecializacij
    // public editSubspecializations2(cid: any, bpi: any, data: any): Observable<any> {
    //     return this.httpClient.put(
    //         this.helper.getAssistanceUrl(2) + `/contractors/${cid}/${bpi}/subspecializations`,
    //         data,
    //     );
    // }
    public editSubspecializations(cid: any, bpi: any, bsId: any, data: any): Observable<any> {
        return this.httpClient.put(
            this.helper.getAssistanceUrl(2) + `/bs/${bsId}/contractors/${cid}/${bpi}/subspecializations`,
            data,
        );
    }

    public getContractorAddress(bsid: number, cid: number): Observable<OverriddenContractorAddress> {
        return this.httpClient.get(this.helper.getAssistanceUrl(3) + `/cont/bsid/${bsid}/contractors/${cid}/address`);
    }

    public putContractorAddress(
        bsid: number,
        cid: string,
        data: OverriddenContractorAddress,
    ): Observable<OverriddenContractorAddress> {
        return this.httpClient.put(
            this.helper.getAssistanceUrl(3) + `/cont/bsid/${bsid}/contractors/${cid}/address`,
            data,
        );
    }

    public getContractorTest(bsid: number, cid: number, vendor: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getRootUrl(3) + `/contractorinfo/api/bsid/${bsid}/contractors/${cid}/bpi/${vendor}`,
        );
    }

    public addSeparatedContractorService(bsid: number, contractorId: any, data: Object): Observable<any> {
        return this.httpClient.post(
            this.helper.getAssistanceUrl(3) + `/bs/${bsid}/separated/contractors/${contractorId}/services`,
            data,
        );
    }

    public deleteSeparatedContractorService(bsid: number, contractorId: any, serviceId: any): Observable<any> {
        return this.httpClient.delete(
            this.helper.getAssistanceUrl(3) + `/bs/${bsid}/separated/contractors/${contractorId}/services/${serviceId}`,
        );
    }

    public getNetworkContractors(bsid) {
        return this.httpClient.get(this.helper.getRootUrl(3) + `/assistance/v3/api/bs/${bsid}/contractors`);
    }

    public connectContractorBs(bsid, contractorId) {
        ///booking/asv2/assistance/v3/api/bs/{bsid}/contractors/{contractorId}
        return this.httpClient.post(
            this.helper.getRootUrl(3) + `/assistance/v3/api/bs/${bsid}/contractors/${contractorId}`,
        );
    }

    public notifyContractorMissingFreeSlots(cInfoId, subcontractorId, serviceId, externalServiceId?) {
        const bsId = this.storage.getBusinessSubject().id;

        let urlQuery = `?`;

        if (subcontractorId) {
            urlQuery += `&subcontractorId=${subcontractorId}`;
        }

        if (serviceId) {
            urlQuery += `&serviceId=${serviceId}`;
        }

        if (externalServiceId) {
            urlQuery += `&externalServiceId=${externalServiceId}`;
        }

        return this.httpClient.post(
            this.helper.getAssistanceUrl(2) + `/bs/${bsId}/cinfo/${cInfoId}/missingFreeSlots/mail${urlQuery}`,
            {},
        );
    }

    public getContractorMissingFreeSlotsLog(cInfoId) {
        const bsId = this.storage.getBusinessSubject().id;
        ///bs/{bsId}/cinfo/{cId}/missingFreeSlots/mail/log

        return this.httpClient.get(
            this.helper.getAssistanceUrl(2) + `/bs/${bsId}/cinfo/${cInfoId}/missingFreeSlots/mail/log`,
        );
    }

    public mapExternalContractor(bsId, externalContractorId) {
        return this.httpClient.get(
            this.helper.getAssistanceUrl(2) + `/bs/${bsId}/contractors/external/${externalContractorId}`,
        );
    }
}

//http://10.230.33.13:5550/booking/asv2/contractorinfo/api/bsid/1/contractors/54/bpi/MEDIFIT’
// asv2/contractorinfo/api/contractors/3/address/bpi/MEDIFIT'

// http://localhost:5550/booking/asv2/assistance/api/cont/bsid/1/contractors/54/address
