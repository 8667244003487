import { QuickSidebarService } from './../../quick-sidebar/quick-sidebar.service';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
// services
import { HelperNotificationService } from './../../../../shared/services/helper-notification.service';
import { StorageService } from './../../../../shared/services/storage.service';
import { NotificationsService } from './../../../../shared/services/notifications.service';
// models
import { Notification } from './../../../../shared/model/notification.model';
import * as moment from 'moment';
import * as _ from 'lodash';
import { OneSignalService } from '../../../../shared/services/onesignal.service';

@Component({
    selector: '[head-header-notifications]',
    templateUrl: './header-notifications.component.html',
    styles: [],
})
export class HeaderNotificationsComponent implements OnInit, OnDestroy {
    public showAlert: boolean = false;
    public notificationsList: Notification[] = [];
    public numberOfNotifications: number = 0;
    public visibleNumberOfNotifications: number = 0;
    public initNumberOfNotification: number = null;

    private notificationInterval;

    constructor(
        private notificationsService: NotificationsService,
        private storage: StorageService,
        private helperNotification: HelperNotificationService,
        private serviceSide: QuickSidebarService,
        private oneSignal: OneSignalService,
    ) {}

    ngOnInit() {
        this.checkForNewNotifications();
        this.checkForNewNotificationsInterval();

        this.oneSignal.receivedNotifications.subscribe((notification) => {
            this.checkForNewNotifications();
        });
    }

    ngOnDestroy() {
        if (this.notificationInterval) {
            clearInterval(this.notificationInterval);
        }
    }

    // public loadNotifications(): void {
    //     if (_.get(this.storage.getBusinessSubject(), 'id', false)) {
    //         this.notificationsService
    //             .getNotifications(
    //                 this.storage.getBusinessSubject().id,
    //                 moment()
    //                     .subtract(2, 'day')
    //                     .format('YYYY-MM-DD'),
    //                 moment()
    //                     .add(1, 'day')
    //                     .format('YYYY-MM-DD'),
    //             )
    //             .subscribe((res) => {
    //                 this.showAlert = false;
    //                 this.notificationsList = res
    //                     .map((oldNotification) => {
    //                         oldNotification.unread = true;
    //                         return this.helperNotification.returnNotificationReadyForView(oldNotification);
    //                     })
    //                     .filter((notification) => {
    //                         return notification !== null;
    //                     });
    //             });
    //     }
    // }

    // public messageRead(index: number): void {
    //     this.notificationsList[index].unread = this.notificationsList[index].unread ? false : true;
    // }

    // public markAllAsRead(): void {
    //     this.notificationsList = this.notificationsList.map((element) => {
    //         element.unread = false;
    //         return element;
    //     });
    // }

    public checkForNewNotificationsInterval(): void {
        this.notificationInterval = setInterval(() => {
            this.checkForNewNotifications();
        }, 90000);
    }

    public checkForNewNotifications(): void {
        this.notificationsService
            .getNotifications(
                this.storage.getBusinessSubject().id,
                moment().format('YYYY-MM-DD'),
                moment()
                    .add(1, 'day')
                    .format('YYYY-MM-DD'),
            )
            .subscribe(
                (res) => {
                    if (this.numberOfNotifications > 0 && res.length !== this.numberOfNotifications) {
                        this.showAlert = true;
                        this.visibleNumberOfNotifications =
                            res.length - (this.initNumberOfNotification ? this.initNumberOfNotification : 0);
                    }

                    this.numberOfNotifications = res.length;

                    if (!this.initNumberOfNotification) {
                        this.initNumberOfNotification = this.numberOfNotifications;
                    }
                },
                (error) => {
                    this.showAlert = false;
                    this.visibleNumberOfNotifications = 0;
                    this.initNumberOfNotification = this.numberOfNotifications;
                },
            );
    }

    public click() {
        this.visibleNumberOfNotifications = 0;
        this.initNumberOfNotification = this.numberOfNotifications;
        this.showAlert = false;
        this.serviceSide.toggle();
    }
}
