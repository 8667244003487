import { AdditionalData } from './../authorization/additional-data';
import { Serializable } from '../deserialize.service';

export class UrlCase implements Serializable<UrlCase> {
    public id: string;
    public claimFile: string;
    public additionalData: AdditionalData;
    public coverMap: string; //authorityID za Triglav
    deserialize(input: any) {
        this.id = input.id;
        this.claimFile = input.claimFile;
        this.additionalData = input.additionalData;
        this.coverMap = input.coverMap;
        return this;
    }
}
