import { GenderType } from './../enums/genderType.enum';
import { Serializable } from './deserialize.service';

export class Customer implements Serializable<Customer> {
    id: string;
    name: string;
    surname: string;
    address: string;
    city: string;
    country: string;
    phone: string;
    mobilePhone: string;
    email: string;
    post: string;
    birthDate: Date;
    sendMail: boolean = false;
    sendSms: boolean = false;
    gender: GenderType;
    medifitId: number;
    emso: string;
    kzz: string;
    taxNo: string;
    birthDateString: string;
    externalId: string;

    deserialize(input) {
        if (input === null || !input) {
            return input;
        }

        this.id = input.id;
        this.name = input.name;
        this.surname = input.surname;
        this.address = input.address;
        this.city = input.city;
        this.country = input.country;
        this.phone = input.phone;
        this.email = input.email;
        this.post = input.post;
        this.birthDate = input.birthDate;
        this.sendMail = input.sendMail;
        this.sendSms = input.sendSms;
        this.mobilePhone = input.mobilePhone;
        this.gender = input.gender;
        this.medifitId = input.medifitId;
        this.emso = input.emso;
        this.kzz = input.kzz;
        this.taxNo = input.taxNo;
        this.birthDateString = input.birthDateString;
        this.externalId = input.externalId;
        return this;
    }
}
