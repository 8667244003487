import { Injectable } from '@angular/core';
// models
import { Notification } from '../model/notification.model';
import { NotificationEnum } from '../enums/notification.enum';
import { NotificationMessage } from '../model/notificationMessage.model';
// other
import * as _ from 'lodash';
import { OpenAuthorityCase } from '../../theme/pages/_base/base-case/_actions/base-case.actions';
import { Router } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import { TriageService } from './triage.service';
import { StorageService } from './storage.service';
import { HelperService } from './helper.service';
import { Store } from '@ngxs/store';
import { OneSignalService } from './onesignal.service';
import { NotificationsService } from './notifications.service';
import { AuthorizationTriage } from '../model/authorityCase/authorizationTriage.model';

@Injectable({
    providedIn: 'root',
})
export class HelperNotificationService {
    constructor(
        private route: Router,
        private authService: AuthorizationService,
        private triageService: TriageService,
        private storage: StorageService,
        private helper: HelperService,
        private store: Store,
        private oneSignal: OneSignalService,
        private notificationsService: NotificationsService,
    ) {
        this.oneSignal.clickedNotifications.subscribe((notification) => {
            if (notification.data) {
                const parsedNotification = this.notificationPrepareData(
                    new Notification().deserialize({
                        data: notification.data,
                        type: notification.data.type,
                    }),
                );

                if (notification.data.notificationId) {
                    this.notificationsService
                        .markNotificationAsSeen(this.storage.getBusinessSubject().id, notification.data.notificationId)
                        .subscribe();
                }

                if (parsedNotification.redirect) {
                    this.redirectSwitch(parsedNotification.redirect, parsedNotification.link, parsedNotification.data);
                }
            } else if (notification.url) {
                const url = new URL(notification.url);
                if (url.pathname && url.pathname !== '/') {
                    this.route.navigate([url.pathname]);
                }
            }
        });
    }

    public returnNotificationReadyForView(oldNotification: Notification): Notification {
        return this.notificationPrepareData(oldNotification);
    }

    public notificationPrepareData(notification: Notification): Notification {
        switch (notification.type) {
            case NotificationEnum.GDPR_CONSENT_COMPLETED:
                notification.title = 'GDPR soglasje';
                notification.backgroundColor = '#E6F7F3';
                notification.borderColor = '#34BFA3';
                notification.link = `customers/view/${notification.data.customerId}`;
                notification.name = notification.data.customerName;
                notification.messages = null;
                notification.redirect = 'normal';
                notification.dataInFooter = 'customer';
                return notification;

            // avtorizacije
            case NotificationEnum.RESERVATION_CANCELED:
                notification.title = `Termin preklican (${notification.data.caseInternalId})`;
                notification.backgroundColor = '#FDECEF';
                notification.borderColor = '#F4516C';
                notification.link = `authorization/details`;
                notification.name = notification.customerName;
                notification.messages = this.reservationCanceledMessage(notification.customerName);
                notification.redirect = 'authorization';
                notification.dataInFooter = 'business';
                notification.prereservationId = notification.data === null ? 0 : notification.data.caseId;
                return notification;

            case NotificationEnum.RESERVATION_MARKED_AS_NO_SHOW:
                notification.title = 'Stranke ni bilo';
                notification.backgroundColor = '#FDECEF';
                notification.borderColor = '#F4516C';
                notification.link = `authorization/details`;
                notification.name = notification.customerName;
                notification.messages = null;
                notification.redirect = 'authorization';
                notification.dataInFooter = 'business';
                notification.prereservationId = notification.data === null ? 0 : notification.data.caseId;
                return notification;

            case NotificationEnum.RESERVATION_COMPLETED:
                notification.title = 'Avtorizacija zaključena';
                notification.backgroundColor = '#E6F7F3';
                notification.borderColor = '#34BFA3';
                notification.link = `authorization/details`;
                notification.name = notification.customerName;
                notification.messages = this.reservationCanceledMessage(notification.customerName);
                notification.redirect = 'authorization';
                notification.dataInFooter = 'business';
                notification.prereservationId = notification.data === null ? 0 : notification.data.caseId;
                return notification;

            case NotificationEnum.RESERVATION_UPDATED:
                notification.title = `Termin spremenjen (${notification.data.caseInternalId})`;

                if (_.has(notification, 'data.caseInternalId')) {
                    notification.title = `Termin spremenjen (${notification.data.caseInternalId})`;
                } else {
                    notification.title = `Termin spremenjen`;
                }
                notification.backgroundColor = '#FCE6C5';
                notification.borderColor = '#A16D16';
                notification.link = `authorization/details`;
                notification.name = notification.customerName;
                notification.messages = this.reservationUpdatedSetMessage(notification.data);
                notification.redirect = 'authorization';
                notification.dataInFooter = 'business';
                notification.prereservationId = notification.data === null ? '' : notification.data.caseId;
                // V primeru, da ne vemo kakšna je bila sprememba, notificationa ne prikažemo...
                if (notification.messages.length === 0) {
                    return null;
                }
                return notification;

            case NotificationEnum.RESERVATION_CREATED:
                notification.title = `Rezervacija termina (${notification.data.caseInternalId})`;
                notification.backgroundColor = '#ADD8e6';
                notification.borderColor = '#008BB8';
                notification.link = 'authorization/details';
                notification.name = notification.customerName;
                notification.messages = this.reservationCanceledMessage(notification.customerName);
                notification.redirect = 'authorization';
                notification.dataInFooter = 'business';
                notification.prereservationId = notification.data === null ? null : notification.data.caseId;
                return notification;
            //return null;

            case NotificationEnum.CASE_CANCELED:
                notification.title = 'Primer preklican';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `authorization/details`;
                notification.name = notification.customerName;
                notification.messages = this.reservationCanceledMessage(notification.customerName);
                notification.redirect = 'reservation';
                notification.dataInFooter = 'business';
                notification.prereservationId = notification.data === null ? 0 : notification.data.caseId;
                return notification;

            case NotificationEnum.ASISTANT_CASE_DOCUMENT_ADDED:
                notification.title = 'Nov dokument';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `case/${notification.data.caseId}`;
                notification.name = notification.data.appUserName;
                notification.messages = this.documentAddedMessage(notification.data);
                notification.redirect = 'assistant';
                notification.prereservationId = notification.data.caseId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.ASISTANT_CASE_COMMENT_ADDED:
                notification.title = 'Nov komentar';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `case/${notification.data.caseId}`;
                notification.name = notification.data.appUserName;
                notification.messages = this.commentAddedMessage(notification.data);
                notification.redirect = 'assistant';
                notification.prereservationId = notification.data.caseId;
                notification.dataInFooter = 'business';
                return notification;
            //triage
            case NotificationEnum.ASISTANT_TRIAGE_DOCUMENT_ADDED:
                notification.title = 'Nov dokument';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `consul/view/${notification.data.triageId}`;
                notification.name = notification.data.appUserName;
                notification.messages = this.documentAddedMessage(notification.data);
                notification.redirect = 'triage';
                notification.prereservationId = notification.data.triageId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.ASISTANT_TRIAGE_COMMENT_ADDED:
                notification.title = 'Nov komentar';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `consul/view/${notification.data.triageId}`;
                notification.name = notification.data.appUserName;
                notification.messages = this.commentAddedMessage(notification.data);
                notification.redirect = 'assistant';
                notification.prereservationId = notification.data.triageId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.TRIAGE_CREATED:
                notification.title = 'Nova eTriaža';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `authorization/details`;
                notification.name = notification.data.appUserName;
                notification.messages = null;
                notification.redirect = 'triage';
                notification.prereservationId = notification.data.triageId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.TRIAGE_ANSWERED:
                notification.title = 'eTriaža odgovorjena';
                notification.backgroundColor = '#E6F7F3';
                notification.borderColor = '#34BFA3';
                notification.link = `authorization/details`;
                notification.name = notification.data.appUserName;
                notification.messages = this.triageAnsweredMessage(notification.data);
                notification.redirect = 'triage';
                notification.prereservationId = notification.data.triageId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.TRIAGE_UNANSWERED:
                notification.title = 'eTriaža neodgovorjena';
                notification.backgroundColor = '#FCE6C5';
                notification.borderColor = '#A16D16';
                notification.link = `authorization/details`;
                notification.name = notification.data.appUserName;
                notification.messages = [
                    new NotificationMessage().deserialize({ message: 'Na e-posvet v roku 48 ur ni bilo odgovora.' }),
                ];
                notification.redirect = 'triage';
                notification.prereservationId = notification.data.triageId;
                return notification;

            case NotificationEnum.TRIAGE_CONCLUDED:
                notification.title = 'Zaključena eTriaža';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `authorization/details`;
                notification.name = notification.data.appUserName;
                notification.messages = null;
                notification.redirect = 'triage';
                notification.prereservationId = notification.data.triageId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.TRIAGE_CANCELED:
                notification.title = 'Preklicana eTriaža';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `authorization/details`;
                notification.name = notification.data.appUserName;
                notification.messages = null;
                notification.redirect = 'triage';
                notification.prereservationId = notification.data.triageId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.CONTRACTOR_PRICE_LIST_CONFIRMED:
                notification.title = 'Cenik potrjen';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = null;
                notification.name = notification.data.appUserName;
                notification.messages = this.pricelistMessage(notification.data);
                notification.redirect = '';
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.CONTRACTOR_PRICE_LIST_UPDATED:
                notification.title = 'Nov cenik';
                notification.backgroundColor = '#ADD8e6';
                notification.borderColor = '#008BB8';
                notification.link = `settings/contractors/${notification.data.contractorId}/pricelist/${notification.data.priceListId}`;
                notification.name = notification.data.appUserName;
                notification.messages = this.pricelistMessage(notification.data);
                notification.redirect = 'normal';
                notification.dataInFooter = 'business';
                return notification;

            // asistent
            case NotificationEnum.ASISTANT_CASE_CONTRACTOR_COMMENT_ADDED:
                notification.title = 'Nov komentar';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `authorization/details`;
                notification.name = notification.data.appUserName;
                notification.messages = this.commentAddedMessage(notification.data);
                notification.redirect = 'authorization';
                notification.prereservationId = notification.data.caseId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.ASISTANT_TRIAGE_CONTRACTOR_DOCUMENT_ADDED:
                notification.title = 'Nov dokument';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `authorization/details`;
                notification.name = notification.data.appUserName;
                notification.messages = this.documentAddedMessage(notification.data);
                notification.redirect = 'triage';
                notification.prereservationId = notification.data.triageId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.ASISTANT_TRIAGE_CONTRACTOR_COMMENT_ADDED:
                notification.title = 'Nov komentar';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `authorization/details`;
                notification.name = notification.data.appUserName;
                notification.messages = this.commentAddedMessage(notification.data);
                notification.redirect = 'triage';
                notification.prereservationId = notification.data.triageId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.ASISTANT_CASE_CONTRACTOR_DOCUMENT_ADDED:
                notification.title = 'Nov dokument';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `authorization/details`;
                notification.name = notification.data.appUserName;
                notification.messages = this.documentAddedMessage(notification.data);
                notification.redirect = 'authorization';
                notification.prereservationId = notification.data.caseId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.VISIT_CONCLUDED:
                notification.title = 'Obisk zaključen';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `authorization/details`;
                notification.name = notification.data.appUserName;
                notification.messages = null;
                notification.redirect = 'authorization';
                notification.prereservationId = notification.data.caseId;
                notification.dataInFooter = 'business';
                return notification;

            default:
                notification.title = 'Manjkajo podatki';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                return notification;
                break;
        }
    }

    public reservationUpdatedSetMessage(data: any): NotificationMessage[] {
        const messages: NotificationMessage[] = [];

        if (data.newFrom !== data.originalFrom) {
            const msg1: NotificationMessage = new NotificationMessage().deserialize({
                message: data.newFrom,
                messageStyle: '',
                datePipe: true,
                icon: 'la la-calendar',
            });

            const msg2: NotificationMessage = new NotificationMessage().deserialize({
                message: data.originalFrom,
                messageStyle: { 'text-decoration': 'line-through', 'margin-left': '19px' },
                datePipe: true,
                icon: 'none',
            });
            messages.push(msg1, msg2);
        }

        if (data.subcontractorName) {
            const msg1: NotificationMessage = new NotificationMessage().deserialize({
                message: data.subcontractorName,
                messageStyle: '',
                datePipe: false,
                icon: 'la la-stethoscope',
            });

            const msg2: NotificationMessage = new NotificationMessage().deserialize({
                message: data.originalSubcontractorName,
                messageStyle: { 'text-decoration': 'line-through', 'margin-left': '19px' },
                datePipe: false,
                icon: 'none',
            });
            messages.push(msg1, msg2);
        }
        if (messages.length == 0) {
            const msg: NotificationMessage = new NotificationMessage().deserialize({
                message: 'Rezervacija spremenjena',
                messageStyle: '',
                datePipe: false,
                icon: 'none',
            });
            messages.push(msg);
        }
        return messages;
    }

    public documentAddedMessage(data: any): NotificationMessage[] {
        const messages: NotificationMessage[] = [];
        const msg1: NotificationMessage = new NotificationMessage().deserialize({
            message: data.documentDescription,
            messageStyle: '',
            datePipe: false,
            icon: 'none',
        });
        messages.push(msg1);
        return messages;
    }

    public commentAddedMessage(data: any): NotificationMessage[] {
        const messages: NotificationMessage[] = [];
        const msg1: NotificationMessage = new NotificationMessage().deserialize({
            message: data.comment,
            messageStyle: '',
            datePipe: false,
            icon: 'none',
        });
        messages.push(msg1);
        return messages;
    }

    public pricelistMessage(data: any): NotificationMessage[] {
        const messages: NotificationMessage[] = [];
        const msg1: NotificationMessage = new NotificationMessage().deserialize({
            message: data.priceListName,
            messageStyle: '',
            datePipe: false,
            icon: 'none',
        });
        messages.push(msg1);
        return messages;
    }

    public reservationCanceledMessage(customerName: string): NotificationMessage[] {
        const messages: NotificationMessage[] = [];
        const msg1: NotificationMessage = new NotificationMessage().deserialize({
            message: customerName,
            messageStyle: '',
            datePipe: false,
            icon: 'la la-user',
        });
        messages.push(msg1);
        return messages;
    }

    public triageAnsweredMessage(data: any): NotificationMessage[] {
        const messages: NotificationMessage[] = [];
        const msg1: NotificationMessage = new NotificationMessage().deserialize({
            message: data.serviceName,
            messageStyle: '',
            datePipe: false,
            icon: 'none',
        });
        messages.push(msg1);
        return messages;
    }

    public redirectSwitch(redirect, link, data): void {
        switch (redirect) {
            case 'reservation':
                this.route.navigate([link]);
                break;
            case 'normal':
                this.route.navigate([link]);
                break;
            case 'assistant':
                this.route.navigate([link]);
                break;
            case 'authorization':
                this.redirectAuth(data.caseId);
                break;
            case 'triage':
                this.redirectTriage(data.triageId);
                break;
            default:
                break;
        }
    }

    private redirectAuth(caseId: string): any {
        this.authService.getSingleCase(this.storage.getBusinessSubject().id, caseId).subscribe((res) => {
            this.store.dispatch(new OpenAuthorityCase(new AuthorizationTriage().deserialize(res[0]))).subscribe(() => {
                this.helper.navigate(['/authorization/details']);
            });
        });
    }

    private redirectTriage(caseId: string): any {
        this.triageService.getTriage(this.storage.getBusinessSubject().id, caseId).subscribe((res) => {
            if (!res.caseAuthority.id) {
                res.caseAuthority.id = res.caseAuthority.authorityId;
            }

            this.store
                .dispatch(
                    new OpenAuthorityCase(
                        new AuthorizationTriage().deserialize({
                            appUser: res.appUserName,
                            triageId: res.id,
                        }),
                    ),
                )
                .subscribe(() => {
                    this.helper.navigate(['/authorization/details']);
                });
        });
    }
}
