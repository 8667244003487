import { AdditionalData } from './../authorization/additional-data';
import { UrlService } from './BS1/url-service.model';
import { UrlCase } from './url-case.model';
import { User } from './../user.model';
import { Customer } from './../customer.model';
import { Serializable } from '../deserialize.service';

export class UrlData implements Serializable<UrlData> {
    public customer: Customer;
    public user: User;
    public case: UrlCase;
    public service: any;
    public userId: string;
    public additionalData?: AdditionalData;

    deserialize(input: any) {
        this.customer = input.customer;
        this.user = input.user;
        this.case = input.case;
        this.service = input.service;
        this.userId = input.userId;
        this.additionalData = input.additionalData;
        return this;
    }
}
