import { Serializable } from '../deserialize.service';
import { AuthorizationType } from '../../enums/authorizationType.enum';
import * as moment from 'moment';

export class ReservationStatus implements Serializable<ReservationStatus> {
    bsId: number;
    caseId: string;
    caseInternalId: number;
    eventType: AuthorizationType;
    prereservationId: string;
    eventDate: moment.Moment;
    subcontractorId: Number;
    subcontractorName: string;
    wqId: number;
    x21Timestamp: any;
    reservationDate: moment.Moment;

    deserialize(input) {
        this.bsId = input.bsId;
        this.caseId = input.caseId;
        // this.contractorId = input.contractorId;
        this.caseInternalId = input.caseInternalId;
        this.eventType = input.eventType;
        this.prereservationId = input.prereservationId;
        if (input.eventDate) {
            this.eventDate = moment(input.eventDate);
        }
        this.subcontractorId = input.subcontractorId;
        this.subcontractorName = input.subcontractorName;
        this.wqId = input.wqId;
        this.x21Timestamp = input.x21Timestamp;
        if (input.reservationDate) {
            this.reservationDate = moment(input.reservationDate);
        }
        return this;
    }

    // public getOrderObj(): AuthorizationOrder {
    //     return new AuthorizationOrder().deserialize({
    //         externalBsUserId: this.user.id,
    //         caseId: this.case_id,
    //         customer: this.customer,
    //         services: this.service
    //     });
    // }
}
