import { Treatment } from './schedule/treatment.model';
import { Serializable } from './deserialize.service';

export class Slot implements Serializable<Slot> {
    start: Date;
    end: Date;
    scheduleId: string;
    assetId: number;
    bsids: number[];
    treatments: Treatment[];

    deserialize(input) {
        // debugger;
        this.start = input.start;
        this.end = input.end;
        this.scheduleId = input.scheduleId;
        this.assetId = input.assetId;
        this.bsids = input.bsids;
        this.treatments = input.treatments;
        return this;
    }
}
