<div class="text-center" style="color: #fff">
    <h3 i18n="@@7efdad29-09ce-5f52-9b8a-a520c16590e4">Rezervacija termina pri izvajalcu</h3>
</div>
<div class="d-flex justify-content-center">
    <div style="max-width: 700px">
        <div class="row">
            <div class="col-sm-6">
                <div class="m-portlet m-portlet--full-height mb-0 mfa-portlet-mid-title">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title">
                                <h3 class="m-portlet__head-text" i18n="@@8b5a322e-04bc-5de9-ab87-a7d525bb63e9">
                                    Izvajalec
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="m-portlet__body">

                        <!-- {{termDetails|json}} -->
                        <div class="mfa-portlet-mid-title__padding">
                            <div>{{termDetails?.contractor?.name}}</div>
                            <div>{{termDetails?.contractor?.address}}</div>
                            <div>{{termDetails?.contractor?.postComb}}</div>
                            <div class="pt-2">
                                <i class="la la-phone m--font-inverse-light" ngbTooltip="Telefon stranke" i18n-ngbTooltip="@@1d207701-f4af-5b31-b0c6-7767aa91a19f"></i>
                                {{termDetails?.contractor?.phone}}
                            </div>
                            <div>
                                <i class="la la-at m--font-inverse-light" ngbTooltip="Email stranke" i18n-ngbTooltip="@@cdf5b1f5-7aee-59b6-97d7-f4f7374ba458"></i>
                                {{termDetails?.contractor?.email}}
                            </div>

                        </div>
                        <div class="mfa-portlet-mid-title__title" i18n="@@39c66b3d-1f29-5b37-b1d0-61ac0a8da956">
                            Termin obravnave
                        </div>
                        <div class="mfa-portlet-mid-title__padding">
                            <div *ngIf="termDetails?.directBooking">
                                <div class="d-flex w-100 mfa-flex-row">
                                    <div class="mfa-flex-cell w-100 m--font-bolder" i18n="@@2b82fb65-48ca-584c-a4ac-5eef42267615">
                                        Datum
                                    </div>
                                    <div class="mfa-flex-cell w-100">
                                        {{termDetails?.slot?.start | date:'dd.MM.yyyy'}}
                                    </div>
                                </div>
                                <div class="d-flex w-100 mfa-flex-row">
                                    <div class="mfa-flex-cell w-100 m--font-bolder">
                                        Čas
                                    </div>
                                    <div class="mfa-flex-cell w-100">
                                        od {{termDetails?.slot?.start | date:'HH:mm'}} do {{termDetails?.slot?.end |
                                        date:'HH:mm'}}
                                    </div>
                                </div>
                                <div class="d-flex w-100 mfa-flex-row">
                                    <div class="mfa-flex-cell w-100 m--font-bolder" i18n="@@6f5a6b2c-272d-5040-bf59-9d8029ba1426">
                                        Podizvajalec
                                    </div>
                                    <div class="mfa-flex-cell w-100">
                                        {{termDetails?.subcontractor?.name}}
                                    </div>
                                </div>
                                <div class="d-flex w-100 mfa-flex-row">
                                    <div class="mfa-flex-cell w-100 m--font-bolder" i18n="@@10283651-89a0-52c0-9746-09604f822863">
                                        Storitev IZV
                                    </div>
                                    <div class="mfa-flex-cell w-100 text-truncate">
                                        {{termDetails?.service?.name}}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!termDetails?.directBooking" i18n="@@833c10d3-7153-5b05-bfb9-5c99d63dc5cf">
                                Vsa naročila gredo v čakalno vrsto izbranega izvajalca.
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div class="col-sm-6">
                <div class="m-portlet">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title">
                                <h3 class="m-portlet__head-text" i18n="@@d2f1e2b9-9143-5a48-84ba-1867e616b529">
                                    Stranka
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="m-portlet__body">
                        <div class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <i class="la la-user m--font-inverse-light"></i>
                            </span>
                            <span class="m-list-search__result-item-text">
                                {{termDetails?.customer?.name}}
                                {{termDetails?.customer?.surname}}
                            </span>
                        </div>

                        <div class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <i class="la la-birthday-cake m--font-inverse-light"></i>
                            </span>
                            <span class="m-list-search__result-item-text">
                                {{termDetails?.customer?.birthDate |date: 'dd. MM. yyyy'}}
                            </span>
                        </div>

                        <div class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <i class="la la-map-marker m--font-inverse-light"></i>
                            </span>
                            <span class="m-list-search__result-item-text">
                                {{termDetails?.customer?.address}}
                            </span>
                        </div>

                        <div class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon pr-4">

                            </span>
                            <span class="m-list-search__result-item-text">
                                {{termDetails?.customer?.post}} {{termDetails?.customer?.city}}
                            </span>
                        </div>

                        <div class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <i class="la la-mobile-phone m--font-inverse-light"></i>
                            </span>
                            <span class="m-list-search__result-item-text">
                                {{termDetails?.customer?.phone}}
                            </span>
                        </div>
                        <div class="m-list-search__result-item">
                            <span class="m-list-search__result-item-icon">
                                <i class="la la-at m--font-inverse-light"></i>
                            </span>
                            <span class="m-list-search__result-item-text">
                                {{termDetails?.customer?.email}}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="m-portlet">
                    <div class="m-portlet__head">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title">
                                <h3 class="m-portlet__head-text" i18n="@@7c30ba8d-056d-5bdd-8ff6-7f69451aac80">
                                    Avtorizacija
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="m-portlet__body">
                        <div>
                            <div class="d-flex w-100 mfa-flex-row">
                                <div class="mfa-flex-cell w-100 m--font-bolder" i18n="@@ef507b0b-f548-5e06-be2b-3af1de2448e6">
                                    ID Avtorizacije
                                </div>
                                <div class="mfa-flex-cell w-100">
                                    {{termDetails?.authorization?.caseId}}
                                </div>
                            </div>
                            <div class="d-flex w-100 mfa-flex-row">
                                <div class="mfa-flex-cell w-100 m--font-bolder" i18n="@@e5974713-b853-5782-9885-fc1e27c6038a">
                                    Storitev ZAV
                                </div>
                                <div class="mfa-flex-cell w-100">
                                    {{termDetails?.authorization?.services[0]?.name}}
                                </div>
                            </div>
                            <div class="d-flex w-100 mfa-flex-row">
                                <div class="mfa-flex-cell w-100 m--font-bolder" i18n="@@9f4a72fd-14c3-5960-bb51-3a633ec95cf4">
                                    Skrbnik
                                </div>
                                <div class="mfa-flex-cell w-100">
                                    Marjeta Klementc
                                </div>
                            </div>
                            <div class="d-flex w-100 mfa-flex-row">
                                <div class="mfa-flex-cell w-100 m--font-bolder" i18n="@@a82529be-751e-59a4-86e5-0ef8d20f6a9f">
                                    Št. obravnav
                                </div>
                                <div class="mfa-flex-cell w-100">
                                    {{termDetails?.authorization?.numOfVisits}}
                                </div>
                            </div>
                            <!-- <div class="d-flex w-100 mfa-flex-row">
                                <div class="mfa-flex-cell w-100 m--font-bolder">
                                    Inf. znesek
                                </div>
                                <div class="mfa-flex-cell w-100">
                                    80 €
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-sm-2">
        </div> -->
        </div>
        <div class="row">
            <div class="col-12">
                <div class="mfa-notification" i18n="@@bb463877-4442-540a-ba77-978a4322810c">
                    Z rezervacijo prvega termina iz paketa bodo v čakalno vrsto izvajalca poslane tudi preostale
                    obravnave iz paketa.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="ml-auto btn m-btn--pill btn-danger m-btn m-btn--custom" (click)="activeModal.close('Close click')" i18n="@@70ae211c-43a3-5810-a699-672ba930ee08">Prekliči</button>
    <button type="button" class="mr-auto btn m-btn--pill btn-success m-btn m-btn--custom" (click)="createReservation()" i18n="@@609039bf-5cb3-5b6a-b3ae-ab0a9224ed71">Potrdi</button>
</div>