import { Serializable } from './deserialize.service';

export class PreReservation implements Serializable<PreReservation> {
    aggregateId: string;
    prereservationId: string;
    comment: string;
    userId: string;
    appUserId: number;
    appUserName: string;
    leasedBs: number;

    constructor(obj?: any) {
        this.aggregateId = (obj && obj.aggregateId) || null;
        this.prereservationId = (obj && obj.prereservationId) || null;
        this.comment = (obj && obj.comment) || null;
        this.userId = (obj && obj.userId) || null;
        this.appUserId = (obj && obj.appUserId) || null;
        this.appUserName = (obj && obj.appUserName) || null;
        this.leasedBs = (obj && obj.leasedBs) || null;
    }

    deserialize(input) {
        this.aggregateId = input.aggregateId;
        this.prereservationId = input.prereservationId;
        this.comment = input.comment;
        this.userId = input.userId;
        this.appUserId = input.appUserId;
        this.appUserName = input.appUserName;
        this.leasedBs = input.leasedBs;
        return this;
    }
}
