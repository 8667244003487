<div class="modal-header">
    <h4 class="modal-title" *ngIf="!contractor" i18n="@@8111233051918336">
        Dodajanje novega izvajalca
    </h4>
    <h4 class="modal-title" *ngIf="contractor" i18n="@@4120362698670080">Urejanje izvajalca</h4>
</div>
<div class="modal-body">
    <form [formGroup]="contractorDetailsForm" novalidate *ngIf="contractorDetailsForm">
        <div class="m-list-search">
            <div class="m-list-search__results">
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i class="la la-user m--font-inverse-light" ngbTooltip="Naziv izvajalca"></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <ng-select
                            ngbTooltip="{{ contractorDetailsForm?.value?.name }}"
                            [disableTooltip]="contractorDetailsForm?.value?.name?.length < 25"
                            [items]="contractorsFromCRM"
                            bindLabel="name"
                            formControlName="name"
                            placeholder="Izvajalec"
                            [clearable]="true"
                            [addTag]="true"
                            [addTagText]="'Ročni vnos izvajalca:'"
                            (change)="fillContractorData($event)"
                        ></ng-select>
                    </span>
                </div>
                <div *ngIf="contractorDetailsForm.controls['name']?.errors && isSubmitted" class="m--font-danger pl-5">
                    <small [hidden]="!contractorDetailsForm.controls['name']?.errors?.required" i18n
                        >Izvajalec je obvezno polje.
                    </small>
                </div>
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i class="la la-map-marker m--font-inverse-light" ngbTooltip="Naslov izvajalca"></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="address"
                            type="text"
                            class="form-control m-input"
                            placeholder="Naslov"
                            [ngClass]="{ 'form-control-sm': isSmallInput }"
                        />
                    </span>
                </div>

                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon"> </span>
                    <span class="m-list-search__result-item-text">
                        <div class="row">
                            <div class="col-xl-6 col-md-6 mb-2">
                                <input
                                    formControlName="postNumber"
                                    type="text"
                                    class="form-control m-input"
                                    placeholder="Poštna številka"
                                    [ngClass]="{ 'form-control-sm': isSmallInput }"
                                />
                            </div>
                            <div class="col-xl-6 col-md-6">
                                <input
                                    formControlName="post"
                                    type="text"
                                    class="form-control m-input"
                                    placeholder="Pošta"
                                    [ngClass]="{ 'form-control-sm': isSmallInput }"
                                />
                            </div>
                        </div>
                    </span>
                </div>
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <span class="la-i-cursor la m--font-inverse-light" ngbTooltip="Šifra izvajalca"></span>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            [formControlName]="
                                bsId == 1 || bsId == 11
                                    ? 'generaliExternalId'
                                    : bsId == 2
                                    ? 'tzzOsebaId'
                                    : 'inisExternalId'
                            "
                            type="text"
                            class="form-control m-input"
                            placeholder="Šifra izvajalca"
                            [ngClass]="{ 'form-control-sm': isSmallInput }"
                        />
                    </span>
                </div>
                <ng-container *ngIf="bsId == 1 || bsId == 11">
                    <div class="m-list-search__result-item">
                        <span class="m-list-search__result-item-icon">
                            <span class="la-i-cursor la m--font-inverse-light" ngbTooltip="Šifra osa"></span>
                        </span>
                        <span class="m-list-search__result-item-text">
                            <input
                                formControlName="generaliOsaId"
                                type="number"
                                class="form-control m-input"
                                placeholder="Šifra osa"
                                [ngClass]="{ 'form-control-sm': isSmallInput }"
                            />
                        </span>
                    </div>
                </ng-container>
                <div class="m-list-search__result-item" *ngIf="bsId == 2">
                    <span class="m-list-search__result-item-icon">
                        <span class="la-i-cursor la m--font-inverse-light" ngbTooltip="Šifra naslova"></span>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="tzzExternalId"
                            type="text"
                            class="form-control m-input"
                            placeholder="Šifra naslova"
                            [ngClass]="{ 'form-control-sm': isSmallInput }"
                        />
                    </span>
                </div>
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <span class="la la-envelope m--font-inverse-light" ngbTooltip="Email izvajalca"></span>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="email"
                            type="text"
                            class="form-control m-input"
                            placeholder="Email"
                            [ngClass]="{ 'form-control-sm': isSmallInput }"
                        />
                    </span>
                </div>

                <div *ngIf="contractorDetailsForm.controls['email'].errors && isSubmitted" class="m--font-danger pl-5">
                    <small [hidden]="!contractorDetailsForm.controls['email']?.errors?.required" i18n>
                        Email je obvezno polje.
                    </small>

                    <small [hidden]="!contractorDetailsForm.controls['email']?.errors?.email" i18n>
                        Email ni pravilen.
                    </small>
                </div>

                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <span class="la la-phone m--font-inverse-light" ngbTooltip="Telefon izvajalca"></span>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="phone"
                            type="text"
                            placeholder="Telefon"
                            class="form-control m-input"
                            [ngClass]="{ 'form-control-sm': isSmallInput }"
                        />
                    </span>
                </div>
                <div *ngIf="contractorDetailsForm.controls['phone']?.errors && isSubmitted" class="m--font-danger pl-5">
                    <small
                        [hidden]="!contractorDetailsForm.controls['phone']?.errors?.required"
                        i18n="@@ba797024-dfc4-5496-90d8-deb098fe2eb4"
                        >Telefon je obvezno polje.
                    </small>
                    <small [hidden]="!contractorDetailsForm.controls['phone']?.errors?.minlength"
                        >Dolžina telefonske številke je prekratka! Vnesite vsaj 9 števil.
                    </small>
                </div>
                <!-- 
                *****************
                KONTAKTNI PODATKI
                ***************** 
            -->
                <!-- <ng-container *ngIf="">
                 
                    <div class="pt-4">
                        <h6>Naslov po meri</h6>
                    </div>
                    <div class="m-list-search__result-item">
                        <span class="m-list-search__result-item-icon">
                            <i class="la la-user m--font-inverse-light" ngbTooltip="Naziv izvajalca"></i>
                        </span>
                        <span class="m-list-search__result-item-text">
                            <input
                                formControlName="contactTitle"
                                type="text"
                                class="form-control m-input"
                                placeholder="Naziv izvajalca"
                                [ngClass]="{ 'form-control-sm': isSmallInput }"
                            />
                        </span>
                    </div>
                    <div class="m-list-search__result-item">
                        <span class="m-list-search__result-item-icon">
                            <i
                                class="la la-map-marker m--font-inverse-light"
                                ngbTooltip="Kontaktni naslov izvajalca"
                            ></i>
                        </span>
                        <span class="m-list-search__result-item-text">
                            <div class="row">
                                <div class="col-xl-8 col-md-8 mb-2">
                                    <input
                                        formControlName="contactStreetName"
                                        type="text"
                                        class="form-control m-input"
                                        placeholder="Naslov"
                                        [ngClass]="{ 'form-control-sm': isSmallInput }"
                                    />
                                </div>
                                <div class="col-xl-4 col-md-4 mb-2">
                                    <input
                                        formControlName="contactStreetNumber"
                                        type="text"
                                        class="form-control m-input"
                                        placeholder="Hišna številka"
                                        [ngClass]="{ 'form-control-sm': isSmallInput }"
                                    />
                                </div>
                            </div>
                        </span>
                    </div>

                    <div class="m-list-search__result-item">
                        <span class="m-list-search__result-item-icon"> </span>
                        <span class="m-list-search__result-item-text">
                            <div class="row">
                                <div class="col-xl-6 col-md-6 mb-2">
                                    <input
                                        formControlName="contactPostNumber"
                                        type="text"
                                        class="form-control m-input"
                                        placeholder="Poštna številka"
                                        [ngClass]="{ 'form-control-sm': isSmallInput }"
                                    />
                                </div>
                                <div class="col-xl-6 col-md-6">
                                    <input
                                        formControlName="contactPostName"
                                        type="text"
                                        class="form-control m-input"
                                        placeholder="Pošta"
                                        [ngClass]="{ 'form-control-sm': isSmallInput }"
                                    />
                                </div>
                            </div>
                        </span>
                    </div>
                    <div class="m-list-search__result-item">
                        <span class="m-list-search__result-item-icon">
                            <span
                                class="la la-envelope m--font-inverse-light"
                                ngbTooltip="Kontaktni email izvajalca"
                            ></span>
                        </span>
                        <span class="m-list-search__result-item-text">
                            <input
                                formControlName="contactEmail"
                                type="text"
                                class="form-control m-input"
                                placeholder="Kontaktni email"
                                [ngClass]="{ 'form-control-sm': isSmallInput }"
                            />
                        </span>
                    </div>
                    <div
                        *ngIf="contractorDetailsForm.controls['contactEmail']?.errors && isSubmitted"
                        class="m--font-danger pl-5"
                    >
                        <small [hidden]="!contractorDetailsForm.controls['contactEmail']?.errors?.required" i18n>
                            Email je obvezno polje.
                        </small>

                        <small [hidden]="!contractorDetailsForm.controls['contactEmail']?.errors?.email" i18n>
                            Email ni pravilen.
                        </small>
                    </div>
                    <div class="m-list-search__result-item">
                        <span class="m-list-search__result-item-icon">
                            <span
                                class="la la-phone m--font-inverse-light"
                                ngbTooltip="Kontaktni telefon izvajalca"
                            ></span>
                        </span>
                        <span class="m-list-search__result-item-text">
                            <input
                                formControlName="contactPhone"
                                type="text"
                                placeholder="Kontaktni telefon"
                                class="form-control m-input"
                                [ngClass]="{ 'form-control-sm': isSmallInput }"
                            />
                        </span>
                    </div>
                    <div
                        *ngIf="contractorDetailsForm.controls['contactPhone']?.errors && isSubmitted"
                        class="m--font-danger pl-5"
                    >
                        <small
                            [hidden]="!contractorDetailsForm.controls['phone']?.errors?.pattern"
                            i18n="@@df7c11e4-ba4b-5a93-b572-2e79212df86c"
                        >
                            Vsi znaki niso številke!
                        </small>
                        <small
                            [hidden]="!contractorDetailsForm.controls['contactPhone']?.errors?.required"
                            i18n="@@ba797024-dfc4-5496-90d8-deb098fe2eb4"
                            >Telefon je obvezno polje.
                        </small>
                        <small [hidden]="!contractorDetailsForm.controls['contactPhone']?.errors?.minlength"
                            >Dolžina telefonske številke je prekratka! Vnesite vsaj 9 števil.
                        </small>
                    </div>
                </ng-container> -->
                <ng-container *ngIf="newContractor">
                    <hr />
                    <div class="m-list-search__result-item d-flex">
                        <div class="m-radio-inline">
                            <label class="m-radio m-radio--state-brand">
                                <input type="radio" [checked]="agreedTermsChecked" (change)="termsChanged($event)" />
                                <span></span>
                            </label>
                        </div>
                        <div>
                            <span i18n="@@7807518608719872">
                                <i>
                                    Želim dodati novega izvajalca zdravstvene storitve, s katerim ima pravni subjekt, ki
                                    ga predstavljam, urejen formalno poslovni odnos glede sodelovanja na področju
                                    izvajanja zdravstvenih storitev
                                </i>
                            </span>
                        </div>
                    </div>
                    <div
                        *ngIf="contractorDetailsForm.controls['agreedTerms']?.errors && isSubmitted"
                        class="m--font-danger ml-4"
                    >
                        <small
                            [hidden]="!contractorDetailsForm.controls['agreedTerms']?.errors?.required"
                            i18n="@@4998397197025280"
                            class="pl-3"
                        >
                            Izjava nujna za nadaljevanje.
                        </small>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="ml-auto mr-auto btn m-btn--pill btn-danger m-btn m-btn--custom"
        (click)="dismiss('Close click')"
        tabindex="8"
        i18n="@@d2552f4e-a8b7-5daf-9c89-f1f0ac30a2df"
    >
        Prekliči
    </button>
    <button
        (click)="createContractor()"
        type="buttons"
        class="mr-auto btn m-btn--pill btn-accent m-btn m-btn--custom"
        tabindex="6"
        i18n="@@615c16be-d366-5ec4-a79a-7db71946bb97"
    >
        Potrdi
    </button>
</div>
