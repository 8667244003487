import {ActiveModulesService} from './active-modules.service';
import {AppModulesType} from './../enums/appModulesType.enum';
import {MenuItem} from './../model/utils/menuItem.model';
import {catchError, filter, map, take} from 'rxjs/operators';
// import { NgxRolesService, NgxPermissionsService } from 'ngx-permissions';
import {Injectable, Inject} from '@angular/core';
import {StorageService} from './storage.service';
import {Subject, BehaviorSubject, Observable, of, forkJoin} from 'rxjs';
// import { Customer } from '../model/customer.model';
import * as _ from 'lodash';
import {NgxPermissionsService} from 'ngx-permissions';
import {AnalyticsConfig} from '../model/analyticsConfig.model';
import {AnalyticsService} from './analytics.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class MenuService {
    public urlPrefix: string = _.get(this.storage.getAppData(), 'type', '');

    public default: MenuItem[] = [
        // {
        //     link: '/index',
        //     title: 'Domov'
        // },
        {
            link: `/${this.urlPrefix}/customers`,
            title: 'Stranke',
            rules: 'customers_isVisible',
        },
        // {
        //     link: '/terms',
        //     title: 'Termini'
        // },
        {
            link: `/${this.urlPrefix}/authorization`,
            title: 'Primeri',
            rules: 'authorizations_isVisible',
        },
        {
            link: `/${this.urlPrefix}/contractors/main`,
            title: 'Izvajalci',
            rules: 'contractors_isVisible',
        },
        {
            link: `/${this.urlPrefix}/tasks`,
            title: 'Opravila',
            rules: 'tasks_isVisible',
        },
        {
            link: `/${this.urlPrefix}/analytics`,
            title: 'Analize',
            rules: 'settings_analytics_isVisible',
        },
        /*
        {
            link: `/${this.urlPrefix}/hd-analytics`,
            title: 'Halo Doktor',
            rules: 'settings_halodoktor_isVisible',
            module: AppModulesType.HALODOKTOR_STAT_MODULE,
        },
         */
        {
            link: `/analytics`,
            title: 'Analitika',
            rules: 'metabaseAnalytics_isVisible',
            module: AppModulesType.ANALYTICS_MODULE,
        },
        {
            link: `/enarocanje`,
            title: 'eNaročanje',
            rules: 'eNarocanje_isVisible',
            production: false,
        },
    ].map((el) => new MenuItem().deserialize(el));

    public externalOrder: any = [
        // {
        //     link: '/index',
        //     title: 'Domov'
        // },
        {
            link: '/customers',
            title: 'Stranke',
        },
        {
            link: '/authorization',
            title: 'Avtorizacije',
        },
        {
            link: '/contractors/main',
            title: 'Izvajalci',
        },
    ];

    public hiddeHeader: BehaviorSubject<any> = new BehaviorSubject(false);
    // public menuList: BehaviorSubject<any> = new BehaviorSubject(this.validateMenu(this.default));
    // public menuList: BehaviorSubject<any> = new BehaviorSubject(this.default);
    public menuList: BehaviorSubject<any> = new BehaviorSubject([]);

    // public customerInMenu: Subject<Customer> = new Subject();

    constructor(
        private storage: StorageService,
        private permissionsService: NgxPermissionsService,
        private am: ActiveModulesService,
        private analyticsService: AnalyticsService,
    ) {
        // this.generateMenu();
        this.storage.appData().subscribe((res) => {
            this.urlPrefix = res.type;
            // this.generateMenu();
        });
        // this.permissionsService.permissions$.subscribe(() => {
        //     // debugger;
        //     // const appData: any = this.storage.getAppData();
        //     // if (appData) {
        //     //     this.urlPrefix = appData.type;
        //     // }
        //     this.generateMenu();
        // });

        // const s = _.get(this.storage.getAppData(), 'type', '');
        // debugger;
        // this.permissionsService.hasPermission('customer_details_canEdit').then(isEditable => {
        // });
    }

    // public generateMenu() {
    //     this.showMenu();
    // }

    public showMenu(): any {
        //this.menuList.next(this.validateMenu(this.default));
    }

    public validateMenu(menu: MenuItem[]): Observable<MenuItem[]> {
        const allPermissions = this.permissionsService.getPermissions();

        const menuItems$ = [];
        menu.forEach((menuItem) => {
            let elem: Observable<{ menuItem: MenuItem, enabled: boolean }>;
            if (menuItem.production === false && environment.production) {
                elem = (of({
                    menuItem: menuItem,
                    enabled: false,
                }));
            } else if (menuItem.module && !this.am.isAM(menuItem.module)) {
                elem = (of({
                    menuItem: menuItem,
                    enabled: false,
                }));
            } else if (menuItem.rules) {
                if (allPermissions[menuItem.rules]) {
                    // ima dovoljenje za prikaz
                    elem = (of({
                        menuItem: menuItem,
                        enabled: true,
                    }));
                } else {
                    // nima role - skrij
                    elem = (of({
                        menuItem: menuItem,
                        enabled: false,
                    }));
                }
            } else {
                elem = (of({
                    menuItem: menuItem,
                    enabled: true,
                }));
            }

            menuItems$.push(elem);
        });

        /*
        const res = menu.filter((menuItem) => {

        });*/

        return forkJoin(menuItems$).pipe(
            map((items: any) => {

                const calculatedMenu = [];

                items.forEach((itm) => {
                    if (itm.enabled) {
                        calculatedMenu.push(itm.menuItem);
                    }
                });

                return calculatedMenu;
                //return item.menuItem;
            }));
        // this.permissionsService.hasPermission('customer_details_canEdit').then(isEditable => {
        //     debugger;
        // });
    }
}
