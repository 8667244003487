import { Slot } from './slot.model';
import { Serializable } from './deserialize.service';
import { Service } from './service/service.model';
import { ServiceReference } from './service/serviceReference.model';
import { Subcontractor } from './subcontractor.model';
import * as _ from 'lodash';
import { OrgUnit } from './orgUnit.model';

export class Contractor implements Serializable<Contractor> {
    id: number;
    customId: string;
    name: string;
    address: string;
    description: string;
    webId: string;
    services: Service[];
    subcontractors: Contractor[];
    color: string;
    email: string;
    contactEmail: string;
    phone: string;
    contactPhone: string;
    post: string;
    postNumber: string;
    postComb: string;
    timeSlots: Slot[];
    directBooking: boolean;
    directTriage: boolean;
    enabledInAssist: boolean;
    bpiType: string;
    externalId: string;
    subspecializations: string[];
    orgUnits: OrgUnit[];
    contractorId: string;
    bpi: string;
    priorityBooking: boolean;
    cInfoId: number;
    averageResult: number;
    directBookingAndWQ: boolean;
    sendContractorNotifications: boolean;
    generaliExternalId: string;
    tzzExternalId: string;
    tzzOsebaId: string;
    inisExternalId: string;
    generaliOsaId: string;

    bsId: any;

    deserialize(input: any) {
        this.id = input.id;
        this.customId = input.customId;
        this.name = input.name;
        this.address = input.address;
        this.description = input.description;
        this.webId = input.webId;
        this.color = input.color;
        this.email = input.email;
        this.contactEmail = input.contactEmail;
        this.phone = input.phone;
        this.contactPhone = input.contactPhone;
        this.bpiType = input.bpiType;
        this.directBooking = input.directBooking;
        this.directTriage = input.directTriage;
        this.enabledInAssist = input.enabledInAssist;
        this.externalId = input.externalId;
        this.subspecializations = input.subspecializations;

        this.directBookingAndWQ = input.directBookingAndWQ;
        this.sendContractorNotifications = input.sendContractorNotifications;
        this.contractorId = input.contractorId;
        this.bpi = input.bpi;
        this.priorityBooking = input.priorityBooking;
        this.cInfoId = input.cInfoId;

        this.generaliExternalId = input.generaliExternalId;
        this.tzzExternalId = input.tzzExternalId;
        this.tzzOsebaId = input.tzzOsebaId;
        this.inisExternalId = input.inisExternalId;
        this.generaliOsaId = input.generaliOsaId;

        this.bsId = input.bsId;

        if (this.averageResult) {
            this.averageResult = input.averageResult;
        }

        if (input.post && input.postNumber) {
            this.post = input.post;
            this.postNumber = input.postNumber;
            this.postComb = this.postNumber + ' ' + this.post;
        }
        if (input.services) {
            this.services = input.services.map((conr) => {
                return new Service().deserialize(conr);
            });
        }
        if (input.subcontractors) {
            this.subcontractors = input.subcontractors.map((conr) => {
                return new Contractor().deserialize(conr);
            });
        }
        if (input.orgUnits) {
            this.orgUnits = input.orgUnits.map((orgUnit) => {
                return new OrgUnit().deserialize(orgUnit);
            });
        }

        return this;
    }

    public getId(): string {
        if (_.isNil(this.id)) {
            return this.externalId;
        }
        return this.id + '';
    }
}
