import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/_guards/auth.guard';
import { EnarocanjeComponent } from './components/enarocanje/enarocanje.component';

const routes: Routes = [
    {
        path: '',
        component: ThemeComponent,
        canActivate: [AuthGuard],
        children: [
            // {
            //     path: 'index',
            //     loadChildren: './pages/aside-left-display-disabled/index/index.module#IndexModule'
            // },
            {
                path: 'index',
                loadChildren: () => import('./pages/home/index/index.module').then((m) => m.IndexModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./pages/default/settings/settings.module').then((m) => m.SettingsModule),
            },
            {
                path: 'analytics',
                loadChildren: () => import('./pages/default/analytics/analytics.module').then((m) => m.AnalyticsModule),
            },
            {
                path: 'enarocanje',
                component: EnarocanjeComponent,
            },
            {
                path: '404',
                loadChildren: () => import('./pages/default/not-found/not-found.module').then((m) => m.NotFoundModule),
            },
            // {
            //     path: '',
            //     redirectTo: 'customers',
            //     pathMatch: 'full'
            // }
        ],
    },
    {
        path: '0',
        component: ThemeComponent,
        canActivate: [AuthGuard],
        children: [
            // {
            //     path: 'index',
            //     loadChildren: './type0/pages/index/index.module#IndexModule'
            // },
            {
                path: 'customers',
                loadChildren: () =>
                    import('./type0/pages/t0-customers/t0-customers.module').then((m) => m.T0CustomersModule),
                // loadChildren: './pages/home/customers/customers.module#CustomersModule'
            },
            {
                path: 'contractors',
                loadChildren: () =>
                    import('./type0/pages/t0-contractor/t0-contractor.module').then((m) => m.T0ContractorModule),
            },
            {
                path: 'authorization',
                loadChildren: () => import('./type0/pages/t0-case/t0-case.module').then((m) => m.T0CaseModule),
            },
            {
                path: 'tasks',
                loadChildren: () => import('./type0/pages/t0-tasks/t0-tasks.module').then((m) => m.T0TasksModule),
            },
            {
                path: 'analytics',
                loadChildren: () =>
                    import('./type0/pages/t0-analytics/t0-analytics.module').then((m) => m.T0AnalyticsModule),
            },
            {
                path: 'hd-analytics',
                loadChildren: () =>
                    import('./pages/_base/base-halodoktor-analytics/base-halodoktor-analytics.module').then(
                        (m) => m.BaseHalodoktorAnalyticsModule,
                    ),
            },
        ],
    },
    {
        path: '1',
        component: ThemeComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'customers',
                loadChildren: () =>
                    import('./type1/pages/t1-customers/t1-customers.module').then((m) => m.T1CustomersModule),
            },
            {
                path: 'contractors',
                loadChildren: './type1/pages/t1-contractor/t1-contractor.module#T1ContractorModule',
            },
            {
                path: 'authorization',
                loadChildren: './type1/pages/t1-case/t1-case.module#T1CaseModule',
            },
            {
                path: 'tasks',
                loadChildren: './type1/pages/t1-tasks/t1-tasks.module#T1TasksModule',
            },
            {
                path: 'analytics',
                loadChildren: () =>
                    import('./type1/pages/t1-analytics/t1-analytics.module').then((m) => m.T1AnalyticsModule),
            },
            {
                path: 'hd-analytics',
                loadChildren: () =>
                    import('./pages/_base/base-halodoktor-analytics/base-halodoktor-analytics.module').then(
                        (m) => m.BaseHalodoktorAnalyticsModule,
                    ),
            },
        ],
    },
    // {
    //     path: '**',
    //     redirectTo: 'index',
    //     pathMatch: 'full'
    // }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ThemeRoutingModule {}
