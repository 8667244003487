<div class="telephony">
    <div class="telephony-large-modal" cdkDragBoundary=".m-page" cdkDrag [hidden]="!isWindowShown">
        <header class="mf-header">
            <!-- <div class="mf-header-icon w-100 text-center">
                <i class="la la-reorder"></i>
            </div> -->
            <div class="mf-actions w-100 my-1 d-flex">
                <!-- <i class="la la-arrows"></i> -->
                <div class="w-100 my-1 pr-2" style="max-width: 343px;">
                    <div class="text-white p-1" *ngIf="customer">
                        <!-- Stranka: {{ customer?.name }} {{ customer?.surname }} <i  class="la la-external-link"></i> -->
                        <ng-container *ngFor="let item of customer">
                            <a
                                href="#"
                                class="m-badge m-badge--success m-badge--wide mr-1 mb-1"
                                (click)="$event.preventDefault(); openCustomer(item)"
                            >
                                {{ item?.name }} {{ item?.surname }}
                            </a>
                            <!-- <a
                                class="m-badge m-badge--success m-badge--wide mr-4 mb-1"
                                (click)="createNewReservation(item)"
                            >
                                <i class="la la-plus-square"></i>
                            </a> -->
                        </ng-container>
                    </div>
                </div>
                <a
                    href="#"
                    class="btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                    ngbTooltip="Zapri"
                    (click)="$event.preventDefault(); closeWindow()"
                    ><i class="la la-times"></i
                ></a>
            </div>
        </header>
        <div class="mf-phone-wrapper">
            <div id="mf-phone" class="border-0"></div>
            <!-- <section class="" *ngIf="isUnregistered">
                <tph-new-user (onRegister)="onRegister()"> </tph-new-user>
            </section> -->
        </div>
    </div>
</div>
