import { ExtraInfo } from './../model/auth/extra-info';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';
import { HttpClientService } from './http-client.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class IntegrationService {
    constructor(private helper: HelperService, private httpClient: HttpClientService) {}

    public getExtraInfo(caseId: string, userId: string): Observable<ExtraInfo> {
        return this.httpClient.get(
            this.helper.getRootUrl(3) +
                `/assistance/v3/api/integration/cases/${this.helper.replaceSlahsForDolar(caseId)}/extrainfo/${userId}`,
        );
    }
}
