import { SetToken } from './../../theme/pages/_base/base-case/_actions/base-case.actions';
import { Store } from '@ngxs/store';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpClientService } from './http-client.service';
import { HelperService } from './helper.service';
import { StorageService } from './storage.service';
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { User } from './../model/user.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Captcha } from '../model';

@Injectable()
export class AuthService {
    constructor(
        private http: HttpClient,
        private storage: StorageService,
        private helper: HelperService,
        private httpClient: HttpClientService,
        public store: Store,
    ) {}

    public tokenLogin(token: any): boolean {
        this.storage.saveToken({ access_token: token });
        this.store.dispatch(new SetToken(token));
        return true;
    }

    public login(username: string, password: string): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Basic ' + environment.AUTHPASS,
            }),
        };

        return this.http
            .post(
                this.helper.getAuthUrl(),
                'username=' +
                    encodeURIComponent(username.trim()) +
                    '&password=' +
                    encodeURIComponent(password) +
                    '&grant_type=password',
                httpOptions,
            )
            .pipe(
                map(
                    (response) => {
                        // login successful if there's a jwt token in the response
                        // debugger;
                        this.storage.saveToken(response);
                        return true;
                    },
                    (error) => {
                        console.error('Napaka pri: AuthenticationService');
                        return observableThrowError(error);
                    },
                ),
            );
    }

    public logout() {
        this.storage.clearToken();
        // this.storage.deleteAll();
        // this.storage.deleteUserData();
        // this.storage.deleteAppData();
    }

    public getFreeSlotsForService(
        cid: string,
        sid: string,
        from: Date,
        to: Date,
        serviceDuration: number,
    ): Observable<any> {
        const data: any = {
            contractor: {
                webId: cid,
            },
            service: {
                id: sid,
                length: serviceDuration,
            },
            from: from,
            to: to,
        };
        return this.http.post(this.helper.getAssistanceUrl() + '/freeslots/', data);
    }

    public getFlatFreeSlotsForService(cid: string, sid: string, from: Date, to: Date): Observable<any> {
        const data: any = {
            contractor: {
                webId: cid,
                subcontractors: [
                    {
                        id: '2',
                    },
                ],
            },
            service: {
                id: sid,
            },
            from: from,
            to: to,
        };
        return this.http.post(this.helper.getAssistanceUrl() + '/flatfreeslots/', data);
    }

    public getUserData(): Observable<User> {
        return this.httpClient
            .get(this.helper.getAssistanceUrl(2) + '/userinfo/')
            .pipe(map((el) => new User().deserialize(el)));
    }

    //getUserRoles()
    public getUserRoles(contractorid: number): Observable<any> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + '/roles/' + contractorid);
    }

    private extractData(res: Response) {
        const body = res.json();
        return body;
    }

    public getCaptcha(): Observable<Captcha> {
        return this.httpClient.get(this.helper.getCaptchaUrl() + '');
    }

    public solveUserCaptcha(username: string, guid: string, x: number, y: number): Observable<boolean> {
        return this.httpClient.get(this.helper.getCaptchaUrl() + `/users/${username}/validate/${guid}/${x}/${y}`);
    }
}
