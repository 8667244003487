import { CommunicationStringPipe } from './../../shared/pipes/comm-strings.pipe';
import { MonthPipe } from './../../shared/pipes/month.pipe';
import { ComSharedDocItemComponent } from './com-shared-doc-Item/com-shared-doc-Item.component';
import { ComPopupSelectSharedDocumentComponent } from './popup/com-popup-select-shared-document/com-popup-select-shared-document.component';
import { SettingsTypePipe } from './../../shared/pipes/settings-type.pipe';
import { DayDiffPipe } from './../../shared/pipes/day-diff.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { ComCustomerViewComponent } from './com-customer-view/com-customer-view.component';
import { ComHistoryItemComponent } from './com-history/com-history-item/com-history-item.component';
import { ComHistoryComponent } from './com-history/com-history.component';
import { ComInternalCommentTaskComponent } from './com-internal-comment-task/com-internal-comment-task.component';
import { ChItemComponent } from './change-history/ch-item/ch-item.component';
import { CaseLogTypesPipe } from './../../shared/pipes/case-log-types.pipe';
import { ChangeHistoryComponent } from './change-history/change-history.component';
import { TextMaskModule } from 'angular2-text-mask';
import { AllSlotsPopupComponent } from './popup/all-slots-popup/all-slots-popup.component';
import { DocumentListElementComponent } from './document-list-element/document-list-element.component';
import { ContractorsService } from './../../shared/services/contractors.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ComponentsComponent } from './components.component';
import { TermPopupComponent } from './popup/term-popup/term-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Daterangepicker } from 'ng2-daterangepicker';
import { DocumentManagerComponent } from './document-manager/document-manager.component';
import { DocumentsService } from '../../shared/services/documents.service';
import { MediaViewPopupComponent } from './popup/media-view-popup/media-view-popup.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SafePipe } from '../../shared/pipes/safe.pipe';
import { CommentManagerComponent } from './comment-manager/comment-manager.component';
import { ReservationService } from '../../shared/services/reservation.service';
import { CustomerFormComponent } from './customer-form/customer-form.component';
import { MomentModule } from 'ngx-moment';
import { ComAuthorizationsComponent } from './com-authorizations/com-authorizations.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EditTaskPopupComponent } from './popup/edit-task-popup/edit-task-popup.component';
import { NgxFlagIconCssModule } from 'ngx-flag-icon-css';
import { ContractorFormComponent } from './contractor-form/contractor-form.component';
import { HistoryPopupComponent } from './popup/history-popup/history-popup.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TimelineElementComponent } from './popup/history-popup/timeline-element/timeline-element.component';
import { EnarocanjeComponent } from './enarocanje/enarocanje.component';
import {ExternalServiceFormComponent} from "./external-service-form/external-service-form.component";
import { NumberOfTermsPopupComponent } from './popup/number-of-terms-popup/number-of-terms-popup.component';
@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        Daterangepicker,
        PdfViewerModule,
        TextMaskModule,
        MomentModule,
        NgxDatatableModule,
        NgxFlagIconCssModule,
        NgSelectModule,
        PerfectScrollbarModule,
    ],
    declarations: [
        ComponentsComponent,
        TermPopupComponent,
        DocumentListElementComponent,
        AllSlotsPopupComponent,
        NumberOfTermsPopupComponent,
        DocumentManagerComponent,
        MediaViewPopupComponent,
        SafePipe,
        MonthPipe,
        CommentManagerComponent,
        CustomerFormComponent,
        ChangeHistoryComponent,
        CaseLogTypesPipe,
        DayDiffPipe,
        ChItemComponent,
        ComInternalCommentTaskComponent,
        ComHistoryComponent,
        ComHistoryItemComponent,
        ComCustomerViewComponent,
        ComAuthorizationsComponent,
        EditTaskPopupComponent,
        SettingsTypePipe,
        CommunicationStringPipe,
        ContractorFormComponent,
        HistoryPopupComponent,
        TimelineElementComponent,
        ComPopupSelectSharedDocumentComponent,
        ComSharedDocItemComponent,
        ExternalServiceFormComponent,
        EnarocanjeComponent,
        NumberOfTermsPopupComponent,
    ],
    exports: [
        TermPopupComponent,
        DocumentManagerComponent,
        DocumentListElementComponent,
        CustomerFormComponent,
        ContractorFormComponent,
        ChangeHistoryComponent,
        CaseLogTypesPipe,
        DayDiffPipe,
        ComInternalCommentTaskComponent,
        ComHistoryComponent,
        ComCustomerViewComponent,
        ComAuthorizationsComponent,
        SafePipe,
        MonthPipe,
        SettingsTypePipe,
        CommunicationStringPipe,
        HistoryPopupComponent,
        TimelineElementComponent,
        ExternalServiceFormComponent,
        ComSharedDocItemComponent,
    ],
    providers: [ContractorsService, DocumentsService, ReservationService, DatePipe],
    entryComponents: [
        TermPopupComponent,
        MediaViewPopupComponent,
        EditTaskPopupComponent,
        AllSlotsPopupComponent,
        NumberOfTermsPopupComponent,
        CustomerFormComponent,
        ContractorFormComponent,
        ExternalServiceFormComponent,
        HistoryPopupComponent,
    ],
})
export class ComponentsModule {}
