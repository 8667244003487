import {AuthenticationService} from './auth/_services/authentication.service';
import {HttpClientService} from './shared/services/http-client.service';
import {catchError, finalize, filter, take, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {StorageService} from './shared/services/storage.service';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import {
    HttpXsrfTokenExtractor,
    HttpRequest,
    HttpHandler,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import * as _ from 'lodash';
import {HelperService} from './shared/services';
import {environment} from '../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TokenInterceptorService {
    constructor(
        private injector: Injector,
        private tokenExtractor: HttpXsrfTokenExtractor,
        private helper: HelperService,
    ) {
    }

    private isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
        if (this.isOneOfIgnoreredUrl(request)) {
            return next.handle(request);
        }

        const whitelistedApiUrls = [
            environment.root.v3.protocol + environment.root.v3.rootUrl
        ];

        const blacklistedApiUrls = [
            environment.captcha.url,
            this.helper.getAuthUrl(),
        ];
        
        const wApiUrl = whitelistedApiUrls.find((url) => {
            return request.url.indexOf(url) > -1;
        });

        const bApiUrl = blacklistedApiUrls.find((url) => {
            return request.url.indexOf(url) > -1;
        });

        if (wApiUrl && !bApiUrl) {
            const version = this.helper.getAppVersion();
            if (version) {
                const versionMajor = version.slice(0, 1);

                request = request.clone({
                    setHeaders: {
                        'X-ASISTENT-VERSION': versionMajor,
                    },
                });
            }
        }

        const storage = this.injector.get(StorageService);
        const router = this.injector.get(Router);
        const token = _.get(storage.getToken(), 'access_token', '');
        return next.handle(this.addTokenToRequest(request, token, '')).pipe(
            catchError((err) => {
                if (request.url === this.helper.getAuthUrl()) {
                    return throwError(err);
                }
                if (err instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>err).status) {
                        case 401:
                            //2FA
                            if (_.get(err, 'error.error_description') === '2FA') {
                                return throwError(err);
                            }
                            //TODO ni najlepsa resitev
                            if (
                                _.get(request, 'body', '') &&
                                _.get(request, 'body', '').indexOf('refresh_token') !== -1
                            ) {
                                storage.clearToken();
                                router.navigate(['/login']);
                            }
                            return this.handle401Error(request, next);
                        case 400:
                            return throwError(err);
                        case 403:
                            return throwError(err);
                        default:
                            return throwError(err);
                    }
                } else {
                    return throwError(err);
                }
            }),
        );
    }

    private isOneOfIgnoreredUrl(request: any): boolean {
        return /public/.test(request.url) || /hooks.slack.com/.test(request.url);
    }

    private addTokenToRequest(request: HttpRequest<any>, token: string, XSRFToken: string): HttpRequest<any> {
        let useToken: boolean = true;
        if (request.body && _.isString(request.body)) {
            if (_.get(request, 'body', '').indexOf('refresh_token') !== -1) {
                useToken = false;
            }
        }
        if (!token) {
            useToken = false;
        }

        if (useToken) {
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } else {
            return request.clone();
        }
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        const httpClientMF = this.injector.get(HttpClientService);
        const authService = this.injector.get(AuthenticationService);
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return httpClientMF.refreshToken().pipe(
                switchMap((token: any) => {
                    const accessToken = _.get(token, 'access_token', undefined);
                    if (accessToken) {
                        this.tokenSubject.next(accessToken);
                        return next.handle(this.addTokenToRequest(request, accessToken, ''));
                    }

                    return <any>authService.logout();
                }),
                catchError((err) => {
                    return <any>authService.logout();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }),
            );
        } else {
            this.isRefreshingToken = false;

            return this.tokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((token) => {
                    return next.handle(this.addTokenToRequest(request, token, ''));
                }),
            );
        }
    }
}
