<div>
    <div class="mf-view-tools" (click)="activeModal.dismiss('Close click'); (false)">
        <div class="d-flex justify-content-center">
            <a
                href="#"
                class="btn btn-accent  m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                placement="top"
                ngbTooltip="Natisni"
                i18n-ngbTooltip="@@media.view.print"
                (click)="printFile(); $event.stopPropagation(); (false)"
            >
                <i class="la la-print"></i>
            </a>
            <a
                href="#"
                class="btn btn-accent  m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                placement="top"
                ngbTooltip="Prenesi"
                i18n-ngbTooltip="@@media.view.download"
                (click)="downloadFile(); $event.stopPropagation(); (false)"
            >
                <i class="la la-download"></i>
            </a>
            <a
                href="#"
                class="btn btn-danger  m-btn m-btn--icon btn-lg m-btn--icon-only m-btn--pill"
                placement="top"
                ngbTooltip="Zapri"
                i18n-ngbTooltip="@@media.view.close"
                (click)="activeModal.dismiss('Close click'); (false)"
            >
                <i class="la la-close "></i>
            </a>
        </div>
    </div>

    <div class="mf-digital-signature-seal" *ngIf="signature">
        <div class="d-flex">
            <div>
                <i class="la la-shield pt-1" style="height: 30px;"></i>
            </div>
            <div>
                <div>
                    <span>Podpisal: </span> <span class="m--font-boldest">{{ signature?.signer }}</span>
                </div>
                <div>
                    <span>Datum: </span> <span class="m--font-boldest">{{ signature?.date }}</span>
                </div>
                <div>
                    <span>IZS: </span> <span class="m--font-boldest">{{ signature?.contractor }}</span>
                </div>
            </div>
        </div>
    </div>
    <iframe #print frameborder="0" width="0px" height="0px" style="left:-9999px"></iframe>
    <div *ngIf="documentType === 'application/pdf'">
        <pdf-viewer
            [src]="documentFile"
            [fit-to-page]="true"
            [original-size]="false"
            style="display: block;"
        ></pdf-viewer>
    </div>
    <div *ngIf="documentType.includes('image')" class="mf-image-view">
        <img [src]="documentFile | safe: 'url'" class="center" />
    </div>
    <div *ngIf="documentType == 'text/plain'" class="mf-text-plain-view p-3 m-1">
        <p>
            {{ documentFile }}
        </p>
    </div>
</div>
