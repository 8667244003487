export enum NotificationOrigin {
    HISTORYLOGS = 'GETCASEHISTORYLOGS',
    CASECONTRACTORCOMMENTS = 'GETCASECONTRACTORCOMMENTS',
    CASECONTRACTORDOCUMENTS = 'GETCASECONTRACTORDOCUMENTS',
    CASECOMMENTS = 'GETCASECOMMENTS',
    CASEDOCUMENTS = 'GETCASEDOCUMENTS',
    CASECUSTOMERDOCUMENTS = 'GETCASECUSTOMERDOCUMENTS',
    TRIAGEHISTORYLOG = 'GETTRIAGEHISTORYLOG',
    TRIAGECONTRACTORDOCUMENTS = 'GETTRIAGECONTRACTORDOCUMENTS',
    TRIAGEDOCUMENTS = 'GETTRIAGEDOCUMENTS',
    TRIAGECONTRACTORCOMMENTS = 'GETTRIAGECONTRACTORCOMMENTS',
    TRIAGECOMMENTS = 'GETTRIAGECOMMENTS',
    TRIAGECOMMUNICATION = 'TRIAGECOMMUNICATION',
    AUTHORIZATIONCOMMUNICATION = 'AUTHORIZATIONCOMMUNICATION',
}
