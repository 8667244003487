import { Serializable } from './deserialize.service';
import { DocumentSource } from '../enums/documentSource.enum';

export class Document implements Serializable<Document> {
    id: number;
    documentAdded: Date;
    description: string;
    url: string;
    contentType: string;
    appUserId: number;
    appUserName: string;
    documentId: string;
    origin: string;
    documentSource: string;

    deserialize(input) {
        // debugger;
        this.id = input.documentId;
        this.documentAdded = input.documentAdded;
        this.description = input.description;
        this.url = input.url;
        this.contentType = input.contentType;
        this.appUserId = input.appUserId;
        this.appUserName = input.appUserName;
        this.documentId = input.documentId;
        this.documentSource = input.documentSource;

        return this;
    }
}

export class DocumentCase implements Serializable<DocumentCase> {
    id: number;
    documentAdded: Date;
    description: string;
    url: string;
    contentType: string;
    fromAmb: boolean;
    documentSource: DocumentSource;
    prereservationId: string;
    appUserName: string;

    deserialize(input) {
        // debugger;
        this.id = input.documentId;
        this.documentAdded = input.documentAdded;
        this.description = input.description;
        this.url = input.url;
        this.contentType = input.contentType;
        this.fromAmb = input.fromAmb;
        this.documentSource = input.documentSource;
        this.prereservationId = input.prereservationId;
        this.appUserName = input.appUserName;
        return this;
    }
}
