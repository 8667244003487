<!-- begin::Header -->
<header
    class="m-grid__item m-header border3"
    data-minimize="minimize"
    data-minimize-offset="200"
    data-minimize-mobile-offset="200"
    appunwraptag=""
>
    <div class="m-header__top" style="background-color: #3d3b56">
        <div
            class="m-container m-container--responsive m-container--xxl m-container--full-height m-page__container border3"
        >
            <div class="m-stack m-stack--ver m-stack--desktop border3">
                <!-- begin::Brand -->
                <div class="m-stack__item m-brand border3">
                    <div class="m-stack m-stack--ver m-stack--general m-stack--inline">
                        <div class="m-stack__item m-stack__item--middle m-brand__logo">
                            <a routerLink="/customers" class="m-brand__logo-wrapper">
                                <img alt="" src="assets/app/media/img/logos/logo-white.svg" width="150px" />
                                <!-- christmas -->
                                <!-- <img alt="" src="assets/app/media/img/logos/short-logo-white-c.svg" width="70px" /> -->
                            </a>
                        </div>
                        <div class="m-stack__item m-stack__item--middle m-brand__tools"></div>
                    </div>
                </div>
                <!-- end::Brand -->
                <!-- begin::Topbar -->
                <div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
                    <div id="m_header_topbar" class="m-topbar  m-stack m-stack--ver m-stack--general">
                        <div class="m-stack__item m-topbar__nav-wrapper">
                            <ul class="m-topbar__nav m-nav m-nav--inline">
                                <!-- <app-header-notifications></app-header-notifications> -->
                                <!-- <head-header-notifications></head-header-notifications> -->
                                <!-- <li head-header-notifications class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width"
                                    data-dropdown-toggle="click" data-dropdown-persistent="true"></li> -->
                                <span
                                    _ngcontent-c3=""
                                    class="m-nav__link-icon m-topbar__username m--font-brand"
                                    ng-reflect-klass="m-nav__link-icon"
                                    ng-reflect-ng-class="[object Object]"
                                    ><span _ngcontent-c3="" class="m-nav__link-icon-wrapper"
                                        ><i _ngcontent-c3="" class="la la-bell"></i></span
                                ></span>

                                <li
                                    class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                                    data-dropdown-toggle="click"
                                >
                                    <a href="#" class="m-nav__link m-dropdown__toggle">
                                        <span class="m-topbar__welcome" i18n="@@79c70dfa-ad18-5225-a482-4cdea8ccb2d2">
                                            Zdravo,&nbsp;
                                        </span>
                                        <span class="m-topbar__username m--font-brand">
                                            {{ loggedingUser?.name }}
                                        </span>
                                    </a>
                                    <div class="m-dropdown__wrapper">
                                        <span
                                            class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"
                                        ></span>
                                        <div class="m-dropdown__inner">
                                            <div class="m-dropdown__header m--align-center">
                                                <div class="m-card-user m-card-user--skin-dark">
                                                    <div class="m-card-user__pic">
                                                        <i class="la la-male m--font-light" style="font-size: 40px"></i>
                                                        <!-- <img src="./assets/app/media/img/users/user4.jpg" class="m--img-rounded m--marginless" alt="" /> -->
                                                    </div>
                                                    <div class="m-card-user__details">
                                                        <span class="m-card-user__name m--font-weight-500">
                                                            {{ loggedingUser?.name }}
                                                        </span>
                                                        <!-- <div href="" class="m-card-user__email m--font-weight-300 m-link" *ngFor="let role of userRoles">
                                                            {{translateRole[role]}} &nbsp;
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="m-dropdown__body">
                                                <div class="m-dropdown__content">
                                                    <ul class="m-nav m-nav--skin-light">
                                                        <!-- <li class="m-nav__section m--hide">
                                                            <span class="m-nav__section-text">
                                                                Section
                                                            </span>
                                                        </li> -->
                                                        <!-- <li class="m-nav__item">
                                                            <a routerLink="/settings/profile" class="m-nav__link">
                                                                <i class="m-nav__link-icon la la-cog"></i>
                                                                <span class="m-nav__link-title">
                                                                    <span class="m-nav__link-wrap">
                                                                        <span class="m-nav__link-text">
                                                                            Nastavitve
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </a>
                                                        </li> -->

                                                        <li class="m-nav__separator m-nav__separator--fit"></li>
                                                        <li class="m-nav__item">
                                                            <a
                                                                routerLink="/snippets/pages/user/login-1"
                                                                class="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                                                routerLink="/contractor-app/logout"
                                                                i18n="@@567884c3-0fc6-5330-9c73-1a8b6afe3920"
                                                            >
                                                                Odjava
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- end::Topbar -->
            </div>
        </div>
        <!-- -------------------------------------------------------------------------------------- -->
        <!-- <div *ngIf="checkLocation()" class="row mt-0 pt-0" style="background-color:white;height:50px">
            <div (click)="goBack()" style="align-items:center;display:flex" class="offset-1">
                <i style="cursor:pointer" class="la la-arrow-left"></i>
            </div>
            <div style="align-items:center;display:flex" class="col-10">
                <b>{{ userData.id }} {{ userData.customer.name }} {{ userData.customer.surname }} </b>
            </div>
        </div> -->
        <!-- ----------------------------------------------------------------------------- -->
    </div>
    <!-- *ngIf="!(helper.hiddeHeader|async)" -->
    <!-- {{menu.menuList|async|json}} -->
    <!-- <div class="m-header__bottom">
        <div class="m-container m-container--responsive m-container--xxl m-container--full-height m-page__container">
            <div class="m-stack m-stack--ver m-stack--desktop">

                <div class="m-stack__item m-stack__item--middle m-stack__item--fluid">
                    <div id="m_header_menu" class="m-header-menu  m-header-menu--skin-dark m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-light m-aside-header-menu-mobile--submenu-skin-light">
                        <ul class="m-menu__nav  m-menu__nav--submenu-arrow">

                            <li *ngFor="let menu of menu?.menuList|async" class="m-menu__item  " routerLinkActive="m-menu__item--active"
                                [routerLinkActiveOptions]="{ exact: false }" aria-haspopup="true">
                                <a routerLink="{{menu?.link}}" class="m-menu__link" *ngIf="menu.title !== 'x'">
                                    <span class="m-menu__item-here"></span>
                                    <span class="m-menu__link-text">
                                        <span>{{menu?.title}}</span>
                                    </span>
                                </a>
                                <a routerLink="{{menu.link}}" class="m-menu__link" *ngIf="menu?.title === 'x'">
                                    <span class="m-menu__item-here"></span>
                                    <span class="m-menu__link-text">
                                        <i class="la la-arrow-left" style="font-size: 3rem" *ngIf="menu?.title === 'x'"></i>
                                    </span>
                                </a>

                            </li>
                            <li class="m-menu__item mf-patient-in-menu" aria-haspopup="true" *ngIf="selectedCustomer"
                                style="position:absolute; right:0px">
                                <div class="m-menu__link m--font-brand">
                                    <span class="m-menu__item-here"></span>
                                    <span class="m-menu__link-text m--font-brand">
                                        <i class="la la-user"></i>
                                        {{selectedCustomer?.name}} {{selectedCustomer?.surname}}
                                    </span>
                                </div>
                            </li>


                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>-->
</header>
