import { TriageResponse } from './../../../contractor-app/models/triageResponse.model';
import { CoaService } from './../../../contractor-app/services/coa.service';
import { Customer } from './../../../shared/model/customer.model';
import { MenuService } from './../../../shared/services/menu.service';
import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { StorageService } from '../../../shared/services/storage.service';
import { User } from '../../../shared/model/user.model';
import * as _ from 'lodash';

declare let mLayout: any;
@Component({
    selector: 'app-header-nav-case',
    templateUrl: './header-nav-case.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./header-nav-case.component.scss'],
})
export class HeaderNavCaseComponent implements OnInit, AfterViewInit {
    public loggedingUser: User;
    public translateRole = { ROLE_OWNER: 'vodja', ROLE_SUBCONTRACTOR: 'podizvajalec', ROLE_SEKRETAR: 'Sekretar' };
    public selectedCustomer: Customer;
    public coaRest: CoaService;
    public allData: TriageResponse[];
    public userData: TriageResponse;

    constructor(private storage: StorageService, public menu: MenuService) {}
    ngOnInit() {
        this.loggedingUser = this.storage.getUserData();
        // let userId = Number(window.location.href.split('case-list/')[1]);
        // this.coaRest.getBusinessSubjectTriaz(54).subscribe((res) => {
        //     this.allData = res.map((el) => new TriageResponse().deserialize(el));
        //     this.allData.forEach((el) => {
        //         if (el.id == userId) {
        //             this.userData = el;
        //             console.log(this.userData);
        //         }
        //     });
        // });
    }
    ngAfterViewInit() {
        mLayout.initHeader();
    }
    public checkLocation(): boolean {
        if (window.location.href.split('case-list')[1].length > 0) {
            return true;
        } else {
            return false;
        }
    }
    public goBack(): void {
        window.history.back();
    }
}
