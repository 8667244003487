import { HelperService } from './helper.service';
import { HttpClientService } from './http-client.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PublicService {
    constructor(private helper: HelperService, private httpClient: HttpClientService) {}

    //forgottenPwd() //public access
    public forgottenPwd(data: any): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(2) + `/public/pwd/forgotten`, data);
    }

    public renewPwd(data: any): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(2) + '/public/pwd/renew', data);
    }

    public checkToken(documentid: string): Observable<string> {
        return this.httpClient.post(
            this.helper.getAssistanceUrl(2) + '/public/token/' + documentid,
            {},
            { responseType: 'text' },
        );
    }
    
    public sendAccessCode(documentid: string, phonenumber: string): Observable<string> {
        return this.httpClient.post(
            this.helper.getAssistanceUrl(2) + '/public/token/' + documentid + '/phone/' + phonenumber,
            {},
            { responseType: 'text' },
        );
    }

    public getCustomerDocuments(guid: any, phoneCode: any): Observable<any> {
        return this.httpClient.get(
            this.helper.getAssistanceUrl(2) + `/public/patientDocuments/link/${guid}/code/${phoneCode}`,
        );
    }

    // http://10.230.33.13:8092/booking/v2/assistance/api/notifications/69a085ad-d8bf-459a-af56-5779d4e2f2f8
    public getNotificationDocuments(guid: any): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getAssistanceUrl(3) + `/notifications/${guid}`);
    }
    // http://10.230.33:5550/booking/asv2/assistance/api/notifications/69a085ad-d8bf-459a-af56-5779d4e2f2f8
    // http://localhost:5550/booking/asv2/assistance/api/notifications/3bcde305-4c89-4ed5-a515-390c07880b64
    // http://localhost:9090/booking/patient/api/notifications/99d4b7a7-4b8d-48dd-a070-bb6993d1ffce

    public sendAccessToken(documentid: string, phonenumber: string): Observable<any> {
        return this.httpClient.post(
            this.helper.getAssistanceUrl(2) + '/public/token/' + documentid + '/code/' + phonenumber,
            {},
        );
    }
}

// {
//     responseType: 'text',
// }
