import { Serializable } from './../../deserialize.service';
import { UrlData } from './../../auth/url-data.model';
import { UrlService } from './url-service.model';

export class UrlDataBS1 extends UrlData implements Serializable<UrlDataBS1> {
    public service: UrlService[];

    deserialize(input: any) {
        super.deserialize(input);

        this.service = input.service;
        return this;
    }
}
